import { globalCss } from '@f8n-frontend/stitches';

export const privyStyles = globalCss({
  ':root': {
    // Black, using hsl so that it works with Privy's internal styling logic
    '--privy-color-accent': 'hsl(0,0%,0%) !important',

    '--privy-border-radius-sm': '2px !important',
    '--privy-border-radius-md': '4px !important',
    '--privy-border-radius-mdlg': '8px !important',
    '--privy-border-radius-lg': '8px !important',
  },

  '#privy-modal-content': {
    fontFamily: '$body',
  },

  '#privy-dialog-title': {
    fontWeight: '$semibold',
  },
});
