import { styled } from '@f8n-frontend/stitches';
import * as ToastPrimitive from '@radix-ui/react-toast';

const Viewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '$5',
  margin: 0,
  listStyle: 'none',
  outline: 'none',
  // otherwise toast can be hidden behind nft page "related nfts" fixed footer
  zIndex: 10,
});

const Body = styled('div', {
  minWidth: 300,
  backgroundColor: '$white100',
  borderRadius: '$3',
  boxShadow: '$regular2',
  paddingY: '$3',
  paddingX: '$5',
  marginY: '$1',
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
  minHeight: 60,

  '@bp0': {
    maxWidth: 400,
  },

  variants: {
    hasAction: {
      true: {
        paddingRight: '$3',
      },
      false: {},
    },
    hasIcon: {
      true: {
        paddingLeft: '$3',
      },
      false: {},
    },
    variant: {
      info: {},
      error: {
        '& > svg': {
          color: '$red4',
        },
      },
      success: {
        '& > svg': {
          color: '$green4',
        },
      },
    },
  },
});

const Action = styled(ToastPrimitive.Action, {
  marginLeft: 'auto',
});

const Toast = {
  Provider: ToastPrimitive.Provider,
  Body,
  Root: ToastPrimitive.Root,
  Description: ToastPrimitive.Description,
  Action,
  Viewport,
};

export default Toast;
