import { createConfig as createPrivyWagmiConfig } from '@privy-io/wagmi';
import { match } from 'ts-pattern';
import { HttpTransportConfig, createPublicClient, webSocket } from 'viem';
import { CreateConfigParameters, http } from 'wagmi';
import { mainnet, sepolia, base, baseSepolia } from 'wagmi/chains';

import { ENV } from 'utils/env';

import {
  CHAINS,
  ChainId,
  MAINNET_CHAIN_IDS,
  TESTNET_CHAIN_IDS,
} from './chains';

const BATCH_WAIT_IN_MS = 25;

const defaultTransportConfig: HttpTransportConfig = {
  batch: {
    wait: BATCH_WAIT_IN_MS,
  },
};

export const WAGMI_TESTNET_CHAINS = [sepolia, baseSepolia] as const;
export const WAGMI_MAINNET_CHAINS = [mainnet, base] as const;

const getViemWebSocketClient = (options: { chainId: ChainId }) => {
  const chainId = options.chainId;

  if (ENV.ALCHEMY.category === 'mainnet') {
    if (chainId === CHAINS.mainnet.chainId) {
      return createPublicClient({
        chain: mainnet,
        transport: webSocket(ENV.ALCHEMY.chains[chainId].wssUrl),
      });
    }

    if (chainId === CHAINS.base.chainId) {
      return createPublicClient({
        chain: base,
        transport: webSocket(ENV.ALCHEMY.chains[chainId].wssUrl),
      });
    }
  }

  if (ENV.ALCHEMY.category === 'testnet') {
    if (chainId === CHAINS.sepolia.chainId) {
      return createPublicClient({
        chain: sepolia,
        transport: webSocket(ENV.ALCHEMY.chains[chainId].wssUrl),
      });
    }

    if (chainId === CHAINS.baseSepolia.chainId) {
      return createPublicClient({
        chain: baseSepolia,
        transport: webSocket(ENV.ALCHEMY.chains[chainId].wssUrl),
      });
    }
  }

  throw new Error(`No client found for chainId: ${chainId}`);
};

const getViemWebSocketClients = () => {
  if (ENV.ALCHEMY.category === 'mainnet') {
    return {
      [CHAINS.mainnet.chainId]: getViemWebSocketClient({
        chainId: CHAINS.mainnet.chainId,
      }),
      [CHAINS.base.chainId]: getViemWebSocketClient({
        chainId: CHAINS.base.chainId,
      }),
    };
  }

  return {
    [CHAINS.sepolia.chainId]: getViemWebSocketClient({
      chainId: CHAINS.sepolia.chainId,
    }),
    [CHAINS.baseSepolia.chainId]: getViemWebSocketClient({
      chainId: CHAINS.baseSepolia.chainId,
    }),
  };
};

const viemWebSocketClients = getViemWebSocketClients();

export const getViemWebSocketClientByChainId = (chainId: ChainId) => {
  const client = viemWebSocketClients[chainId];
  /**
   * we throw upstream of this so that should not be reached
   */
  if (!client) {
    throw new Error(`No client found for chainId: ${chainId}`);
  }

  return client;
};

export const mapChainIdToWagmiChain = (chainId: ChainId) => {
  return match(chainId)
    .with(MAINNET_CHAIN_IDS.base, () => base)
    .with(MAINNET_CHAIN_IDS.mainnet, () => mainnet)
    .with(TESTNET_CHAIN_IDS.baseSepolia, () => baseSepolia)
    .with(TESTNET_CHAIN_IDS.sepolia, () => sepolia)
    .exhaustive();
};

const getWagmiConfig = () => {
  const defaultWagmiConfigParameters: Partial<CreateConfigParameters> = {
    ssr: true,
    batch: {
      multicall: {
        wait: BATCH_WAIT_IN_MS,
      },
    },
  };

  if (ENV.ALCHEMY.category === 'testnet') {
    return createPrivyWagmiConfig({
      ...defaultWagmiConfigParameters,
      chains: WAGMI_TESTNET_CHAINS,
      transports: {
        [CHAINS.sepolia.chainId]: http(
          ENV.ALCHEMY.chains[CHAINS.sepolia.chainId].httpsUrl,
          defaultTransportConfig
        ),
        [CHAINS.baseSepolia.chainId]: http(
          ENV.ALCHEMY.chains[CHAINS.baseSepolia.chainId].httpsUrl,
          defaultTransportConfig
        ),
      },
    });
  }

  return createPrivyWagmiConfig({
    ...defaultWagmiConfigParameters,
    chains: WAGMI_MAINNET_CHAINS,
    transports: {
      [CHAINS.mainnet.chainId]: http(
        ENV.ALCHEMY.chains[CHAINS.mainnet.chainId].httpsUrl,
        defaultTransportConfig
      ),
      [CHAINS.base.chainId]: http(
        ENV.ALCHEMY.chains[CHAINS.base.chainId].httpsUrl,
        defaultTransportConfig
      ),
    },
  });
};

export const wagmiConfig = getWagmiConfig();

export const wagmiMainnetConfig = createPrivyWagmiConfig({
  ssr: true,
  chains: [mainnet],
  transports: {
    [mainnet.id]: http(ENV.ALCHEMY_MAINNET_URL, defaultTransportConfig),
  },
  batch: {
    multicall: {
      wait: BATCH_WAIT_IN_MS,
    },
  },
});
