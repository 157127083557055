import { VideoHTMLAttributes } from 'react';

export const PINATA_FILE_ENDPOINT =
  'https://api.pinata.cloud/pinning/pinFileToIPFS';

export const CACHE_TIME = 43200000; // 12 hours

export const TESTNET_FEATURED_CONTENT_URL =
  'https://testnet.foundation.app/mint/base-sepolia/0x7550352f16811B2d55b5E67112BE3Ce4D61AC137';

export const TESTNET_FEATURED_CREATOR_URLS = [
  'http://localhost:3000/@loudsqueak',
  'http://localhost:3000/@sdgjasldkgjasldg',
  'http://localhost:3000/@loudsqueak-demo',
  'http://localhost:3000/@lozza',
  'http://localhost:3000/@turnipboi',
  'http://localhost:3000/@net121',
  'http://localhost:3000/@gosset2',
  'http://localhost:3000/@matt',
  'http://localhost:3000/@paul',
  'http://localhost:3000/@maalavidaa',
  'http://localhost:3000/@jkm',
  'http://localhost:3000/@paulcowgill',
  'http://localhost:3000/@gonzzzalo',
  'http://localhost:3000/@elpizo_',
  'http://localhost:3000/@samanthays',
  'http://localhost:3000/@gonzzzalo_',
  'http://localhost:3000/@loudsqueak_',
  'http://localhost:3000/@davidportebeckefeld_',
  'http://localhost:3000/@arvidabystromold',
  'http://localhost:3000/@pixlpa',
  'http://localhost:3000/@notsamantha',
  'http://localhost:3000/@arvidabystrom',
  'http://localhost:3000/@davidportebeckefeld',
  'http://localhost:3000/@Nick',
  'http://localhost:3000/@samantha',
  'http://localhost:3000/@hd-test',
  'http://localhost:3000/@coopahtroopa',
];

export const TESTNET_FEATURED_PROFILE_URLS = [
  'http://localhost:3000/@adamdriver',
  'http://localhost:3000/@alexander',
  'http://localhost:3000/@arnold',
  'http://localhost:3000/@arvidabystrom',
  'http://localhost:3000/@arvidabystrom',
  'http://localhost:3000/@baggybydesign',
  'http://localhost:3000/@benje',
  'http://localhost:3000/@coopahtroopa',
  'http://localhost:3000/@cori',
  'http://localhost:3000/@creator',
  'http://localhost:3000/@dappb0i',
  'http://localhost:3000/@davidportebeckefeld',
  'http://localhost:3000/@fvckrender',
  'http://localhost:3000/@gonzzzalo',
  'http://localhost:3000/@gosset2',
  'http://localhost:3000/@hypnopizza',
  'http://localhost:3000/@jessepimenta',
  'http://localhost:3000/@jkm',
  'http://localhost:3000/@kayvon',
  'http://localhost:3000/@kyt',
  'http://localhost:3000/@loudsqueak-demo',
  'http://localhost:3000/@lozza',
  'http://localhost:3000/@maalavidaa',
  'http://localhost:3000/@matt',
  'http://localhost:3000/@net121',
  'http://localhost:3000/@Nick',
  'http://localhost:3000/@notsamantha',
  'http://localhost:3000/@paul',
  'http://localhost:3000/@paulcowgill',
  'http://localhost:3000/@paulyc',
  'http://localhost:3000/@pixlpa',
  'http://localhost:3000/@samantha',
  'http://localhost:3000/@samanthays',
  'http://localhost:3000/@tooty',
  'http://localhost:3000/@tree',
  'http://localhost:3000/@turnipboi',
];

/**
 * Represents an Ethereum address where all 20 bytes are zero.
 * Can be used for an address that doesn't exist or hasn't been set.
 */
export const ZERO_ADDRESS =
  '0x0000000000000000000000000000000000000000' as const;

/**
 * Represents a 32-byte Ethereum hash where all bytes are zero.
 * Can be used to represent a hash that doesn't exist or hasn't been set.
 */
export const ZERO_HASH =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

export const HASURA_DEFAULT_ITEMS_PER_PAGE = 48;

export const DEFAULT_PROVIDER_TYPE = 'METAMASK';

export const DUPLICATE_ASSET_ERROR_MSG = 'DUPLICATE_ASSET';

export const MIN_FOLLOWS_COUNT = 5;

export const CONTENTFUL_HOME_PAGE_ID = '4aQQfoWs4jjk4srqvD1eyJ';
export const CONTENTFUL_CASE_STUDIES_ID = '2WM55pvbTZuXEgCDOzFxss';

export const WEEKLY_FORM_URL =
  'https://withfoundation.us19.list-manage.com/subscribe/post-json?u=c4a22288e5ecbe301dff16398&amp;id=4b6e100ee2';
export const MAILCHIMP_SUBSCRIBE_URL =
  'https://withfoundation.us19.list-manage.com/subscribe/post-json?u=c4a22288e5ecbe301dff16398&amp;id=4b6e100ee2&amp;f_id=000995e0f0';
export const MAX_SPLIT_RECIPIENT_COUNT = 4;

export const GREENHOUSE_API_URL =
  'https://boards-api.greenhouse.io/v1/boards/foundation/jobs?content=true';

export const FND_CONTRACT_SLUG = 'foundation';

export const NFTS_PAGE_CACHE_KEY = 'NFTS_PAGE';

export const FOUNDATION_CONTRACT_CREATOR_ADDRESS =
  '0x9d9C46aCa6a2c5FF6824A92d521b6381f9f8F1a9';

export const IPFS_BASE_PATH = {
  assets: 'assets',
  metadata: 'metadata',
};

const IPFS_GATEWAY_URL = new URL('https://ipfs.croc3.dev/ipfs/');
export const IPFS_GATEWAY_URL_STRING = IPFS_GATEWAY_URL.toString();
export const IPFS_GATEWAY_URL_HOST = IPFS_GATEWAY_URL.host;

export const USERNAME_REGEX = /^[\w._-]+$/;
export const ETH_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/g;
export const IPFS_HASH_REGEX =
  /^(Qm[1-9A-HJ-NP-Za-km-z]{44,}|b[A-Za-z2-7]{58,}|B[A-Z2-7]{58,}|z[1-9A-HJ-NP-Za-km-z]{48,}|F[0-9A-F]{50,})$/g;

export const REVALIDATE_TIME = 3600;

export const NOT_FOUND = {
  notFound: true as const,
  revalidate: 10,
};

export const ACTIVITY_PATH = '/activity';
export const FEED_PATH = '/feed';

export const DEFAULT_VIDEO_PROPS: VideoHTMLAttributes<HTMLVideoElement> = {
  playsInline: true,
  autoPlay: true,
  loop: true,
  muted: true,
};

export const SLUG_MAX_LENGTH = 48;

export const WORLD_DESCRIPTION_MAX_LENGTH = 1500;

export const FEATURED_WORLD_SLUGS_TESTNET = [] as const;

export const MAX_BATCH_LIST_COUNT = 50;
export const MIN_LIST_PRICE = 0.000001;
export const LIMIT_PER_ACCOUNT = 10;

const UINT16_MAX = 65535;

/** Optimized to fit inside our `Stepper` component. */
export const MAX_MINT_QUANTITY = 999_999;

export const MAX_TOKEN_ID = UINT16_MAX;

// for use in web3 txn hooks
export const UNSET_ARG = BigInt(0);

export const UNIX_EPOCH_DATE = new Date(0);

export const URL_REGEX = /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,24}/g;

export const BABES_FREE_RELEASES = [
  {
    name: 'Babes Forever',
    contractAddress: '0xb1fE9d6608b71e430D3868AEDB625B663F646Ac8',
    releaseDate: '2024-06-03T17:00:00Z',
  },
  {
    name: 'f*ck mornings',
    contractAddress: '0x477Fa47248F58D849639C74EEDAC5D4b411B97CF',
    releaseDate: '2024-06-04T17:00:00Z',
  },
  {
    name: 'hot babes in your area',
    contractAddress: '0x149d44e884026a6903bD353bFFF5b1a8416A0d17',
    releaseDate: '2024-06-05T17:00:00Z',
  },
  {
    name: 'always imitated, never duplicated',
    contractAddress: '0x482Ad3FC26546091701450124F58E36aA985f03f',
    releaseDate: '2024-06-06T17:00:00Z',
  },
  {
    name: 'clown',
    contractAddress: '0x047757B318052d1FA8dE80FCb4948678bE29528D',
    releaseDate: '2024-06-07T17:00:00Z',
  },
  {
    name: 'apology accepted 💕',
    contractAddress: '0x494a274dC3f8e97f0e68F68e06ad106520b67deE',
    releaseDate: '2024-06-08T17:00:00Z',
  },
  {
    name: 'hey hottie',
    contractAddress: '0x6FFb7e66866C77035e834E3d31De1a6A16e06249',
    releaseDate: '2024-06-09T17:00:00Z',
  },
] as const;
