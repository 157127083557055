import { logger } from 'lib/logger';

/**
 * Use this to monitor the performance of any expensive operations (i.e. network requests)
 *
 * @example
 * const monitor = monitorPerformance('fetchNfts');
 * const startMark = monitor.start();
 * const data = await fetchExpensiveData();
 * monitor.end(startMark);
 */
export const monitorPerformance = (name: string) => {
  return {
    start: () => {
      return performance.mark(`${name}-start`);
    },
    end: (startMark: PerformanceMark) => {
      const endMark = performance.mark(`${name}-end`);
      const measure = performance.measure(name, startMark.name, endMark.name);

      logger.info(
        `query-performance ${name}: ${measure.duration}`,
        measure.toJSON()
      );
    },
  };
};

export const monitorTxKey = 'monitor-tx-v0';
