import { FunctionComponent, SVGAttributes, CSSProperties } from 'react';

interface IconProps {
  width: number;
  height: number;
  icon: FunctionComponent<SVGAttributes<SVGElement>>;
  style?: CSSProperties;
}

/**
 * @deprecated use components/base/IconV2 instead
 */
export default function Icon(props: IconProps) {
  const { icon: IconComponent, height, width, style } = props;
  return (
    <IconComponent
      width={width}
      height={height}
      style={{ ...style, display: 'block', position: 'relative' }}
    />
  );
}
