/**
 * The keys in this object should describe how this param is used.
 * The value is a url param and should ideally be human readable.
 * For example, prefer values like "creator" over "c" or "creatorUuid".
 */
export const ROUTER_PARAMS = Object.freeze({
  COLLECTION_ATTRIBUTES: 'attr',
  COLLECTION_CREATOR: 'creator',
  COLLECTION_DISTINCT_ASSET_KEY: 'media',
  DROP_STATUS: 'status',
  MODAL_DEEP_LINK: 'show',
  REDIRECT_PATH: 'redirect-path',
  TAB_DEEP_LINK: 'tab',
  CHAIN_ID: 'chainId',
});
