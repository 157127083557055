import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useAnalytics from 'contexts/analytics/useAnalytics';
import useAuth from 'contexts/auth/useAuth';

import { detectRouterAnalyticsViewEvent } from 'utils/analytics';

export default function useTrackRouterView(): void {
  const analytics = useAnalytics();
  const auth = useAuth();
  const router = useRouter();

  const isAuthReady = auth.state !== 'unknown';

  useEffect(
    () => {
      if (!router.pathname) return;

      // wait for auth to be in a known state before tracking
      if (!isAuthReady) return;

      const baseViewEvent = detectRouterAnalyticsViewEvent({
        pathname: router.pathname,
      });

      if (!baseViewEvent) return;

      analytics.track({
        ...baseViewEvent,
        authState: auth.state,
        pathname: router.pathname,
      });
    },
    /**
     * auth.state is intentionally omitted from the dependencies here, beause we
     * only want to fire this event when the path changes.
     * */
    [router.pathname, isAuthReady]
  );
}
