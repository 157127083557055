/**
 * This file contains global cache keys, namespaced by feature.
 * Not all cache keys need to be defined here, only those that are used in many places.
 *
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 *
 * The proposed structure of the cache keys is as follows:
 * 1. A root key that is unique to the feature/entity (e.g. 'profile', 'nft', 'world', 'collection')
 * 2. A descriptor (e.g. 'byId', 'bySlug', 'byCreator', 'isFollowing', 'search')
 * 3. Unique identifier or varaibles (e.g. a public key, an id, a slug, or an object of variables)
 *
 * @example cache keys
 * - ['profile', 'isFollowing', '0x1234...']
 * - ['nft', 'byId', '0x1234...']
 * - ['world', 'bySlug', 'my-world']
 * - ['collection', 'byCreator', { ...variables }]
 *
 * @example in a component
 * const query = useQuery({
 *  queryKey: userCache.byPublicKey(publicKey),
 *  queryFn: () => getUserByPublicKey(publicKey),
 * })
 *
 * @example in another component or hook
 * const queryClient = useQueryClient()
 * queryClient.invalidateQueries(userCache.root) // invalidates all user queries
 * queryClient.invalidateQueries(userCache.byPublicKey(publicKey)) // invalidates a specific user query
 */

import { Maybe } from 'types/utils';

type InfiniteOptions<T = Record<string, unknown>> = T & {
  page?: Maybe<number>;
  perPage?: Maybe<number>;
};

/**
 * Root cache keys. These are exposed to the rest of the app via the `*Cache` objects below.
 * Note that these are listed here for reference only, and should not be exported directly.
 */
const cacheRoot = {
  connectedUser: 'connected-user',
  user: 'user',
  world: 'world',
} as const;

/**
 * Cache keys for the currently connected user
 */
export const connectedUserCache = {
  root: [cacheRoot.connectedUser] as const,
  authToken: [cacheRoot.connectedUser, 'auth-token'] as const,
  isFollowing: (accountAddress: string) => {
    return [...connectedUserCache.root, 'isFollowing', accountAddress] as const;
  },
};

/**
 * Cache keys for any profile / user data
 */
export const userCacheKey = {
  root: [cacheRoot.user] as const,
  byPublicKey: (publicKey: string) =>
    [...userCacheKey.root, publicKey] as const,
};

/**
 * Cache keys for any world data
 */
export const worldCache = {
  root: [cacheRoot.world] as const,
  bySlug: (slug: string) => {
    return [...worldCache.root, 'bySlug', slug] as const;
  },
  allowlistBySlug: (slug: string) => {
    return [...worldCache.root, 'allowlist', slug] as const;
  },
  allowlistBySlugInfinite: (options: InfiniteOptions<{ slug: string }>) => {
    return [
      ...worldCache.allowlistBySlug(options.slug),
      'infinite',
      options,
    ] as const;
  },
};
