import { styled, keyframes } from '@f8n-frontend/stitches';

const dots = keyframes({
  '20%': { backgroundPosition: '0% 0%, 50% 50%, 100% 50%' },
  '40%': { backgroundPosition: '0% 100%, 50% 0%, 100% 50%' },
  '60%': { backgroundPosition: '0% 50%, 50% 100%, 100% 0%' },
  '80%': { backgroundPosition: '0% 50%, 50% 50%, 100% 100%' },
});

const dotsSmall = keyframes({
  '20%': { backgroundPosition: '0% 0%, 50% 50%, 100% 50%' },
  '40%': { backgroundPosition: '0% 60%, 50% 0%, 100% 50%' },
  '60%': { backgroundPosition: '0% 50%, 50% 60%, 100% 0%' },
  '80%': { backgroundPosition: '0% 50%, 50% 50%, 100% 60%' },
});

const Dots = styled('div', {
  '--dot': 'no-repeat radial-gradient(circle closest-side, #000 90%, #0000)',

  width: '48px',
  aspectRatio: 2.5,
  background: 'var(--dot) 0%   50%, var(--dot) 50%  50%, var(--dot) 100% 50%',
  backgroundSize: 'calc(100%/3) 50%',
  animation: `${dots} 1400ms infinite ease-in-out`,
});

const DotsSmall = styled('div', {
  '--dot': 'no-repeat radial-gradient(circle closest-side, #000 90%, #0000)',

  width: '18px',
  aspectRatio: 2.5,
  background: 'var(--dot) 0%   50%, var(--dot) 50%  50%, var(--dot) 100% 50%',
  backgroundSize: 'calc(100%/5) 25%',
  animation: `${dotsSmall} 1400ms infinite ease-in-out`,
});

export { DotsSmall };
export default Dots;
