import { createContext } from 'react';

import { noop } from 'utils/helpers';

import { Analytics } from './types';

const defaultAnalyticsContext: Analytics = {
  track: noop,
  legacyTrack: noop,
};

const AnalyticsContext = createContext<Analytics>(defaultAnalyticsContext);

export default AnalyticsContext;
