import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { IconEl } from 'components/base/IconV2';

type ToastNotificationVariant = 'info' | 'error' | 'success';

type Id = string;

type BaseNotification = {
  id: Id;
};

type ToastNotification = BaseNotification & {
  /** main text shown inside Toast */
  message: string;
  icon?: IconEl;
  variant: ToastNotificationVariant;
  action?: {
    /** text shown to screen readers */
    altText: string;
    /** text shown inside action button */
    cta: string;
    /** callback when action button is clicked */
    onAction: () => void;
  };
};

// We only support one type of notification right now, but others can be added to this store (i.e. blocking modals)
export type Notification = {
  toast: ToastNotification;
};

type AddNotificationPayload = {
  action?: ToastNotification['action'];
  message: ToastNotification['message'];
  /** pass an id to prevent duplicate notifications */
  id?: Id;
};

type AddNotificationPayloadWithIcon = AddNotificationPayload & {
  icon?: React.ReactNode;
};

export type NotificationStore = {
  toasts: Record<Id, Notification['toast']>;
  show: {
    info(payload: AddNotificationPayloadWithIcon): void;
    success(payload: AddNotificationPayload): void;
    error(payload: AddNotificationPayload): void;
  };
  remove: (id: Id) => void;
};

const useNotifications = create(
  subscribeWithSelector<NotificationStore>((set) => ({
    toasts: {},
    show: {
      info: (payload) => {
        set((state) => addToast({ state, payload, variant: 'info' }));
      },
      error: (payload) => {
        set((state) => addToast({ state, payload, variant: 'error' }));
      },
      success: (payload) => {
        set((state) => addToast({ state, payload, variant: 'success' }));
      },
    },
    remove: (toastId) =>
      set((state) => {
        const nextState = { ...state };
        delete nextState.toasts[toastId];
        return nextState;
      }),
  }))
);

type AddToastOptions = {
  state: NotificationStore;
  payload: AddNotificationPayload;
  variant: ToastNotification['variant'];
};

const addToast = ({
  state,
  payload,
  variant,
}: AddToastOptions): NotificationStore => {
  const id = payload.id || Date.now().toString();

  return {
    ...state,
    toasts: {
      ...state.toasts,
      [id]: {
        ...payload,
        id,
        variant,
      },
    },
  };
};

export default useNotifications;
