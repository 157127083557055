import type { Address } from 'viem';

import { ChainId } from 'lib/chains';

import {
  HighlightFlowUrlParams,
  PickHighlightImportParams,
} from 'types/highlight-import';

export const IMPORT_FROM_HIGHLIGHT_PATH = '/import/highlight';
export const IMPORT_FROM_HIGHLIGHT_PATHNAME = '/import/highlight/[[...parts]]';

/**
 * @param chainId - number
 * @returns chainId as a constant string
 */
export const asChainIdParam = (chainId: ChainId) => {
  return `${chainId}` as const;
};

type ChainIdParam = ReturnType<typeof asChainIdParam>;

type ImportFromHighlightUrlParts =
  | [Address, ChainIdParam] // add to world
  | [Address, ChainIdParam, string] // update payout address
  | [Address, ChainIdParam, string, 'import'] // import collection
  | [Address, ChainIdParam, string, 'import', 'success'] // success screen
  | [Address, ChainIdParam, string, 'import', 'error']; // error screen

export type ImportFromHighlightUrlQueryOptions = {
  parts: ImportFromHighlightUrlParts;
  chainId?: HighlightFlowUrlParams['chainId'];
  worldId?: HighlightFlowUrlParams['worldId'];
  exhibitionId?: HighlightFlowUrlParams['momentId'];
  payoutTxHash?: HighlightFlowUrlParams['payoutTxHash'];
  worldTxHash?: HighlightFlowUrlParams['worldTxHash'];
  payoutAddress?: HighlightFlowUrlParams['payoutAddress'];
};

const buildImportFromHighlightUrlObject = <
  Query extends ImportFromHighlightUrlQueryOptions,
>(
  options: Query
) => {
  const query = options;

  // This is a workaround to prevent the Next.js Link component from placing empty query params into the URL.
  if (!options.payoutTxHash) {
    delete query.payoutTxHash;
  }
  if (!options.worldTxHash) {
    delete query.worldTxHash;
  }
  if (!options.payoutAddress) {
    delete query.payoutAddress;
  }
  if (!options.exhibitionId) {
    delete query.exhibitionId;
  }

  return {
    pathname: IMPORT_FROM_HIGHLIGHT_PATHNAME,
    query,
  };
};

export const getImportFromHighlightPath = {
  /** Step 1 in flow — verify contract */
  verifyContract: IMPORT_FROM_HIGHLIGHT_PATH,
  /** Step 2 in flow — add contract to world + pending step */
  addToWorld: (
    options: PickHighlightImportParams<'chainId' | 'contractAddress'>
  ) => {
    return buildImportFromHighlightUrlObject({
      parts: [options.contractAddress, asChainIdParam(options.chainId)],
    });
  },
  addingToWorld: (
    options: PickHighlightImportParams<
      'chainId' | 'contractAddress' | 'worldId' | 'momentId'
    > &
      Partial<PickHighlightImportParams<'worldTxHash'>>
  ) => {
    return buildImportFromHighlightUrlObject({
      parts: [options.contractAddress, asChainIdParam(options.chainId)],
      worldId: options.worldId,
      worldTxHash: options.worldTxHash,
      exhibitionId: options.momentId,
    });
  },
  /**
   * Step 3 in flow — update payout address
   * When optional payoutTxHash is present, this represents the loading state
   * */
  updatePayoutAddress: (
    options: PickHighlightImportParams<
      'chainId' | 'contractAddress' | 'worldId' | 'momentId'
    > &
      Partial<PickHighlightImportParams<'payoutTxHash' | 'payoutAddress'>>
  ) => {
    return buildImportFromHighlightUrlObject({
      parts: [
        options.contractAddress,
        asChainIdParam(options.chainId),
        String(options.worldId),
      ],
      payoutTxHash: options.payoutTxHash,
      payoutAddress: options.payoutAddress,
      exhibitionId: options.momentId,
    });
  },
  /** Step 4 in flow — update payout address */
  importing: (
    options: PickHighlightImportParams<
      'chainId' | 'contractAddress' | 'worldId'
    >
  ) => {
    return buildImportFromHighlightUrlObject({
      parts: [
        options.contractAddress,
        asChainIdParam(options.chainId),
        String(options.worldId),
        'import',
      ],
    });
  },
  /** final step in flow when import succeeds */
  importSuccessful: (
    options: PickHighlightImportParams<
      'chainId' | 'contractAddress' | 'worldId'
    >
  ) => {
    return buildImportFromHighlightUrlObject({
      parts: [
        options.contractAddress,
        asChainIdParam(options.chainId),
        String(options.worldId),
        'import',
        'success',
      ],
    });
  },
  /** final step in flow when import fails */
  importFailed: (
    options: PickHighlightImportParams<
      'chainId' | 'contractAddress' | 'worldId'
    >
  ) => {
    return buildImportFromHighlightUrlObject({
      parts: [
        options.contractAddress,
        asChainIdParam(options.chainId),
        String(options.worldId),
        'import',
        'error',
      ],
    });
  },
} as const;

export const getImportFromHiglightLogPrefix = () => {
  return 'import-from-highlight:' as const;
};

const IMPORT_FROM_HIGHLIGHT_LOG_MESSAGE = {
  validateHighlightContract: {
    error: 'failed to validate contract',
    success: 'validated contract',
  },
  addToWorld: {
    error: 'failed to add to world',
    success: 'added to world',
  },
  updatePayoutAddressFixedPrice: {
    error: 'failed to set payout address (fixed price)',
    success: 'set payout address (fixed price)',
  },
  updatePayoutAddressDutchAuction: {
    error: 'failed to set payout address (dutch auction)',
    success: 'set payout address (dutch auction)',
  },
  importToFoundation: {
    error: 'failed to import to Foundation',
    success: 'imported to Foundation',
    alreadyImported: 'already imported to Foundation',
  },
} as const;

type ImportLogMap = typeof IMPORT_FROM_HIGHLIGHT_LOG_MESSAGE;
type LogStep = keyof ImportLogMap;

export const getImportFromHighlightLogMessage = <
  Step extends LogStep,
>(options: {
  step: Step;
  scenario: keyof ImportLogMap[Step];
}) => {
  const { step, scenario } = options;
  const message = IMPORT_FROM_HIGHLIGHT_LOG_MESSAGE[step][scenario];

  return `${getImportFromHiglightLogPrefix()} ${message}`;
};
