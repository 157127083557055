import { keyframes } from '@f8n-frontend/stitches';

import Box from 'components/base/Box';

import SpinnerIcon from 'assets/icons/spinner.svg';

import { UnsafeAny } from 'types/utils';

import Icon from './Icon';

const rotate = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

interface SpinnerStrokedProps {
  size?: number;
  css?: UnsafeAny;
}

/**
 * @deprecated Use `Spinner` instead.
 */
export default function SpinnerStroked(props: SpinnerStrokedProps) {
  const { size = 70, css } = props;
  return (
    <Box
      css={{
        ...css,
        display: 'flex',
        animation: `${rotate} 900ms linear infinite`,
        animateFillMode: 'forwards',
      }}
      data-testid="loading"
    >
      <Icon icon={SpinnerIcon} width={size} height={size} />
    </Box>
  );
}
