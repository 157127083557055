export type Maybe<T> = T | null;

export type RecordValues<T extends Record<string, unknown>> = T[keyof T];

export type PartialPick<T, K extends keyof T> = {
  [P in K]?: T[P];
};

export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null | undefined;
};

export type RequiredNonNullable<T extends object> = {
  [P in keyof Required<T>]: NonNullable<T[P]>;
};

export type MutationOptions<Mutation extends (...args: unknown[]) => unknown> =
  Parameters<Mutation>[0];

export type DeepPartial<T> = Partial<{
  [P in keyof T]: DeepPartial<T[P]>;
}>;

export type ExtractRecordKeysByValueType<Data, ValueType> = {
  [Key in keyof Data]: Data[Key] extends ValueType ? Key : never;
}[keyof Data];

type Join<
  Key,
  Previous,
  TKey extends number | string = string,
> = Key extends TKey
  ? Previous extends TKey
    ? `${Key}${'' extends Previous ? '' : '.'}${Previous}`
    : never
  : never;

type Previous = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, ...0[]];

/**
 * Receives an object type and returns a union of the object keys.
 * Nested object keys are converted to a x.y.z format
 * @see https://gist.github.com/badsyntax/4df5d2d8d6f49b7da59bb47f55fadda5
 *
 * @example
 * type Thing = {
 *   a: string;
 *   b: {
 *    c: string;
 *   },
 *   x: {
 *    y: {
 *     z: string;
 *    }
 * }
 *
 * type Keys = NestedKeyPicker<Thing>; // 'a', 'b.c', 'x.y.z'
 */
export type NestedKeyPicker<
  TEntity,
  TDepth extends number = 3,
  TKey extends number | string = string,
> = [TDepth] extends [never]
  ? never
  : TEntity extends object
    ? {
        [Key in keyof TEntity]-?: Key extends TKey
          ?
              | `${Key}`
              | Join<Key, NestedKeyPicker<TEntity[Key], Previous[TDepth]>>
          : never;
      }[keyof TEntity]
    : '';

/**
 * Receives an object type and returns a union of the keys that have values that are strings.
 *
 * @example
 * type Thing = {
 *   a: string;
 *   b: number;
 *   c: null
 *   d: {}
 * }
 *
 * type Keys = ExtractRecordStringKeys<Thing>; // 'a'
 */
export type ExtractRecordStringKeys<T> = ExtractRecordKeysByValueType<
  T,
  string
>;

/**
 * Receives an object type and returns a union of the keys that have values that are functions.
 *
 * @example
 * type Thing = {
 *   a: string;
 *   b: number;
 *   c: (options) => {}
 *   d: (options) => {}
 * }
 *
 * type ThingMethodKeys = FunctionKeys<Thing>; // 'c' | 'd'
 */
export type FunctionKeys<T> = ExtractRecordKeysByValueType<
  T,
  (...args: UnsafeAny) => UnsafeAny
>;

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

/**
 * Gets the resolved type of a promise, from a function that returns a promise.
 * @example type SomeData = FetcherReturnType<typeof someFetcher>;
 */
export type FetcherReturnType<Fn extends (...args: UnsafeAny[]) => unknown> =
  Awaited<ReturnType<Fn>>;

/**
 * In 99% of cases, we don't want to use 'any'.
 *
 * This alias serves a few purposes:
 *
 * 1. It makes it easier to find all the places where we use 'any' in the codebase.
 * 2. It makes it clearer that 'any' is a bit unsafe.
 * 3. We can disable the lint error for this alias.
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnsafeAny = any;

/**
 * In almost all cases, we don't want to use this.
 * When you need to use it, try to use this to emphasize that it's unsafe.
 */
export type UnsafeJsonBlob = object;

/**
 * This type allows us to suggest a set of specific numbers, while allowing any number.
 * This gives us helpful autocomplete suggestions in editiors.
 *
 * @see https://www.youtube.com/watch?v=a_m7jxrTlaw
 */
export type SuggestNumber<T extends number> = T | Omit<number, T>;

export const castToMutableArray = <T>(array: ReadonlyArray<T>): Array<T> => {
  return array as Array<T>;
};

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

/** Converts a "*ByOneOfInput" type exposed by the API, into a descriminated union interface.
 *
 * @example
 * type OneOfExample = {
 *   collection?: InputMaybe<CollectionInput>
 *   nft?: InputMaybe<NftInput>
 * }
 *
 * type Result = OneOfUnion<OneOfExample> // { type: 'collection', filter: CollectionInput } | { type: 'nft', filter: NftInput }
 */
export type OneOfUnion<OneOfObject extends Record<string, unknown>> =
  NonNullable<
    {
      [Key in keyof OneOfObject]: {
        type: Key;
        filter: NonNullable<OneOfObject[Key]>;
      };
    }[keyof OneOfObject]
  >;

export type SetValueReducerAction<
  Values extends Record<string, unknown>,
  K extends keyof Values,
> = {
  type: 'set-value';
  field: K;
  value: Values[K];
};

export type SetValueFn<Values extends Record<string, unknown>> = <
  K extends keyof Values,
>(
  key: K,
  value: Values[K]
) => void;
