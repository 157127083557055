import { useContext } from 'react';

import report from 'lib/report';

import AuthContext from './AuthContext';
import { hasPublicKey } from './helpers';
import { AuthConnected } from './types';

const useAuth = () => useContext(AuthContext);

/**
 * @summary used on components that we are certain are already rendered in a connected state
 * Note that passing an explicit auth prop to a component is a safer approach than using this hook
 */
export const useAssumeConnectedAuth = () => {
  const auth = useAuth();
  if (!hasPublicKey(auth)) {
    report('useAssumeConnectedAuth() was used in a non-connected state');
  }
  return auth as AuthConnected;
};

export default useAuth;
