import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

import { queryClient } from 'lib/reactQueryClient';

export const isQueryEnabled = <T extends Pick<UseQueryOptions, 'enabled'>>(
  options: T | undefined
): boolean => {
  if (options && options.enabled !== undefined) {
    return options.enabled;
  } else {
    return true;
  }
};

const KEY_NOT_FOUND = 'KEY_NOT_FOUND';

type GetKey<T> = (variables: T) => (string | T)[] | [string];

export function getBaseKey<T extends object>(queryKeyFn: GetKey<T>): string {
  const [baseKey] = queryKeyFn({} as T);
  return typeof baseKey === 'string' ? baseKey : KEY_NOT_FOUND;
}

type QueryCacheUpdator<Data> = (data: Data | undefined) => Data;

/**
 * A helper function to optimistically update some query data.
 *
 * @param queryKey - The key of the query to update
 * @param setDataCallback - A callback that will be called with the current data of the query, returning the new data
 */
function setQueryData<Data>(
  queryKey: QueryKey,
  setDataCallback: QueryCacheUpdator<Data>
): void {
  // Cancel any in-flight queries for this query cache
  queryClient.cancelQueries({ queryKey });

  // Manually set the cache for this query
  queryClient.setQueryData<Data | undefined>(queryKey, setDataCallback);
}

/**
 * @param queryKey - The query key to set data for
 * @returns a function that can be used to set data for the provided queryKey
 */
export function getQueryDataSetterForQueryKey<Data>(queryKey: QueryKey) {
  return (setDataCallback: QueryCacheUpdator<Data>) => {
    setQueryData(queryKey, setDataCallback);
  };
}
