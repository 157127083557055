import { z } from 'zod';

import {
  MAINNET_CHAIN_CATEGORY,
  TESTNET_CHAIN_CATEGORY,
} from './chain-category';

const relayerApiKeySchema = z
  .string({ required_error: 'Relayer API key must be provided' })
  .min(1, { message: 'Relayer API key must be provided' });

const relayerSecretKeySchema = z
  .string({ required_error: 'Relayer API secret must be provided' })
  .min(1, { message: 'Relayer API secret must be provided' });

export const relayerEnvSchema = z.object(
  {
    relayerApiKey: relayerApiKeySchema,
    relayerApiSecret: relayerSecretKeySchema,
  },
  {
    required_error: 'Relayer env vars must be provided',
    invalid_type_error: 'Relayer env vars must be provided as an object',
  }
);

export const relayerMainnetEnvSchema = z
  .object({
    base: relayerEnvSchema,
    mainnet: relayerEnvSchema,
  })
  .transform((chains) => {
    return {
      category: MAINNET_CHAIN_CATEGORY,
      chains,
      primaryChain: chains.mainnet,
    };
  });

export const relayerTestnetEnvSchema = z
  .object({
    baseSepolia: relayerEnvSchema,
    sepolia: relayerEnvSchema,
  })
  .transform((chains) => {
    return {
      category: TESTNET_CHAIN_CATEGORY,
      chains,
      primaryChain: chains.sepolia,
    };
  });
