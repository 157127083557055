import { useQuery } from '@tanstack/react-query';
import { Fragment, useEffect, useRef } from 'react';
import { useBlockNumber, useWaitForTransactionReceipt } from 'wagmi';

import useAnalytics from 'contexts/analytics/useAnalytics';
import useTransactionStore from 'state/stores/transactions';

import { useEventByTransactionHash } from 'gql/api/queries/event-by-transaction-hash.generated';
import { useQueryEffects } from 'hooks/react-query';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import { logger } from 'lib/logger';
import { getChainAnalyticsSummary } from 'utils/analytics';
import { isBooleanType } from 'utils/helpers';
import { monitorTxKey } from 'utils/monitoring';
import { secondsToMs } from 'utils/numbers';
import { selectVisibleToasts } from 'utils/transaction-tracking';

import { TrackedTx, TxMonitoringConfig } from 'types/Transactions';
import { Web3ActionName } from 'types/Web3';

import TransactionTrackerToasts from './TransactionTrackerToasts';

const ASSUMED_SECONDS_PER_BLOCK = 12;
const ASSUMED_BLOCKS_PER_MINUTE = 60 / ASSUMED_SECONDS_PER_BLOCK;

const EVENTS_TABLE_POLL_RETRY_EVERY_SECONDS = 5;
const EVENTS_TABLE_POLL_MAX_ATTEMPTS = 60;

export default function TransactionTracker() {
  const transactions = useTransactionStore((state) => state.transactions);
  const stopTracking = useTransactionStore((state) => state.stopTracking);
  const transactionToasts = useTransactionStore(selectVisibleToasts);

  const isHydrated = useIsHydrated();

  if (!isHydrated) {
    return null;
  }

  return (
    <>
      {Array.from(transactions).map(([_txHash, trackedTransaction]) => {
        return (
          <Fragment key={trackedTransaction.txHash}>
            <TrackedTransactionMonitor tx={trackedTransaction} />
            <TrackedTransactionReporter tx={trackedTransaction} />
          </Fragment>
        );
      })}
      <TransactionTrackerToasts
        transactions={transactionToasts}
        onRemove={(transaction) => stopTracking(transaction.txHash)}
      />
    </>
  );
}

type TrackedTransactionProps = {
  tx: TrackedTx;
};

function TrackedTransactionMonitor(props: TrackedTransactionProps) {
  const { tx } = props;
  const { setStatus, stopTracking } = useTransactionStore();

  const defaultConfig = getDefaultActionMonitoringConfig(tx.action.name);
  const config: TxMonitoringConfig = {
    ...defaultConfig,
    needsIndexing: isBooleanType(tx.needsIndexing)
      ? tx.needsIndexing
      : defaultConfig.needsIndexing,
  };

  const blockNumberQuery = useBlockNumber({
    chainId: tx.chainId,
    query: {
      enabled: tx.status === 'PENDING',
    },
  });

  const latestBlockNumber = blockNumberQuery.data;

  const transactionReceipt = useWaitForTransactionReceipt({
    chainId: tx.chainId,
    hash: tx.txHash,
    query: {
      enabled: blockNumberQuery.data ? Boolean(blockNumberQuery.data) : false,
    },
  });

  useQueryEffects(transactionReceipt, {
    onError(error) {
      logger.error(`${monitorTxKey} waitForTranasction error`, error);
      return setStatus({
        txHash: tx.txHash,
        status: 'ERROR',
      });
    },
    onSuccess(receipt) {
      if (
        latestBlockNumber &&
        isOldTransaction({
          latestBlockNumber: Number(latestBlockNumber),
          txBlockNumber: Number(receipt.blockNumber),
        })
      ) {
        logger.info(`${monitorTxKey} removing-stale-transaction`, {
          action: tx.action,
          latestBlockNumber,
          txBlockNumber: receipt.blockNumber,
          blockNumberDiff: latestBlockNumber - receipt.blockNumber,
          status: tx.status,
        });
        return stopTracking(tx.txHash);
      }

      if (receipt.status === 'success') {
        if (config.needsIndexing) {
          return setStatus({
            txHash: tx.txHash,
            status: 'INDEXING',
          });
        } else {
          return setStatus({
            txHash: tx.txHash,
            status: 'SUCCESS',
          });
        }
      } else {
        return setStatus({
          txHash: tx.txHash,
          status: 'REVERTED',
        });
      }
    },
  });

  const eventByTxHashQuery = useQuery({
    queryKey: ['event-by-tx-hash', tx.txHash],
    queryFn: async () => {
      const fetchEvent = useEventByTransactionHash.fetcher({
        transactionHash: tx.txHash,
      });
      const event = await fetchEvent();

      if (!event || !event.eventByTransactionHash) {
        throw new Error('Event not found');
      } else {
        return true;
      }
    },
    enabled: tx.status === 'INDEXING',
    retry: (failureCount) => {
      const shouldRetry = failureCount < EVENTS_TABLE_POLL_MAX_ATTEMPTS;

      if (shouldRetry) {
        logger.log(`${monitorTxKey} get-event-by-tx-hash retrying`, {
          action: tx.action,
          txHash: tx.txHash,
          attempts: failureCount,
        });
      } else {
        logger.log(`${monitorTxKey} get-event-by-tx-hash hit-max-retry-limit`, {
          action: tx.action,
          txHash: tx.txHash,
          attempts: failureCount,
        });
        setStatus({
          txHash: tx.txHash,
          status: 'SUCCESS',
        });
      }

      return shouldRetry;
    },
    retryDelay: secondsToMs(EVENTS_TABLE_POLL_RETRY_EVERY_SECONDS),
  });

  useQueryEffects(eventByTxHashQuery, {
    onError: (error: Error) => {
      logger.error(`${monitorTxKey} event-by-tx-hash error`, error);
    },
    onSuccess: (hasEvent) => {
      if (!hasEvent) return;
      return setStatus({
        txHash: tx.txHash,
        status: 'SUCCESS',
      });
    },
  });

  return null;
}

function TrackedTransactionReporter(props: TrackedTransactionProps) {
  const { tx } = props;

  const analytics = useAnalytics();
  const txRef = useRef<TrackedTx | null>(null);

  useEffect(() => {
    const updateRef = () => {
      txRef.current = tx;
    };

    const reportStatus = () => {
      // Always report to logger
      logger.log(monitorTxKey, tx);

      // Conditionally report to Analytics
      switch (tx.status) {
        case 'SUCCESS': {
          analytics.track({
            /**
             * track all web3 action properties
             * action.name is intentionally overwritten by analytics event name
             **/
            ...tx.action,

            /**
             * action name is intentionally aliased as `action` for analytics backwards compatibility
             * and to avoid conflicting with the analytics event `name` property
             * */
            action: tx.action.name,
            chain: getChainAnalyticsSummary(tx.chainId),
            name: 'web3_action_succeeded',
            txHash: tx.txHash,
          });
          break;
        }
        case 'REVERTED': {
          analytics.track({
            /**
             * track all web3 action properties
             * action.name is intentionally overwritten by analytics event name
             **/
            ...tx.action,

            /**
             * action name is intentionally aliased as `action` for analytics backwards compatibility
             * and to avoid conflicting with the analytics event `name` property
             * */
            action: tx.action.name,
            chain: getChainAnalyticsSummary(tx.chainId),
            name: 'web3_action_reverted',
          });
          break;
        }
      }
    };

    reportStatus();
    updateRef();
  }, [tx.status]);

  return null;
}

type IsOldTransactionOptions = {
  latestBlockNumber: number;
  txBlockNumber: number;
};
const isOldTransaction = (options: IsOldTransactionOptions) => {
  const { latestBlockNumber, txBlockNumber } = options;
  const blocksSinceTx = latestBlockNumber - txBlockNumber;

  return blocksSinceTx > OLD_BLOCK_THRESHOLD;
};

const OLD_BLOCK_THRESHOLD = ASSUMED_BLOCKS_PER_MINUTE * 8;

type MonitoringConfigMap = Record<Web3ActionName, TxMonitoringConfig>;

// This is a bit boilerplate-y for now. Assuming more configuration will be needed in the future.
const monitoringConfigDefaultsMap: MonitoringConfigMap = {
  'accept-buy-now': { needsIndexing: true },
  'accept-offer': { needsIndexing: true },
  'add-world-sellers': { needsIndexing: true },
  'batch-list': { needsIndexing: true },
  'burn-nft': { needsIndexing: true },
  'create-collection': { needsIndexing: true },
  'create-drop': { needsIndexing: true },
  'create-drop-with-splits': { needsIndexing: true },
  'create-edition': { needsIndexing: true },
  'create-1155': { needsIndexing: true },
  'create-world': { needsIndexing: true },
  'list-nft': { needsIndexing: true },
  'make-offer': { needsIndexing: true },
  'mint-from-drop': { needsIndexing: true },
  'mint-from-drop-early-access': { needsIndexing: true },
  'mint-from-edition': { needsIndexing: true },
  'mint-from-edition-token': { needsIndexing: true },
  'mint-to-collection': { needsIndexing: true },
  'mint-to-collection-with-splits': { needsIndexing: true },
  'place-bid': { needsIndexing: true },
  'reveal-drop': { needsIndexing: true },
  'self-destruct-collection': { needsIndexing: true },
  'set-approval-for-all': { needsIndexing: false },
  'settle-auction': { needsIndexing: true },
  'start-drop': { needsIndexing: true },
  'start-drop-with-allowlist': { needsIndexing: true },
  'transfer-nft': { needsIndexing: true },
  'unlist-nft': { needsIndexing: true },
  'update-listing': { needsIndexing: true },
  'withdraw-feth': { needsIndexing: false },
  'unknown-action': { needsIndexing: true },
  'mint-from-dutch-auction': { needsIndexing: true },
  'start-drop-dutch-auction': { needsIndexing: true },
  'withdraw-creator-revenue-from-dutch-auction': { needsIndexing: true },
  'claim-buyer-rebate-from-dutch-auction': { needsIndexing: true },
  'set-world-payment-address': { needsIndexing: false },
  'migrate-world': { needsIndexing: false },
  'burn-world': { needsIndexing: false },
  'manage-world-admins': { needsIndexing: true },
  'remove-seller': { needsIndexing: true },
  'manage-world-editors': { needsIndexing: true },
  'set-world-payout-address-for-highlight-import': { needsIndexing: false },
  'initiate-ownership-transfer': { needsIndexing: false },
  'cancel-ownership-transfer': { needsIndexing: false },
  'accept-ownership-transfer': { needsIndexing: true },
  'set-curator-fee': {
    needsIndexing: true,
  },
};

const getDefaultActionMonitoringConfig = (actionName: Web3ActionName) => {
  return monitoringConfigDefaultsMap[actionName];
};
