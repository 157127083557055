import { ErrorIcon, SuccessIcon } from '@f8n/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import Toast from 'components/base/Toast';
import useNotifications, { Notification } from 'state/stores/notifications';

import Button from './base/Button';
import Text, { TextProps } from './base/Text';

type ToastNotification = Notification['toast'];

export default function NotificationCenter() {
  const store = useNotifications();

  return (
    <Toast.Provider>
      <AnimatePresence>
        {Object.values(store.toasts).map((toast) => (
          <ToastNotification
            key={toast.id}
            onClose={() => {
              store.remove(toast.id);
            }}
            {...toast}
          />
        ))}
      </AnimatePresence>
      <Toast.Viewport />
    </Toast.Provider>
  );
}

type ToastNotificationProps = ToastNotification & {
  onClose: () => void;
};

function ToastNotification(props: ToastNotificationProps) {
  const { action, message, variant, onClose } = props;

  const [isOpen, setIsOpen] = useState(true);

  const textProps: TextProps = {
    weight: 'medium',
    size: 2,
  };

  const icon = variantIconMap[variant] || props.icon;

  return (
    <AnimatePresence onExitComplete={() => onClose()}>
      {isOpen && (
        <Toast.Root
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setIsOpen(false);
            }
          }}
          forceMount
        >
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ translateY: '100%', opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.4, type: 'spring' }}
            layout
          >
            <Toast.Body
              hasAction={Boolean(action)}
              hasIcon={Boolean(icon)}
              variant={variant}
            >
              <Toast.Description asChild>
                <>
                  {icon}
                  <Text {...textProps}>{message}</Text>
                </>
              </Toast.Description>
              {action && (
                <Toast.Action altText={action.altText} asChild>
                  <Button
                    variant="ghost"
                    onClick={() => action.onAction()}
                    size={0}
                    css={{ color: '$black60' }}
                  >
                    {action.cta}
                  </Button>
                </Toast.Action>
              )}
            </Toast.Body>
          </motion.div>
        </Toast.Root>
      )}
    </AnimatePresence>
  );
}

const variantIconMap: Record<ToastNotification['variant'], JSX.Element | null> =
  {
    error: <ErrorIcon />,
    success: <SuccessIcon />,
    info: null,
  };
