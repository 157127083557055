import { styled } from '@f8n-frontend/stitches';

const MenuRoot = styled('div', {
  padding: '$2',
  maxWidth: '80vw',
  overflow: 'auto',
  borderRadius: '$2',
  position: 'relative',
  boxShadow: '$regular2',
  background: '$white100',
  outline: 'none',
});

const MenuItem = styled('div', {
  gap: '$3',
  width: '100%',
  display: 'flex',
  alignItems: 'center',

  paddingY: '$3',
  paddingX: '$4',

  color: '$black100',
  cursor: 'pointer',
  transition: 'background $1 $ease, color $1 $ease',
  borderRadius: '$1',

  fontSize: '$1',
  lineHeight: '$2',
  fontWeight: '$medium',
  textDecoration: 'none',

  '@bp0-max': {
    paddingX: '$3',
    paddingY: '$2',
  },
  '&:focus': {
    outline: 'none',
    backgroundColor: '$black10',
  },

  '@hover': {
    '&:hover': {
      backgroundColor: '$black5',
      color: '$black100 !important',
    },

    '&[aria-current] + &:hover': {
      backgroundColor: '$black10',
    },

    '&:hover + &[aria-current]': {
      backgroundColor: '$black10',
    },

    '&[aria-current]:hover': {
      backgroundColor: '$black10',
    },

    'nav &:not([aria-current]):hover': {
      color: '$black100',
    },
  },

  '&[aria-current]': {
    backgroundColor: '$black5',
  },

  '&[data-disabled], &[aria-disabled]': {
    color: '$black40',
    backgroundColor: 'transparent !important',

    '@hover': {
      '&:hover': {
        color: '$black40 !important',
        backgroundColor: 'transparent',
        cursor: 'default',
      },
    },
  },

  variants: {
    variant: {
      danger: {
        svg: {
          color: '$red3',
        },
      },
    },
  },
});

const MenuSection = styled('div', {
  color: '$black70',
  fontWeight: '$medium',
  fontSize: '$0',
  letterSpacing: '$3',
  paddingX: '$3',
  paddingBottom: '$2',
  paddingTop: '$4',
  textTransform: 'uppercase',
  '@bp0-max': {
    paddingX: '$2',
  },
});

const Menu = {
  Root: MenuRoot,
  Item: MenuItem,
  Section: MenuSection,
};

export default Menu;
