import { darkMode, styled } from '@f8n-frontend/stitches';
import { ComponentProps, VariantProps } from '@stitches/react';
import { forwardRef } from 'react';

import Spinner, { SPINNER_TARGET } from 'components/Spinner';
import {
  outlineButtonCss,
  primaryButtonCss,
  secondaryButtonCss,
} from 'css/button';

import { ButtonFilterIndicatorRoot } from './ButtonFilterIndicator';

const SVG_SELECTOR = '& > svg';
const LEFT_ICON_SELECTOR = `& > svg:first-child, ${SPINNER_TARGET.selector}:first-child`;
const RIGHT_ICON_SELECTOR = `& > svg:last-child, ${SPINNER_TARGET.selector}:last-child`;

type ButtonProps = ComponentProps<typeof StyledButton>;
type ButtonVariants = VariantProps<typeof StyledButton>;

const StyledButton = styled('button', {
  paddingY: 0,
  cursor: 'pointer',
  appearance: 'none',

  borderRadius: '$round',
  boxSizing: 'border-box',
  border: '1px solid transparent',
  backgroundColor: 'transparent',

  willChange: 'transform',
  transition:
    'background-color $1 $ease, border $1 $ease, box-shadow $1 $ease, color $1 $ease, outline $1 $ease, transform $1 $ease',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  fontFamily: '$body',
  fontWeight: '$medium',
  textAlign: 'center',
  textDecoration: 'none',

  '&:disabled': {
    cursor: 'not-allowed',
  },

  '& > svg': {
    display: 'block',
  },

  [SPINNER_TARGET.selector]: {
    svg: {
      width: 'inherit',
      height: 'inherit',
    },
  },

  variants: {
    width: {
      auto: {},
      full: {
        width: '100%',
      },
    },
    size: {
      0: {
        height: '$formElement0',
        paddingX: '$4',
        fontSize: '$1',

        [`${ButtonFilterIndicatorRoot}`]: {
          top: -8,
          minWidth: '16px',
          height: '16px',
          paddingX: '6px',
          fontSize: '10px',
        },
      },
      1: {
        height: '$formElement1',
        paddingX: '$6',
        fontSize: '$2',

        [`${ButtonFilterIndicatorRoot}`]: {
          top: -10,
          paddingX: '$2',
          fontSize: '$0',
        },
      },
      2: {
        height: '$formElement2',
        paddingX: '$7',
        fontSize: '18px',
      },
    },
    icon: {
      true: {},
      standalone: {},
    },
    variant: {
      base: {
        display: 'block',
        padding: 'unset',
        height: 'unset',
        fontWeight: 'initial',
        border: 'none',
        borderRadius: '0px',

        '&:focus-visible': {
          outline: 'none',
        },
      },
      primary: primaryButtonCss,
      secondary: secondaryButtonCss,
      outline: outlineButtonCss,
      raised: {
        backgroundColor: '$white100',
        borderColor: 'transparent',
        boxShadow: '$soft0',
        color: '$black100',

        '@hover': {
          '&:hover': {
            borderColor: 'transparent',
            boxShadow: '$soft1',
            transform: 'translate3d(0, -1px, 0)',
          },
        },

        '&:active, &[data-state=open]': {
          borderColor: 'transparent',
          boxShadow: '$soft0',
          transform: 'translate3d(0, 2px, 0)',
        },

        '&:focus-visible': {
          borderColor: '$black100',
          outline: '4px solid $black30',
        },
        '&:disabled': {
          color: '$black40',
          transform: 'none',
          borderColor: 'transparent',
          '@hover': {
            '&:hover': {
              boxShadow: '$soft0',
            },
          },
        },
      },
      ghost: {
        backgroundColor: 'transparent',
        color: '$black100',
        '@hover': {
          '&:hover': {
            backgroundColor: '$black5',
          },
        },
        '&:active, &[data-state=open]': {
          backgroundColor: '$black5',
          transform: 'translate3d(0, 2px, 0)',
        },
        '&:focus-visible': {
          borderColor: '$black100',
          outline: '4px solid $black30',
        },
        '&:disabled': {
          color: '$black40',
          '@hover': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          '&:active, &[data-state=open]': {
            transform: 'none',
          },
        },
        [darkMode]: {
          '@hover': {
            '&:hover': {
              backgroundColor: '$black15',
            },
          },
        },
      },
      blur: {
        backgroundColor: '$white20',
        backdropFilter: 'blur(10px)',
        color: '$white100',

        [darkMode]: {
          color: '$black100',
          backgroundColor: '$black20',
          '@hover': {
            '&:hover': {
              backgroundColor: '$black100',
              boxShadow: '$regular1',
              color: '$white100',
              transform: 'translate3d(0, -1px, 0)',
            },
          },
        },

        '@hover': {
          '&:hover': {
            backgroundColor: '$white100',
            boxShadow: '$regular1',
            color: '$black100',
            transform: 'translate3d(0, -1px, 0)',
          },
        },
        '&:active, &[data-state=open]': {
          boxShadow: 'none',
          transform: 'translate3d(0, 2px, 0)',
        },
        '&:focus-visible': {
          borderColor: '$white100',
          outline: '4px solid $white50',
        },
        '&:disabled': {
          backgroundColor: '$white20',
          backdropFilter: 'blur(10px)',
          color: '$white60',

          '@hover': {
            '&:hover': {
              transform: 'none',
              boxShadow: 'none',
            },
          },
        },
      },
      subtle: {
        backgroundColor: '$black5',
        color: '$black100',
        '@hover': {
          '&:hover': {
            backgroundColor: '$black10',
          },
        },
        '&:active, &[data-state=open]': {
          backgroundColor: '$black15',
          transform: 'translate3d(0, 2px, 0)',
        },
        '&:focus-visible': {
          backgroundColor: '$black5',
          borderColor: '$black100',
          outline: '4px solid $black30',
        },
        '&:disabled': {
          backgroundColor: '$black2',
          color: '$black40',
          '@hover': {
            '&:hover': {
              backgroundColor: 'black2',
            },
          },
          '&:active, &[data-state=open]': {
            transform: 'none',
          },
        },
        [darkMode]: {
          '@hover': {
            '&:hover': {
              backgroundColor: '$black15',
            },
          },
        },
      },
      danger: {
        backgroundColor: '$black100',
        color: '$white100',

        '@hover': {
          '&:hover': {
            boxShadow: '$regular1',
            backgroundColor: '$red3',
            transform: 'translate3d(0, -1px, 0)',
          },
        },
        '&:active, &[data-state=open]': {
          backgroundColor: '$red3',
          boxShadow: 'none',
          transform: 'translate3d(0, 2px, 0)',
        },
        '&:focus-visible': {
          borderColor: '$white100',
          outline: '4px solid $black30',
        },
        '&:disabled': {
          backgroundColor: '$black5',
          boxShadow: 'none',
          color: '$black50',
          transform: 'none',

          '&:active, &[data-state=open]': {
            backgroundColor: '$black5',
          },
        },
      },
      colored: {
        backgroundColor: '$black100',
        boxShadow: 'none',
        color: '$white100',

        '@hover': {
          '&:hover': {
            boxShadow: '$regular1',
            transform: 'translate3d(0, -1px, 0)',
          },
        },
        '&:active': {
          boxShadow: 'none',
          transform: 'translate3d(0, 2px, 0)',
        },
        '&:focus-visible': {
          borderColor: '$white100',
          outline: '4px solid $black30',
        },
        '&:disabled': {
          backgroundColor: '$black5',
          boxShadow: 'none',
          color: '$black50',
          transform: 'none',

          '&:active': {
            backgroundColor: '$black5',
          },
        },
      },

      // TODO: Deprecate this variant and use the colored instead
      marketing: {
        color: '$white100',
        backgroundColor: '$blue4',

        [darkMode]: {
          color: '$black100',
        },

        '@hover': {
          '&:hover': {
            backgroundColor: '$blue4',
            transform: 'translate3d(0, -1px, 0)',
          },
        },
        '&:active': {
          backgroundColor: '$blue4',
          transform: 'translate3d(0, 2px, 0)',
        },
        '&:focus-visible': {
          borderColor: '$white100',
          outline: '4px solid $black30',
          [darkMode]: {
            borderColor: '$black100',
          },
        },
        '&:disabled': {
          boxShadow: 'none',
          color: '$black50',
          transform: 'none',
          backgroundColor: '$black5',
          '&:active': {
            backgroundColor: '$black5',
          },
        },
      },
    },
  },
  defaultVariants: {
    size: 1,
    variant: 'outline',
  },
  compoundVariants: [
    /*
     * Button with icon in size 0 */
    {
      size: 0,
      icon: true,
      css: {
        paddingX: '$4',
        [SVG_SELECTOR]: {
          width: 'auto',
          height: '$icon0',
        },
        [LEFT_ICON_SELECTOR]: {
          marginRight: '6px',
        },
        [RIGHT_ICON_SELECTOR]: {
          marginLeft: '6px',
        },
      },
    },
    {
      size: 0,
      icon: 'standalone',
      css: {
        padding: 0,
        borderRadius: '50%',
        width: '$formElement0',
        height: '$formElement0',
        [SVG_SELECTOR]: {
          width: 'auto',
          height: '$icon1',
        },
        [SPINNER_TARGET.selector]: {
          width: '$icon1',
          height: '$icon1',
        },
      },
    },

    /*
     * Button with icon in size 1 */
    {
      size: 1,
      icon: true,
      css: {
        paddingX: '$5',
        [SVG_SELECTOR]: {
          width: 'auto',
          height: '$icon1',
        },
        [LEFT_ICON_SELECTOR]: {
          marginRight: '$2',
        },
        [RIGHT_ICON_SELECTOR]: {
          marginLeft: '$2',
        },
        [SPINNER_TARGET.selector]: {
          width: '$icon1',
          height: '$icon1',
        },
      },
    },
    {
      size: 1,
      icon: 'standalone',
      css: {
        padding: 0,
        borderRadius: '50%',
        width: '$formElement1',
        height: '$formElement1',
        [SVG_SELECTOR]: {
          height: '$icon2',
          width: 'auto',
        },
        [SPINNER_TARGET.selector]: {
          width: '$icon2',
          height: '$icon2',
        },
      },
    },

    /*
     * Button with icon in size 2 */
    {
      size: 2,
      icon: true,
      css: {
        paddingX: '$6',
        [SVG_SELECTOR]: {
          width: 'auto',
          height: '$icon2',
        },
        [LEFT_ICON_SELECTOR]: {
          marginRight: '$3',
        },
        [RIGHT_ICON_SELECTOR]: {
          marginLeft: '$3',
        },
        [SPINNER_TARGET.selector]: {
          width: '$icon2',
          height: '$icon2',
        },
      },
    },
    {
      size: 2,
      icon: 'standalone',
      css: {
        padding: 0,
        borderRadius: '50%',
        width: '$formElement2',
        height: '$formElement2',
        [SVG_SELECTOR]: {
          height: '$icon3',
          width: 'auto',
        },
        [SPINNER_TARGET.selector]: {
          width: '$icon3',
          height: '$icon3',
        },
      },
    },
  ],
});

type LoadingButtonProps = Pick<
  ButtonProps,
  'children' | 'disabled' | 'size' | 'variant' | 'width'
>;

/** Just a loading spinner */
const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  function LoadingButton(props, ref) {
    const { size, variant = 'primary', width } = props;

    return (
      <StyledButton
        ref={ref}
        data-testid="loading"
        size={size}
        variant={variant}
        width={width}
        disabled
        icon
      >
        <Spinner />
        <span>{props.children}</span>
      </StyledButton>
    );
  }
);

export type LoaderButtonProps = Pick<
  ButtonProps,
  | 'children'
  | 'disabled'
  | 'icon'
  | 'onClick'
  | 'size'
  | 'type'
  | 'variant'
  | 'width'
> & {
  isLoading: boolean;
  loadingLabel: string;
};

/** Renders a Button.Loading when loading, otherwise a regular Button */
const LoaderButton = forwardRef<HTMLButtonElement, LoaderButtonProps>(
  function LoaderButton(props, ref) {
    const {
      children,
      disabled,
      icon,
      isLoading,
      loadingLabel,
      onClick,
      size,
      type,
      variant = 'primary',
      width,
    } = props;

    if (isLoading) {
      return (
        <LoadingButton ref={ref} size={size} variant={variant} width={width}>
          {loadingLabel}
        </LoadingButton>
      );
    }

    return (
      <StyledButton
        ref={ref}
        disabled={disabled}
        icon={icon}
        onClick={onClick}
        size={size}
        type={type}
        variant={variant}
        width={width}
      >
        {children}
      </StyledButton>
    );
  }
);

const Button = Object.assign(StyledButton, {
  Loader: LoaderButton,
  Loading: LoadingButton,
});

export type { ButtonProps, ButtonVariants };
export default Button;
