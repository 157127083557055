import { ChainId } from 'lib/chains';
import { areKeysEqual } from 'utils/users';

import {
  Auth,
  AuthAuthorized,
  AuthConnected,
  AuthEnriched,
  ExtractAuthState,
} from './types';

export const hasUser = (auth: Auth): auth is AuthEnriched => {
  if ('user' in auth && auth.user) {
    return true;
  }

  return false;
};

export const hasToken = (auth: Auth): auth is AuthAuthorized => {
  if ('token' in auth && auth.token) {
    return true;
  }

  return false;
};

export const hasPublicKey = (auth: Auth): auth is AuthConnected => {
  if ('publicKey' in auth && auth.publicKey) {
    return true;
  }

  return false;
};

export const isPending = (
  auth: Auth
): auth is ExtractAuthState<'unknown'> | ExtractAuthState<'enriching'> => {
  return auth.state === 'unknown' || auth.state === 'enriching';
};

export const isMyAddress = (auth: Auth, address: string) => {
  if (!hasPublicKey(auth)) {
    return false;
  }

  return areKeysEqual([auth.publicKey, address]);
};

export const isAdmin = (auth: Auth): auth is AuthEnriched => {
  return hasUser(auth) && auth.user && auth.user.isAdmin;
};

export const getConnectedUsernameOrPublicKey = (
  auth: AuthConnected
): string => {
  return hasUser(auth) && auth.user.username
    ? auth.user.username
    : auth.publicKey;
};

export const isConnectedToChainId = (options: {
  auth: AuthConnected;
  chainId: ChainId;
}) => {
  const { auth, chainId } = options;

  if (!auth.isSupportedChain) {
    return false;
  }

  return auth.chainId === chainId;
};

export const isChainSwitchRequired = (options: {
  auth: Auth;
  chainId: ChainId;
}) => {
  const { auth, chainId } = options;

  return hasPublicKey(auth) && !isConnectedToChainId({ auth, chainId });
};
