import { z } from 'zod';

import { isSupportedChainId, isSupportedChainSlug } from 'utils/network';

export const chainIdSchema = z.coerce.number().transform((chainId, ctx) => {
  if (isSupportedChainId(chainId)) {
    return chainId;
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'Unsupported chainId',
  });

  return z.NEVER;
});

export const chainSlugSchema = z.string().transform((slug, ctx) => {
  if (isSupportedChainSlug(slug)) {
    return slug;
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'Unsupported chain slug',
  });

  return z.NEVER;
});
