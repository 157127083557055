import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { ReactElement, ReactNode, useEffect } from 'react';

import 'assets/css/fonts.css';
import 'assets/css/global.css';
import 'assets/css/progress.css';
import 'assets/css/toggle.css';
import 'minireset.css/minireset.css';
import 'react-toggle/style.css';

import NotificationCenter from 'components/NotificationCenter';
import TOSBanner from 'components/TOSBanner';
import ProgressBar from 'components/TopProgressBar';
import TransactionTracker from 'components/TransactionTracker';
import AnalyticsProvider from 'contexts/analytics/AnalyticsProvider';
import AuthProvider from 'contexts/auth/AuthProvider';
import { Web3AuthServiceProviders } from 'contexts/auth/AuthServiceProviders';
import { isAdmin } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import { globalStyles } from 'css/global';

import useTrackModalState from 'hooks/analytics/use-track-modal-state';
import useTrackRouterView from 'hooks/analytics/use-track-router-view';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import { logger } from 'lib/logger';
import { ENV } from 'utils/env';
import { POPOVER_CONTAINER_ID } from 'utils/popover';

export type NextPageWithLayout<
  PageProps = Record<string, unknown>,
  InitialProps = PageProps,
> = NextPage<PageProps, InitialProps> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

const DynamicAdminControlCenterModal = dynamic(
  () =>
    import('components/admin/AdminControlCenterModal').then((module) => {
      return module.AdminControlCenterModal;
    }),
  {
    ssr: false,
  }
);

const DynamicGlobalModals = dynamic(
  () =>
    import('components/app/modal').then((module) => {
      return module.GlobalModals;
    }),
  {
    ssr: false,
  }
);

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);

  globalStyles();

  return (
    <Web3AuthServiceProviders>
      <ProgressBar />
      <SetupBrandshield />
      <SetupLogger />
      <FndProviders>
        <DynamicGlobalModals />
        <TOSBanner />
        <div id={POPOVER_CONTAINER_ID} />
        {getLayout(<Component {...pageProps} />)}
        <NotificationCenter />
        <TransactionTracker />
        <Tracking />
        <SetLoggerContext />
        <AdminTools />
      </FndProviders>
      <ReactQueryDevtools initialIsOpen={false} />
    </Web3AuthServiceProviders>
  );
}

function AdminTools() {
  const isHydrated = useIsHydrated();
  const auth = useAuth();

  if (!isHydrated) {
    return null;
  }

  if (!isAdmin(auth)) {
    return null;
  }

  return <DynamicAdminControlCenterModal adminAddress={auth.publicKey} />;
}

type ProvidersProps = {
  children: ReactNode;
};

/**
 * Context providers maintained by us.
 */

function FndProviders(props: ProvidersProps) {
  return (
    <AuthProvider>
      <AnalyticsProvider>{props.children}</AnalyticsProvider>
    </AuthProvider>
  );
}

function SetupBrandshield() {
  useEffect(function pingBrandshield() {
    if (!ENV.BRANDSHIELD_DOMAIN_TOKEN) return;
    try {
      fetch(`https://wp-nav-pro.com/vrht/${ENV.BRANDSHIELD_DOMAIN_TOKEN}`, {
        mode: 'no-cors', // prevents a console warning
      });
    } catch (err) {
      logger.error('Failed to ping Brandshield', { error: err });
    }
  }, []);

  return null;
}

function SetupLogger() {
  useEffect(function setupLogger() {
    logger.init();
  }, []);

  return null;
}

function SetLoggerContext() {
  const auth = useAuth();

  useEffect(
    function addAuthToLogs() {
      logger.setAuthContext(auth);
    },
    [auth.state]
  );

  return null;
}

/**
 * Note: useTrackRouterView must be rendered inside our AuthProvider AND AnalyticsProvider to function correctly
 */
function Tracking() {
  useTrackRouterView();
  useTrackModalState();

  return null;
}
