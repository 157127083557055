import { darkMode, styled } from '@f8n-frontend/stitches';

import Box from 'components/base/Box';

const Divider = styled(Box, {
  width: '100%',
  backgroundColor: '$black5',
  height: 1,

  [darkMode]: {
    backgroundColor: '$black10',
  },

  variants: {
    orientation: {
      vertical: {
        width: 1,
        height: '100%',
      },
    },
  },
});

export default Divider;
