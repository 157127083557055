import { styled, darkMode } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';

import Text from 'components/base/Text';

type LinkProps = ComponentProps<typeof Link>;

const Link = styled('a', Text, {
  // Resets to handle as="button", for buttons which look like anchors
  appearance: 'none',
  background: 'none',
  border: 'none',
  padding: 0,
  fontFamily: 'inherit',
  fontSize: 'inherit',
  color: 'inherit',
  cursor: 'pointer',
  textDecoration: 'none',
  transition:
    'color $1 $ease, background-color $1 $ease, opacity $1 $ease, outline $1 $ease, transform $1 $ease',

  '&:focus-visible': {
    outline: '4px solid $black30',
    outlineOffset: '4px',
    borderRadius: '$2',
  },

  '&:active': {
    transform: 'translate3d(0, 1px, 0)',
  },
  // TO DO: this is targeting UserTags inside the market widget for worlds
  // if you change this, ensure the text within the UserTag is still white in dark mode
  [darkMode]: {
    color: '$black100',
  },

  variants: {
    isDark: {
      true: {},
    },
    hasUnderline: {
      true: {
        display: 'inline-block',
        underline: true,
      },
    },
    variant: {
      primary: {
        color: '$black60',
        '@hover': {
          '&:hover': {
            color: '$black100',
          },
        },
      },
      strong: {
        color: '$black100',
        '@hover': {
          '&:hover': {
            color: '$black60',
          },
        },
      },
      current: {
        color: 'currentColor',
        '@hover': {
          '&:hover': {
            opacity: 0.6,
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      isDark: true,
      variant: 'primary',
      css: {
        color: '$white70',
        '@hover': {
          '&:hover': {
            color: '$white100',
          },
        },
      },
    },
    {
      isDark: true,
      variant: 'strong',
      css: {
        color: '$white100',
        '@hover': {
          '&:hover': {
            color: '$white70',
          },
        },
      },
    },
  ],
});

export type { LinkProps };
export default Link;
