import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ApiUserVariables, useUser } from 'gql/api/queries/user.generated';
import { userCacheKey } from 'utils/cache';

import { DeepNullable, FetcherReturnType } from 'types/utils';

import { isQueryEnabled } from '../shared';

async function getUserByPublicKey(variables: ApiUserVariables) {
  const fetchUser = useUser.fetcher(variables);
  const data = await fetchUser();

  if (!data || !data.account) {
    throw new Error('Failed to fetch user');
  }

  const account = data.account;
  const adminFields = account.adminFields;

  return {
    ...account,
    // TODO: update this to avoid casting to false
    isAdmin: adminFields ? Boolean(adminFields.isAdmin) : false,
  };
}

type UserByPublicKey = FetcherReturnType<typeof getUserByPublicKey>;

export default function useUserByPublicKey<Selected = UserByPublicKey>(
  variables: DeepNullable<ApiUserVariables>,
  options: Pick<
    UseQueryOptions<
      UserByPublicKey,
      Error,
      Selected,
      ReturnType<typeof userCacheKey.byPublicKey>
    >,
    | 'enabled'
    | 'refetchOnWindowFocus'
    | 'refetchOnMount'
    | 'staleTime'
    | 'retry'
  > = {}
) {
  const userByPublicKey = useQuery({
    ...options,
    queryKey: userCacheKey.byPublicKey(variables.publicKey as string),
    queryFn: () => getUserByPublicKey(variables as ApiUserVariables),
    enabled: isQueryEnabled(options) && Boolean(variables.publicKey),
  });

  return userByPublicKey;
}
