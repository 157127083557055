import {
  Address,
  getAddress as viemGetAddress,
  isAddress as viemIsAddress,
  isHex as viemIsHex,
} from 'viem';

import { ZERO_ADDRESS } from 'lib/constants';

export const getAddress = viemGetAddress;

export const isAddress = viemIsAddress;

export const isHex = viemIsHex;

export const isZeroAddress = (address: Address) => {
  return address === ZERO_ADDRESS;
};
