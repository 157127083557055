import { GraphQLClient } from 'graphql-request';

import { queryClient } from 'lib/reactQueryClient';
import { connectedUserCache } from 'utils/cache';
import { ENV } from 'utils/env';

/**
 * By default, GraphQLClient uses window.XMLHttpRequest.
 * This works in both the browser + during SSR, but NOT in Next.js middleware or Edge API routes.
 * @see https://nextjs.org/docs/api-reference/edge-runtime
 *
 * For this reason, we override use of window.XMLHttpRequest with the global `fetch` object.
 * This isn't really a workaround, because GraphQL works fine with `fetch`.
 */

export const fndNodeClient = (token?: string): GraphQLClient => {
  const graphQLURL = new URL('graphql', ENV.NODE_SERVER_URL);
  // token from param is only really used in the initial auth as its an api call and query client is undefined there.

  const getAuthToken = () => {
    if (token) return token;

    if (typeof window === 'undefined') return undefined;

    return queryClient.getQueryData<string | undefined>(
      connectedUserCache.authToken
    );
  };

  const authToken = getAuthToken();

  return new GraphQLClient(graphQLURL.href, {
    fetch,
    headers: {
      ...(authToken && { authorization: `Bearer ${authToken}` }),
    },
  });
};

export const fndApiClient = (token?: string): GraphQLClient => {
  const graphQLURL = new URL('graphql', ENV.API_URL);

  const getAuthToken = () => {
    if (token) return token;

    if (typeof window === 'undefined') return undefined;

    return queryClient.getQueryData<string | undefined>(
      connectedUserCache.authToken
    );
  };

  const authToken = getAuthToken();

  return new GraphQLClient(graphQLURL.href, {
    fetch,
    headers: {
      ...(authToken && {
        'X-authentication': `Bearer ${authToken}`,
      }),
    },
  });
};

export const nodeFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  _options?: unknown // appeases TS. Generated fetchers pass a third argument.
): (() => Promise<TData>) => {
  return async () => {
    const client = fndNodeClient();
    return await client.request<TData, TVariables>(query, variables);
  };
};

export const apiFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
): (() => Promise<TData>) => {
  return async () => {
    const client = fndApiClient();
    return await client.request<TData, TVariables>(query, variables, headers);
  };
};
