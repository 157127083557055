import { createContext } from 'react';

import { noop } from 'utils/helpers';

import { Auth } from './types';

const defaultAuthContext: Auth = {
  state: 'disconnected',
  startLogin: noop,
};

const AuthContext = createContext<Auth>(defaultAuthContext);

export default AuthContext;
