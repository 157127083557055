import { match } from 'ts-pattern';
import { z } from 'zod';

import { chainCategorySchema } from 'schemas/env/chain-category';

import { BlockExplorerService } from 'types/block-explorer';
import { HttpsUrl } from 'types/url';
import { RecordValues } from 'types/utils';

export type ChainCategory = z.infer<typeof chainCategorySchema>;

export type Chain = 'mainnet' | 'sepolia' | 'base' | 'baseSepolia';

type ChainConfigShape = {
  /** service for L2s to bridge ETH */
  bridge?: HttpsUrl;
  /** on-chain ID for the chain (i.e. 1) */
  chainId: number;
  /** Internal name for the network (i.e. mainnet) */
  chain: Chain;
  /** User-facing slug for the network (i.e. ethereum) */
  slug: string;
  /** UI copy that can be shown to users */
  copy: {
    chainBenefits?: string;
    chainName: string;
    connectToChain: string;
    supportedFeatures: string;
    verboseChainName: string;
  };
  /**
   * - Configuration used to build links to third-party block explorers.
   * - We configure one block-explorer per entity, because some services do not support certain features on certain chains.
   * - For example, at the time of writing, Etherscan doesn't support `nft` URLs for the base chain
   * - This configuration also enables us to swap out block explorers for L2-optimized services in the future.
   * */
  blockExplorers: {
    address: BlockExplorerService;
    nft: BlockExplorerService;
    tx: BlockExplorerService;
  };
};

/**
 * This function does nothing at runtime, but it's useful to give us strong type-safety when defining our chain configs.
 * It allows us to ensure every chain config has the same structure, while still allowing us to use `as const` to get more specific types for each individual chain.
 *
 * TODO: replace with `satisfies` once Jest supports it
 * */
const createChainConfig = <Options extends ChainConfigShape>(
  options: Options
) => {
  return options;
};

export const MAINNET_CHAIN_IDS = {
  base: 8_453,
  mainnet: 1,
} as const;

export const TESTNET_CHAIN_IDS = {
  baseSepolia: 84_532,
  sepolia: 11_155_111,
} as const;

export const CHAIN_IDS = {
  ...MAINNET_CHAIN_IDS,
  ...TESTNET_CHAIN_IDS,
};

const BASE_COPY = {
  benefits:
    'Base is an Ethereum-based network with faster transactions and lower fees.',
};

const FEATURE_SUPPORT_COPY = {
  /** Used for chains with 100% feature-support */
  full: 'Supports Drops, Editions, NFTs, Generative Art',
  /** Used base L2 */
  base: 'Supports Generative Art', // TODO: update once we support Drops, Editions, etc
};

export const MAINNET_CHAINS = {
  /**
   * The OG Ethereum mainnet
   */
  mainnet: createChainConfig({
    chain: 'mainnet',
    chainId: MAINNET_CHAIN_IDS.mainnet,
    slug: 'eth',
    copy: {
      connectToChain: 'Connect',
      chainName: 'Ethereum',
      supportedFeatures: FEATURE_SUPPORT_COPY.full,
      verboseChainName: 'Ethereum mainnet',
    },
    blockExplorers: {
      address: 'etherscan',
      nft: 'etherscan',
      tx: 'etherscan',
    },
  } as const),
  /**
   * @see https://docs.base.org/network-information/
   */
  base: createChainConfig({
    bridge: 'https://superbridge.app/base',
    chain: 'base',
    chainId: MAINNET_CHAIN_IDS.base,
    slug: 'base',
    copy: {
      connectToChain: 'Connect to Base',
      chainBenefits: BASE_COPY.benefits,
      chainName: 'Base',
      supportedFeatures: FEATURE_SUPPORT_COPY.base,
      verboseChainName: 'Base mainnet',
    },
    blockExplorers: {
      address: 'etherscan',
      nft: 'blockscout',
      tx: 'etherscan',
    },
  } as const),
};

export const TESTNET_CHAINS = {
  /**
   * @see https://docs.base.org/network-information/
   */
  baseSepolia: createChainConfig({
    bridge: 'https://superbridge.app/base-sepolia',
    chain: 'baseSepolia',
    chainId: TESTNET_CHAIN_IDS.baseSepolia,
    slug: 'base-sepolia',
    copy: {
      connectToChain: 'Connect to Base Sepolia',
      chainBenefits: BASE_COPY.benefits,
      chainName: 'Base Sepolia',
      supportedFeatures: FEATURE_SUPPORT_COPY.base,
      verboseChainName: 'Base Sepolia testnet',
    },
    blockExplorers: {
      address: 'etherscan',
      nft: 'blockscout',
      tx: 'etherscan',
    },
  } as const),
  /**
   * @see https://sepolia.dev/
   */
  sepolia: createChainConfig({
    chain: 'sepolia',
    chainId: TESTNET_CHAIN_IDS.sepolia,
    slug: 'sepolia',
    copy: {
      connectToChain: 'Connect to Sepolia',
      chainName: 'Sepolia',
      supportedFeatures: FEATURE_SUPPORT_COPY.full,
      verboseChainName: 'Sepolia testnet',
    },
    blockExplorers: {
      address: 'etherscan',
      nft: 'etherscan',
      tx: 'etherscan',
    },
  } as const),
};

export const CHAINS = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
};

export const getChainConfig = (chain: Chain) => {
  return CHAINS[chain];
};

type MainnetChains = RecordValues<typeof MAINNET_CHAINS>;
type TestnetChains = RecordValues<typeof TESTNET_CHAINS>;
export type ChainConfig = MainnetChains | TestnetChains;

export type ChainId = ChainConfig['chainId'];
export type ChainSlug = ChainConfig['slug'];

export const getPrimaryChainConfig = (category: ChainCategory) => {
  return match(category)
    .with('mainnet', () => MAINNET_CHAINS.mainnet)
    .with('testnet', () => TESTNET_CHAINS.sepolia)
    .exhaustive();
};

export const getL2ChainConfig = (category: ChainCategory) => {
  return match(category)
    .with('mainnet', () => MAINNET_CHAINS.base)
    .with('testnet', () => TESTNET_CHAINS.baseSepolia)
    .exhaustive();
};

export const getPrimaryChainId = (category: ChainCategory) => {
  return getPrimaryChainConfig(category).chainId;
};

export const getPrimaryChain = (category: ChainCategory) => {
  return getPrimaryChainConfig(category).chain;
};

type PrimaryChainConfig = ReturnType<typeof getPrimaryChainConfig>;
export type PrimaryChainId = PrimaryChainConfig['chainId'];
export type PrimaryChain = PrimaryChainConfig['chain'];

/**
 * Base + Base testnet chainIds
 */
export type BaseChainId = typeof CHAIN_IDS.base | typeof CHAIN_IDS.baseSepolia;

/** Chains that support bridging */
export type BridgeEnabledChainConfig = Extract<ChainConfig, { bridge: string }>;
