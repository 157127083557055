import { AnalyticsBrowser as SegmentAnalyticsBrowser } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef } from 'react';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { logger } from 'lib/logger';
import report from 'lib/report';
import { ENV, IS_DEV, IS_PREVIEW } from 'utils/env';
import { isBrowser } from 'utils/helpers';

import AnalyticsContext from './AnalyticsContext';
import { Analytics } from './types';

function reportSegmentUninitialized() {
  report(new Error('Segment is not initialized'));
}

type AnalyticsProviderProps = {
  children: React.ReactNode;
};

const isFallback = IS_DEV || (IS_PREVIEW && !ENV.SEGMENT_KEY);

export default function AnalyticsProvider(props: AnalyticsProviderProps) {
  const auth = useAuth();
  const router = useRouter();

  const hasInitializedRef = useRef(false);
  const referrerRef = useRef<string | null>(null);

  /**
   * Initialize Segment on the client, when SEGMENT_KEY is set
   */
  const segment = useMemo(() => {
    if (isBrowser() && ENV.SEGMENT_KEY) {
      return SegmentAnalyticsBrowser.load({
        writeKey: ENV.SEGMENT_KEY,
      });
    }
  }, []);

  const analytics = useMemo<Analytics>(() => {
    if (isFallback) {
      return {
        track(options) {
          logger.info('analytics.track', options);
        },
        legacyTrack(name, payload) {
          logger.info('analytics.track (legacy)', {
            ...payload,
            name,
          });
        },
      };
    }

    return {
      track(options) {
        const { name, ...payload } = options;

        logger.info('analytics.track', options);

        if (!segment) return;
        segment.track(name, {
          ...(payload || {}),
          // Add the pathname to every event we track
          pathname: router.pathname,
        });
      },
      legacyTrack(name, payload) {
        if (!segment) return;

        logger.info('analytics.track (legacy)', {
          ...payload,
          name,
        });

        segment.track(name, {
          ...(payload || {}),
          // Add the pathname to every event we track
          pathname: router.pathname,
        });
      },
    };
  }, [segment, router.pathname]);

  useEffect(
    function identify() {
      // Return early if already initialized
      if (hasInitializedRef.current) return;

      // Return early if disconnected
      if (!hasPublicKey(auth)) return;

      logger.info('analytics.identify', {
        publicKey: auth.publicKey,
      });

      if (isFallback) return;

      if (!segment) {
        return reportSegmentUninitialized();
      }

      segment.identify(auth.publicKey, {
        // Kept as `publicAddress` for backwards compatibility in our analytics
        publicAddress: auth.publicKey,
        provider: auth.connectorId,
      });

      hasInitializedRef.current = true;
    },
    [auth.state]
  );

  useEffect(
    function trackPage() {
      if (!referrerRef.current) {
        // Use the page referrer, if available
        referrerRef.current = window.document.referrer;
      }

      const referrer = referrerRef.current;

      logger.info('analytics.page', {
        ...router.query,
        pathname: router.pathname,
        referrer,
      });

      if (isFallback) return;

      if (!segment) {
        return reportSegmentUninitialized();
      }

      const properties = {
        ...router.query,
        referrer,
      };

      segment.page(undefined, router.pathname, properties);

      // Manually capture referrer because we're using client-side routing
      referrerRef.current = window.location.href;
    },
    [router.pathname]
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}
