import { type Logger as DataDogLogger } from '@datadog/browser-logs';

import { hasPublicKey } from 'contexts/auth/helpers';
import { Auth } from 'contexts/auth/types';

import { ENV, IS_DEV } from 'utils/env';

import { LoggerContext, LoggerType } from 'types/logger';

const DATADOG_CLIENT_TOKEN = ENV.DATADOG_CLIENT_TOKEN;

class Logger implements LoggerType {
  private dataDogLogger: DataDogLogger | null = null;

  private isDataDogReady(dd: DataDogLogger | null): dd is DataDogLogger {
    return dd !== null;
  }

  public init() {
    const shouldEnableDataDog =
      !IS_DEV &&
      typeof DATADOG_CLIENT_TOKEN === 'string' &&
      typeof window !== 'undefined';

    if (!shouldEnableDataDog) return;
    /*
     * Initialise logging to Datadog
     * @see https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
     */
    const initDataDog = async () => {
      const datadogLogs = await import('@datadog/browser-logs')
        .then((mod) => mod.datadogLogs)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });

      if (!datadogLogs) return;

      this.dataDogLogger = datadogLogs.logger;

      datadogLogs.init({
        clientToken: DATADOG_CLIENT_TOKEN,
        /*
         * This is fixed and therefore not needed as an env var
         * @see https://docs.datadoghq.com/getting_started/site/
         */
        site: 'datadoghq.com',
        // forwards console.error logs, uncaught exceptions and network errors to Datadog.
        forwardErrorsToLogs: true,
        // The percentage of sessions to track
        sampleRate: 100,
        // The application’s environment, either: production or preview.
        env: ENV.VERCEL_ENV,
        // The service name for the application e.g. fnd-frontend-production
        service: `fnd-frontend-${ENV.VERCEL_ENV}`,
      });
    };

    try {
      initDataDog();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public error(message: string, context?: LoggerContext | Error) {
    const dd = this.dataDogLogger;

    if (this.isDataDogReady(dd)) {
      if (context instanceof Error) {
        const error = context;
        dd.error(message, {
          cause: error.cause,
          name: error.name,
          stack: error.stack,
          message: error.message,
        });
      } else {
        dd.error(message, context);
      }
    } else {
      // Note: This should be the only place we use console.error
      // eslint-disable-next-line no-console
      context ? console.error(message, context) : console.error(message);
    }
  }

  public info(message: string, context?: LoggerContext) {
    const dd = this.dataDogLogger;

    if (this.isDataDogReady(dd)) {
      dd.info(message, context);
    } else {
      // Note: This should be the only place we use console.info
      // eslint-disable-next-line no-console
      context ? console.info(message, context) : console.info(message);
    }
  }

  public log(message: string, context?: LoggerContext) {
    const dd = this.dataDogLogger;

    if (this.isDataDogReady(dd)) {
      dd.log(message, context);
    } else {
      // Note: This should be the only place we use console.log
      // eslint-disable-next-line no-console
      context ? console.log(message, context) : console.log(message);
    }
  }

  public setAuthContext(auth: Auth) {
    const dd = this.dataDogLogger;

    const authContext = hasPublicKey(auth)
      ? { publicKey: auth.publicKey, state: auth.state }
      : null;

    if (this.isDataDogReady(dd)) {
      dd.addContext('auth', authContext);
    } else {
      // Note: This should be the only place we use console.log
      // eslint-disable-next-line no-console
      console.info('loggerAuthContext', authContext);
    }
  }
}

export const logger = new Logger();
