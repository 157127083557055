import {
  ContractType,
  DropContractType,
  EditionContractType,
} from 'types/Collection';

export const isEditionContract = (
  contractType: ContractType
): contractType is EditionContractType => {
  return contractType === 'LIMITED_EDITION' || contractType === 'TIMED_EDITION';
};

export const isDropContract = (
  contractType: ContractType
): contractType is DropContractType => {
  return (
    contractType === 'FND_BATCH_MINT_REVEAL' ||
    contractType === 'HIGHLIGHT_GENERATIVE_SERIES'
  );
};

export const isMintableContract = (
  contractType: ContractType
): contractType is DropContractType | EditionContractType => {
  return isDropContract(contractType) || isEditionContract(contractType);
};
