import { ICON_SIZE_MAP } from '@f8n/icons/dist/helpers/size';
import { keyframes, styled } from '@f8n-frontend/stitches';

import SpinnerIcon from 'assets/icons/spinner.svg';
import { createCssTarget } from 'utils/styles';

import { SuggestNumber } from 'types/utils';

export const SPINNER_TARGET = createCssTarget('spinner');

const rotate = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

type Size = keyof typeof ICON_SIZE_MAP;

interface SpinnerStrokedProps {
  size?: SuggestNumber<Size>;
}

export default function Spinner(props: SpinnerStrokedProps) {
  const { size = 0 } = props;
  const sizeNumber = size as number;

  // Supports a set of predefined sizes, or any custom number
  const containerSize = ICON_SIZE_MAP[sizeNumber] || size;

  return (
    <SpinnerContainer
      className={SPINNER_TARGET.className}
      css={{
        width: containerSize,
        height: containerSize,
      }}
      data-testid="loading"
    >
      <SpinnerIcon />
    </SpinnerContainer>
  );
}

const SpinnerContainer = styled('div', {
  display: 'flex',
  animation: `${rotate} 1000ms linear infinite`,
  animateFillMode: 'forwards',

  svg: {
    width: 'inherit',
    height: 'inherit',
    transformOrigin: '50%',

    path: {
      animation: `${rotate} 2000ms ease-in-out infinite`,
      transformOrigin: '50%',
    },
  },
});
