import { IS_MAINNET } from 'utils/env';

import { castToMutableArray } from 'types/utils';

import { WAGMI_MAINNET_CHAINS, WAGMI_TESTNET_CHAINS } from './wagmi';

export const getPrivySupportedChains = () => {
  if (IS_MAINNET) {
    return castToMutableArray(WAGMI_MAINNET_CHAINS);
  } else {
    return castToMutableArray(WAGMI_TESTNET_CHAINS);
  }
};
