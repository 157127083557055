import { create } from 'zustand';

import { ModalApi } from 'types/modal';

const useModal = create<ModalApi>((set) => ({
  activeModal: null,
  setModal: (modal) => set(() => ({ activeModal: modal })),
}));

export default useModal;
