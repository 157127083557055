import {
  always,
  apply,
  clamp,
  compose,
  cond,
  equals,
  isEmpty,
  isNil,
  reject,
  T,
} from 'ramda';

import { Percent, Ratio } from 'types/number';

// ignores 0 and null values when searching for a min in an array
export const nonZeroMin = (values: number[]): number | null => {
  return compose(
    cond([
      // If the resulting array is empty return null
      [isEmpty, always(null)],
      // Otherwise find the smallest value from the list
      [T, apply(Math.min)],
    ]),
    // Remove 0 values
    reject(equals(0)),
    // Remove any nulls from array
    reject(isNil)
  )(values);
};

export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

export const secondsToMs = (seconds: number): number => seconds * 1000;

/**
 * @param baseRate value like 2000 (meaning 20%)
 * @returns percent value like 20 (meaning 20%)
 */
export const basisPointsToPercent = (baseRate: number): number => {
  return baseRate / 100;
};

/**
 * Ensures a value is between 0 and 100.
 */
export const clampPercent = (value: number): Percent => {
  return clamp(0, 100, value);
};

/**
 * Ensures a value is between 0 and 1.
 */
export const clampRatio = (value: number): Ratio => {
  return clamp(0, 1, value);
};

/**
 * Curried function to ensures a value is between min and max.
 */
export const clampToRange = (options: { min: number; max: number }) => {
  return (count: number) => clamp(options.min, options.max, count);
};

export const isZero = (value: bigint): boolean => value === BigInt(0);

export const isNonZero = (value: bigint): boolean => value > BigInt(0);
