import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { z } from 'zod';

import useTargetChain from 'state/stores/target-chain';

import { chainSlugSchema } from 'schemas/url/chain';
import { getChainConfigBySlug } from 'utils/network';

const chainAwareQueryParamSchema = z.object({
  chain: chainSlugSchema,
});

export function useRouterTargetChain() {
  const router = useRouter();
  const setTargetChain = useTargetChain(({ setTargetChain }) => setTargetChain);

  useEffect(() => {
    const parsedRouterQuery = chainAwareQueryParamSchema.safeParse(
      router.query
    );

    if (!parsedRouterQuery.success) return;

    const chainSlug = parsedRouterQuery.data.chain;
    const chainConfig = getChainConfigBySlug(chainSlug);
    setTargetChain(chainConfig);
  }, [router.asPath]);
}
