import type { BaseRouter as BaseNextRouter } from 'next/dist/shared/lib/router/router';

import { ChainId } from 'lib/chains';

import { ViewedEventBase, AnalyticsChainSummary } from 'types/Analytics';

import { getChainConfigById } from './network';
import { getPath } from './router';

type MinimalRouter = Pick<BaseNextRouter, 'pathname'>;

export const detectRouterAnalyticsViewEvent = (
  router: MinimalRouter
): ViewedEventBase | null => {
  const { pathname } = router;

  if (pathname.startsWith('/create')) {
    const name = 'viewed_create_flow';

    switch (pathname) {
      case getPath.create.mint:
        return {
          name,
          createType: 'nft',
        };
      case getPath.create.collection:
        return {
          name,
          createType: 'collection',
        };
      case getPath.create.drop:
        return {
          name,
          createType: 'drop',
        };
      case getPath.create.world.page:
        return {
          name,
          createType: 'world',
        };
    }
  }

  return null;
};

export const getChainAnalyticsSummary = (
  chainId: ChainId
): AnalyticsChainSummary => {
  const config = getChainConfigById(chainId);

  return {
    chainName: config.chain,
    chainId: config.chainId,
  } as const;
};
