import { globalCss } from '@f8n-frontend/stitches';

import DropdownMenu from 'components/base/DropdownMenu';
import Popover from 'components/base/Popover';

import { privyStyles } from './privy';

const rootStyles = globalCss({
  body: {
    fontFamily: '$body',
    WebkitFontSmoothing: 'antialiased',
    color: '$black100',
    transition: 'background-color $2 $ease',
    overflowX: 'hidden',
  },
});

export const globalStyles = () => {
  rootStyles();
  privyStyles();

  // Global styles related to specific components
  DropdownMenu.globalStyles();
  Popover.globalStyles();
};
