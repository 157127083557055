import { CheckIcon, ErrorIcon, ExternalIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { DotsSmall } from 'components/SpinnerDots';
import SpinnerStroked from 'components/SpinnerStroked';
import Button from 'components/base/Button';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import Toast from 'components/base/Toast';

import { logger } from 'lib/logger';
import { getBlockExplorerByChainId } from 'utils/block-explorer';

import { TrackedTx } from 'types/Transactions';

export default React.memo(TransactionTracker);

type TransactionTrackerProps = {
  transactions: TrackedTx[];
  onRemove(transaction: TrackedTx): void;
};

function TransactionTracker(props: TransactionTrackerProps) {
  const transactions = props.transactions;

  return (
    <Toast.Provider label="Transactions">
      <AnimatePresence>
        {transactions.map((transaction) => (
          <TransactionNotification
            key={transaction.txHash}
            onClose={() => {
              props.onRemove(transaction);
            }}
            {...transaction}
          />
        ))}
      </AnimatePresence>
      <Toast.Viewport
        css={{
          right: 0,
          left: 0,
          transform: 'none',
          '@bp0': {
            left: 'auto',
          },
        }}
      />
    </Toast.Provider>
  );
}

type TransactionNotificationProps = TrackedTx & {
  onClose: () => void;
};

const UPDATE_INTERVAL = 1000;

const DELAY_THRESHOLD = 5000;

const DD_EVENT_NAME = 'tracked-transaction';

function TransactionNotification(props: TransactionNotificationProps) {
  const { chainId, title, txHash, onClose } = props;

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (isOpen) return;

    logger.info(DD_EVENT_NAME, {
      eventName: DD_EVENT_NAME,
      txStatus: 'closed-by-user',
      txHash: txHash,
    });
  }, [isOpen]);

  useEffect(() => {
    logger.info(DD_EVENT_NAME, {
      eventName: DD_EVENT_NAME,
      txStatus: 'awaiting-confirmation',
      txHash: txHash,
    });
  }, []);

  const [timeElapsed, setTimeElapsed] = useState<number>(0);

  useInterval(
    () => setTimeElapsed((delay) => delay + UPDATE_INTERVAL),
    props.status === 'INDEXING' || props.status === 'SUCCESS'
      ? UPDATE_INTERVAL
      : null
  );

  const renderIcon = () => {
    if (props.status === 'ERROR' || props.status === 'REVERTED') {
      return (
        <IconWrapper>
          <IconInner variant="error">
            <ErrorIcon size={1} />
          </IconInner>
        </IconWrapper>
      );
    } else if (props.status === 'SUCCESS') {
      return (
        <IconWrapper as="a">
          <IconInner variant="success">
            <CheckIcon size={0} />
          </IconInner>
        </IconWrapper>
      );
    } else if (props.status === 'INDEXING') {
      return (
        <IconWrapper>
          <DotsSmall
            css={{ width: '20px', backgroundSize: 'calc(100%/5) 40%' }}
          />
        </IconWrapper>
      );
    } else {
      return (
        <IconWrapper>
          <SpinnerStroked size={16} />
        </IconWrapper>
      );
    }
  };

  const renderHeading = () => {
    if (props.status === 'REVERTED' || props.status === 'ERROR') {
      return <TrackerHeading color="error">Transaction error</TrackerHeading>;
    } else if (props.status === 'SUCCESS') {
      return <TrackerHeading>{title.SUCCESS}</TrackerHeading>;
    } else if (props.status === 'INDEXING' && timeElapsed >= DELAY_THRESHOLD) {
      return <TrackerHeading>Syncing on Foundation</TrackerHeading>;
    } else if (props.status === 'INDEXING') {
      return <TrackerHeading>Confirmed on chain</TrackerHeading>;
    } else {
      return <TrackerHeading>{title.PENDING}</TrackerHeading>;
    }
  };

  const renderAction = () => {
    if (
      props.status === 'SUCCESS' ||
      props.status === 'REVERTED' ||
      props.status === 'ERROR'
    ) {
      return (
        <Toast.Action altText="Close" asChild>
          <Button
            variant="ghost"
            onClick={() => setIsOpen(false)}
            size={0}
            css={{ color: '$black60' }}
          >
            Close
          </Button>
        </Toast.Action>
      );
    } else {
      return null;
    }
  };

  const explorer = getBlockExplorerByChainId(chainId);

  return (
    <AnimatePresence
      onExitComplete={() => {
        onClose();
      }}
    >
      {isOpen && (
        <Toast.Root forceMount>
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ translateY: '100%', opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.4, type: 'spring' }}
            layout
          >
            <Toast.Body css={{ paddingLeft: '$3' }} hasAction hasIcon>
              <Toast.Description asChild>
                <>
                  {renderIcon()}
                  <BodyWrapper>
                    {renderHeading()}
                    <ExternalLink
                      href={explorer.tx.getUrl({ txHash })}
                      target="_blank"
                      rel="noreferrer"
                      variant="primary"
                    >
                      {explorer.tx.copy.viewOnCta} <ExternalIcon size={0} />
                    </ExternalLink>
                  </BodyWrapper>
                </>
              </Toast.Description>
              {renderAction()}
            </Toast.Body>
          </motion.div>
        </Toast.Root>
      )}
    </AnimatePresence>
  );
}

const TrackerHeading = styled(Text);

TrackerHeading.defaultProps = {
  color: 'strong',
  weight: 'semibold',
  lineHeight: 0,
  size: 1,
};

const BodyWrapper = styled('div', {
  gap: '$1',
  display: 'flex',
  flexDirection: 'column',
});

const IconWrapper = styled('div', {
  width: '42px',
  height: '42px',
  display: 'flex',
  borderRadius: '$2',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$black5',
});

const IconInner = styled('div', {
  width: '$icon1',
  height: '$icon1',
  borderRadius: '$round',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    display: 'block',
  },

  variants: {
    variant: {
      success: {
        color: '$white100',
        backgroundColor: '$green4',
      },
      error: {
        color: '$red3',
      },
    },
  },
});

const ExternalLink = styled(Link, {
  gap: '2px',
  display: 'flex',
  fontSize: '12px',
  alignItems: 'center',
  lineHeight: '$base',
});
