import { QueryObserverResult } from '@tanstack/react-query';
import { useEffect } from 'react';

import { RequireAtLeastOne, UnsafeAny } from 'types/utils';

export function useQueryEffects<
  TData extends UnsafeAny,
  TError extends UnsafeAny,
  QueryResult extends Pick<
    QueryObserverResult<TData, TError>,
    | 'status'
    | 'isSuccess'
    | 'data'
    | 'isError'
    | 'error'
    | 'dataUpdatedAt'
    | 'errorUpdatedAt'
  >,
  SuccessResult extends Extract<QueryResult, { status: 'success' }>,
  ErrorResult extends Extract<QueryResult, { status: 'error' }>,
>(
  query: QueryResult,
  options: RequireAtLeastOne<{
    onSuccess: (data: SuccessResult['data']) => void;
    onError: (error: ErrorResult['error']) => void;
  }>
) {
  useEffect(() => {
    if (query.isSuccess && options.onSuccess) {
      options.onSuccess(query.data);
    }
  }, [query.isSuccess, query.dataUpdatedAt]);

  useEffect(() => {
    if (query.isError && options.onError) {
      options.onError(query.error);
    }
  }, [query.isError, query.errorUpdatedAt]);
}
