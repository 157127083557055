import { useEffect, useRef } from 'react';

import useAnalytics from 'contexts/analytics/useAnalytics';

import useModal from 'hooks/use-modal';

import { ModalConfig } from 'types/modal';

export default function useTrackModalState(): void {
  const analytics = useAnalytics();
  const modal = useModal();
  const lastModalRef = useRef<ModalConfig | null>(null);

  useEffect(
    function trackModalState() {
      if (modal.activeModal) {
        analytics.track({
          name: 'opened_modal',
          modalName: modal.activeModal.type,
          modalConfig: modal.activeModal,
        });
      } else if (lastModalRef.current) {
        analytics.track({
          name: 'closed_modal',
          modalName: lastModalRef.current.type,
          modalConfig: lastModalRef.current,
        });
      }

      // Keep track of the last modal so we can track when it closes
      lastModalRef.current = modal.activeModal;
    },
    [modal.activeModal?.type]
  );
}
