import { usePrivy } from '@privy-io/react-auth';
import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';
import { useAccount, useAccountEffect, useBalance } from 'wagmi';

import useAnalytics from 'contexts/analytics/useAnalytics';

import useUserByPublicKey from 'hooks/queries/api/use-user-by-public-key';
import { useQueryEffects } from 'hooks/react-query';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import useModal from 'hooks/use-modal';
import report from 'lib/report';
import { connectedUserCache } from 'utils/cache';
import { ENV } from 'utils/env';
import { isSupportedChainId } from 'utils/network';

import { AuthToken } from 'types/auth';

import AuthContext from './AuthContext';
import { Auth } from './types';

type AuthProviderProps = {
  children: React.ReactNode;
};

export default function AuthProvider(props: AuthProviderProps) {
  const addressRef = useRef<string | null>(null);
  const privy = usePrivy();

  const { setModal } = useModal();

  const account = useAccount();
  const analytics = useAnalytics();

  useAccountEffect({
    async onConnect({ address }) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: address,
        });
      });
    },
    onDisconnect() {
      Sentry.configureScope((scope) => {
        scope.setUser(null);
      });
    },
  });

  const chainId =
    account.chainId && isSupportedChainId(account.chainId)
      ? account.chainId
      : undefined;

  const balanceQuery = useBalance({
    address: account.address,
    chainId,
    query: {
      enabled: Boolean(chainId),
    },
  });

  const isHydrated = useIsHydrated();

  const connectedAddress = account?.address ? account.address : null;
  const hasWalletConnection = Boolean(connectedAddress);

  useEffect(() => {
    if (!connectedAddress) return;

    const lastKnownAddress = addressRef.current;
    const addressHasChanged = lastKnownAddress
      ? lastKnownAddress !== connectedAddress
      : false;

    if (!addressHasChanged) return;

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: connectedAddress,
      });
    });

    // Keep track of the last known connected wallet address
    // so that we can detect if the user has changed their wallet
    // for example, MetaMask supports quickly swapping between wallets
    addressRef.current = connectedAddress;
  }, [connectedAddress]);

  const authTokenQuery = useQuery({
    queryKey: connectedUserCache.authToken,
    queryFn: getAuthToken,
    enabled: hasWalletConnection,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const userQuery = useUserByPublicKey(
    { publicKey: connectedAddress as string },
    {
      enabled: hasWalletConnection && authTokenQuery.isFetched,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useQueryEffects(userQuery, {
    onError() {
      const token = authTokenQuery.data;

      /**
       * in the case where no user is found in the db we will want
       * the user to sign a message — it is signing a message that
       * creates a user in the db
       */
      if (!token) {
        setModal({ type: 'AUTH_PRE_SIGN' });
      }
    },
    onSuccess: (user) => {
      if (!connectedAddress) return;
      if (!user) return;

      Sentry.configureScope((scope) => {
        scope.setUser({
          id: connectedAddress,
          username: user.username || undefined,
        });
      });
    },
  });

  useEffect(() => {
    // Should never happen, but this log helps us verify
    if (privy.authenticated && !connectedAddress) {
      report('auth: authenticated without connected address');
    }

    // Should never happen, but this log helps us verify
    if (!privy.authenticated && connectedAddress) {
      report('auth: connected without being authenticated');
    }
  }, [connectedAddress, privy.authenticated]);

  const auth = useMemo<Auth>(() => {
    const startLogin = () => {
      privy.login();
      analytics.track({
        name: 'start_login',
      });
    };

    if (!isHydrated || !privy.ready) {
      return {
        state: 'unknown',
        startLogin: () => {
          report('auth: attempted to login from unprepared state');
          startLogin();
        },
      };
    }

    if (!privy.authenticated) {
      return {
        state: 'disconnected',
        startLogin: startLogin,
      };
    }

    /**
     * This shouldn't be possible!
     * TODO: check with Privy why it's happening
     */
    if (privy.authenticated && !connectedAddress) {
      return {
        state: 'disconnected',
        startLogin: () => {
          report('auth: called privy.connectWallet');
          privy.connectWallet();
        },
      };
    }

    // User is technically connected to the app.
    // We might be silently fetching their user data from the DB in the background
    if (connectedAddress) {
      const isSupportedChain = account.chainId
        ? isSupportedChainId(account.chainId)
        : false;

      const chainId =
        account.chain && isSupportedChainId(account.chain.id)
          ? account.chain.id
          : ENV.PRIMARY_CHAIN_ID;

      const connectionData = {
        balance: balanceQuery.data ? balanceQuery.data.value : null,
        chainId,
        isSupportedChain,
        connectorId: account.connector ? account.connector.id : 'unknown',
        publicKey: connectedAddress,
      };

      const authorizationData = {
        token: authTokenQuery.isSuccess ? authTokenQuery.data : null,
      };

      if (userQuery.status === 'error') {
        return {
          ...connectionData,
          ...authorizationData,
          state: 'connected',
        };
      }

      if (userQuery.status === 'pending') {
        return {
          ...connectionData,
          ...authorizationData,
          state: 'enriching',
        };
      }

      if (userQuery.status === 'success' && userQuery.data) {
        return {
          ...connectionData,
          ...authorizationData,
          state: 'enriched',
          user: userQuery.data,
        };
      }
    }

    // This should never happen. If it does, there's a flaw in the logic above.
    return {
      state: 'unknown',
      startLogin: () => {
        report('auth: attempted to login from unknown state');
        startLogin();
      },
    };
  }, [
    balanceQuery.dataUpdatedAt,
    connectedAddress,
    authTokenQuery.status,
    authTokenQuery.data,
    account.chainId,
    userQuery.dataUpdatedAt,
    userQuery.status,
    isHydrated,
    privy.authenticated,
    privy.ready,
    Boolean(privy.user),
  ]);

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setTag('auth-state', auth.state);
    });
  }, [auth.state]);

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}

async function getAuthToken(): Promise<AuthToken | null> {
  const res = await fetch('/api/token');
  const data = await res.json();

  if (res.ok) {
    return data.token;
  } else {
    return null;
  }
}
