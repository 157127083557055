import { base, baseSepolia, mainnet, sepolia } from 'viem/chains';
import { z } from 'zod';

import { extractAlchemyApiKeyFromHttpsUrl } from 'utils/alchemy';

import {
  MAINNET_CHAIN_CATEGORY,
  TESTNET_CHAIN_CATEGORY,
} from './chain-category';

export const alchemyUrlSchema = z
  .string({ required_error: 'Alchemy URL must be provided' })
  .url({ message: 'Alchemy URL must be a valid URL' })
  .transform((url, context) => {
    const apiKey = extractAlchemyApiKeyFromHttpsUrl(url);

    if (apiKey) {
      return {
        apiKey,
        httpsUrl: url,
        wssUrl: alchemyWebSocketUrlSchema.parse(url),
      };
    }

    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Alchemy URL must end with an API key',
    });

    return z.NEVER;
  });

export const alchemyMainnetEnvSchema = z
  .object({
    [base.id]: alchemyUrlSchema,
    [mainnet.id]: alchemyUrlSchema,
  })
  .transform((chains) => {
    return {
      category: MAINNET_CHAIN_CATEGORY,
      chains,
      primaryChain: chains[mainnet.id],
    };
  });

export const alchemyTestnetEnvSchema = z
  .object({
    [baseSepolia.id]: alchemyUrlSchema,
    [sepolia.id]: alchemyUrlSchema,
  })
  .transform((chains) => {
    return {
      category: TESTNET_CHAIN_CATEGORY,
      chains,
      primaryChain: chains[sepolia.id],
    };
  });

/**
 * parse and transform the alchemy https protocol to wss
 * as they otherwise both have the same URL structure
 */
export const alchemyWebSocketUrlSchema = z
  .string()
  .url()
  .transform((url) => {
    const parsedUrl = new URL(url);
    parsedUrl.protocol = 'wss:';
    return parsedUrl.href;
  });
