import { useEffect } from 'react';
import { create } from 'zustand';

import { ChainId } from 'lib/chains';
import { isBooleanType, isStringType } from 'utils/helpers';

import {
  TrackedTx,
  TrackedTxUi,
  TransactionsStore,
  TxHash,
  TxMonitoringConfig,
  TxStatus,
  TxStatusConfig,
  TxStatusCopyOptions,
} from 'types/Transactions';
import {
  Web3ActionName,
  Web3ActionConfig,
  Web3ActionConfigByActionName,
} from 'types/Web3';

type State = TransactionsStore;

export type StartTrackingPayload<
  // Name of every web3 action that can be performed
  ActionName = Web3ActionName,
  // Extracts the configuration for the given web3 action
  ActionConfig = Web3ActionConfigByActionName<ActionName>,
  // Extracts the the attributes for the ActionConfig (all fields except `name`)
  ActionAttributes = Omit<ActionConfig, 'name'>,
> = {
  chainId: ChainId;
  txHash: TxHash;

  // copy customization
  description?: Partial<TxStatusCopyOptions>;
  title?: Partial<TxStatusCopyOptions>;

  // interface customization
  ui: TrackedTxUi;

  /** When set to true, the transaction tracker will wait for this event to be indexed in our events table */
  needsIndexing?: TxMonitoringConfig['needsIndexing'];

  /**
   * If the action supports custom attributes, force consumers to provide the entire config (an object)
   * If the action does not support custom attribtues, allow consumers to pass the ActionName as a string
   */
  action: [keyof ActionAttributes] extends [never] ? ActionName : ActionConfig;
};

export type TrackingCopyMap = Pick<
  StartTrackingPayload,
  'description' | 'title'
>;

type SetStatusPayload = Exclude<TxStatusConfig, 'PENDING'> & {
  txHash: TxHash;
};

type Actions = {
  setStatus: (payload: SetStatusPayload) => void;
  startTracking: <Action extends Web3ActionName>(
    payload: StartTrackingPayload<Action>
  ) => void;
  stopTracking: (txHash: TxHash) => void;
  reset: () => void;
};

type TransactionStore = State & Actions;

const initialState: State = {
  transactions: new Map<TxHash, TrackedTx>(),
};

const canTransitionStatus = (
  currentStatus: TxStatus,
  nextStatus: TxStatus
): boolean => {
  if (currentStatus === 'INDEXING' && nextStatus === 'SUCCESS') {
    return true;
  } else if (currentStatus === 'PENDING') {
    return true;
  }

  return false;
};

const useTransactionStore = create<TransactionStore>((set, getState) => ({
  ...initialState,
  reset: () => {
    set((state) => {
      state.transactions.clear();
      return initialState;
    });
  },
  setStatus: (payload) => {
    const { transactions } = getState();
    const tx = transactions.get(payload.txHash);
    if (!tx) return;
    if (!canTransitionStatus(tx.status, payload.status)) return;

    set((state) => {
      const nextState = { ...state };
      nextState.transactions.set(payload.txHash, {
        ...tx,
        ...payload,
      });
      return nextState;
    });
  },
  startTracking: (payload) => {
    const { transactions } = getState();
    const tx = transactions.has(payload.txHash);
    if (tx) return;

    set((state) => {
      const nextState = { ...state };
      const action: Web3ActionConfig = isStringType(payload.action)
        ? // Typecast is relatively safe here because we're only supporting passing 'action' as a string for actions that don't support custom attributes
          ({ name: payload.action } as Web3ActionConfig)
        : payload.action;

      const trackedTx: TrackedTx = {
        action,
        chainId: payload.chainId,
        description: payload.description || {},
        ui: payload.ui,
        status: 'PENDING',
        needsIndexing: isBooleanType(payload.needsIndexing)
          ? payload.needsIndexing
          : null,
        title: payload.title || {},
        txHash: payload.txHash,
      };

      nextState.transactions.set(payload.txHash, trackedTx);

      return {
        ...nextState,
      };
    });
  },
  stopTracking: (txHash: TxHash) => {
    set((state) => {
      const nextState = { ...state };
      nextState.transactions.delete(txHash);
      return nextState;
    });
  },
}));

type TransactionEffects = {
  onSuccess?: (tx: TrackedTx) => void;
};

export const useTransactionEffects = (
  tx: TrackedTx | null,
  options: TransactionEffects
) => {
  useEffect(() => {
    if (!tx) return;

    if (tx.status === 'SUCCESS' && options.onSuccess) {
      options.onSuccess(tx);
    }
  }, [tx?.status]);
};

export default useTransactionStore;
