import { onGrid } from '@f8n/tokens';
import { styled, globalCss, transitions } from '@f8n-frontend/stitches';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import NextLink from 'next/link';
import { omit } from 'ramda';
import * as React from 'react';

import Menu from 'components/Menu';

import { LinkHref } from 'types/router';

import Divider from './Divider';

const Overlay = styled('div', {
  bottom: 0,
  left: 0,

  width: '100vw',
  height: '100vh',
  position: 'fixed',

  pointerEvents: 'none',
  background: '$black60',
  backdropFilter: 'blur(10px)',

  '@bp1': {
    display: 'none',
  },
});

const ContentOuter = styled(RadixDropdownMenu.Content, {
  transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
  [`${Menu.Root}`]: {
    transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    // When the Content appears above the trigger
    '&[data-side=top][data-state=open]': {
      [`${Menu.Root}`]: {
        animation: `${transitions.fadeInUp} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeIn} $transitions$1 $transitions$ease`,
      },
    },
    '&[data-side=top][data-state=closed]': {
      // We need this fake animation here for Radix to know there's some animation on the parent container
      animation: `${transitions.fakeAnimaton} $transitions$1 $transitions$ease`,
      [`${Menu.Root}`]: {
        // Otherwise the child will be unmounted before the animation is done
        animation: `${transitions.fadeOutUp} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeOut} $transitions$1 $transitions$ease`,
      },
    },

    // When the Content appears below the trigger
    '&[data-side=bottom][data-state=open]': {
      [`${Menu.Root}`]: {
        animation: `${transitions.fadeInDown} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeIn} $transitions$1 $transitions$ease`,
      },
    },
    '&[data-side=bottom][data-state=closed]': {
      // We need this fake animation here for Radix to know there's some animation on the parent container
      animation: `${transitions.fakeAnimaton} $transitions$1 $transitions$ease`,
      [`${Menu.Root}`]: {
        // Otherwise the child will be unmounted before the animation is done
        animation: `${transitions.fadeOutDown} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeOut} $transitions$1 $transitions$ease`,
      },
    },
  },
});

type ContentProps = React.ComponentProps<typeof RadixDropdownMenu.Content> & {
  minWidth?: number | string;
  maxHeight?: number;
};

const Content = React.forwardRef(function Content(
  props: ContentProps,
  /* We need to forward the ref here because Radix won't add an exit transition */
  ref: React.Ref<HTMLDivElement> | undefined
) {
  const { children, minWidth, maxHeight, ...rest } = props;
  return (
    <ContentOuter {...rest} ref={ref}>
      <Overlay />
      <Menu.Root css={{ minWidth, maxHeight }}>{children}</Menu.Root>
    </ContentOuter>
  );
});

Content.defaultProps = {
  align: 'start',
  collisionPadding: onGrid(2),
  sideOffset: onGrid(2),
};

const BaseItemLink = styled('a', Menu.Item);
const BaseItem = styled(RadixDropdownMenu.Item, Menu.Item);
type BaseItemProps = React.ComponentProps<typeof BaseItem>;

type MinimalDropdownItemProps = {
  children: React.ReactNode;
  /** @warn not used by inside DropdownMenu.Item */
  icon?: React.ReactNode;
  enabled?: boolean;
  variant?: BaseItemProps['variant'];
  css?: BaseItemProps['css'];
};

type ClickEvent = React.MouseEvent<HTMLDivElement>;
type ClickEventFn = (ev: ClickEvent) => void;

export type DropdownItemTypeProps =
  | { type?: 'button'; onClick: ClickEventFn }
  | { type: 'faux' }
  | { type: 'link'; href: LinkHref; onClick?: ClickEventFn }
  | { type: 'external-link'; href: string; onClick?: ClickEventFn };

export type DropdownItemProps = MinimalDropdownItemProps &
  DropdownItemTypeProps;

function Item(props: DropdownItemProps) {
  switch (props.type) {
    case 'link': {
      const { children, href, ...rest } = props;
      const baseProps = omit(['type'], rest);
      return (
        <NextLink href={href} passHref>
          <RadixDropdownMenu.Item {...baseProps} asChild>
            <BaseItemLink>{children}</BaseItemLink>
          </RadixDropdownMenu.Item>
        </NextLink>
      );
    }

    case 'external-link': {
      const { children, href, ...rest } = props;
      const baseProps = omit(['type'], rest);
      return (
        <RadixDropdownMenu.Item {...baseProps} asChild>
          <BaseItemLink href={href} target="_blank" rel="noreferrer">
            {children}
          </BaseItemLink>
        </RadixDropdownMenu.Item>
      );
    }

    default: {
      return <BaseItem {...props} />;
    }
  }
}

/* Global styles needed to turn the dropdown menu into a sheet on mobile */
const globalStyles = globalCss({
  ['div[data-radix-popper-content-wrapper]']: {
    zIndex: '1001 !important',
  },
  '@bp1-max': {
    ['div[data-radix-popper-content-wrapper]']: {
      transform: 'none !important',
      top: 'auto !important',
      bottom: '0 !important',
      left: '0 !important',
      width: '100% !important',

      [`${ContentOuter}`]: {
        [`${Menu.Root}`]: {
          width: '100%',
          maxWidth: '100vw',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          transform: 'translate3d(0, 100%, 0)',

          paddingTop: '$6',
          paddingBottom: 'calc($6 + env(safe-area-inset-bottom))',
        },

        '@media (prefers-reduced-motion: no-preference)': {
          '&[data-state=open]': {
            [`${Menu.Root}`]: {
              animation: `${transitions.longFadeInUp} $transitions$2 $transitions$ease forwards !important`,
              animationDelay: '125ms',
            },
          },
          '&[data-state=closed]': {
            [`${Menu.Root}`]: {
              animation: `${transitions.longFadeInDown} $transitions$2 $transitions$ease forwards !important`,
            },
          },
        },
      },
    },
  },
});

function DropdownMenuDivider() {
  return (
    <RadixDropdownMenu.Separator>
      <Divider
        css={{
          marginX: '$4',
          marginY: '$2',
          width: 'auto',
        }}
      />
    </RadixDropdownMenu.Separator>
  );
}

// Utils
const onTouchEnd = (_ev: React.TouchEvent<unknown>) => {
  if (typeof document !== 'undefined') {
    document.body.style.pointerEvents = '';
  }
};

// Default props
ContentOuter.defaultProps = {
  onTouchEnd: (ev) => ev.stopPropagation(),
};
Overlay.defaultProps = {
  onTouchEnd,
};

const DropdownMenu = {
  Content,
  globalStyles,
  Item,
  Divider: DropdownMenuDivider,
  Portal: RadixDropdownMenu.Portal,
  Root: RadixDropdownMenu.Root,
  Trigger: RadixDropdownMenu.Trigger,
};

export default DropdownMenu;
