import { CSSProperties } from '@stitches/react';
import { FieldMetaProps } from 'formik';

export const hasError = (
  meta: FieldMetaProps<unknown>,
  forceError: boolean
): boolean => {
  if (meta.error) {
    return meta.touched || forceError;
  } else {
    return false;
  }
};

// Hack: gradient must be used for multiple background layers are merged
// See https://css-tricks.com/tinted-images-multiple-backgrounds/
const createBackgroundImageColorLayer = (
  r: number,
  g: number,
  b: number,
  a: number
): string => {
  const color = `rgba(${r}, ${g}, ${b}, ${a})`;
  return `linear-gradient(${color}, ${color})`;
};

type CanvasBackgroundOptions = {
  layering?: 'darken' | 'lighten';
  imageUrl?: string;
  patternBackgroundSize?: string;
  hasTransparentBackground?: boolean;
};
export const createCanvasBackground = ({
  layering = 'darken',
  hasTransparentBackground = false,
  imageUrl,
  patternBackgroundSize = 'auto',
}: CanvasBackgroundOptions): CSSProperties => {
  const patternLayer = 'url(/images/patterns/canvas-grid.svg)';

  const [r, g, b] = layering === 'darken' ? [0, 0, 0] : [255, 255, 255];

  const tintLayer = createBackgroundImageColorLayer(r, g, b, 0.2);
  const imageLayer = `url(${imageUrl})`;
  const baseLayer = createBackgroundImageColorLayer(
    r,
    g,
    b,
    hasTransparentBackground ? 0 : 1
  );

  return {
    backgroundImage: imageUrl
      ? `${patternLayer}, ${tintLayer}, ${imageLayer}, ${baseLayer}`
      : `${patternLayer}, ${baseLayer}`,
    backgroundSize: imageUrl
      ? `${patternBackgroundSize}, auto, cover, auto`
      : `${patternBackgroundSize}, auto`,
    backgroundPosition: 'center',
  };
};

type GridAttribute = 'marginBottom' | 'gap' | 'padding';

export const getGridSpacingStyles = (attribute: GridAttribute) => {
  return {
    [attribute]: '$6',
  };
};

export function isTouchDevice() {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    'ontouchstart' in window.document.documentElement ||
    window.navigator.maxTouchPoints > 0
  );
}

/**
 * Used to create a link between a parent component and a child component
 * Generally the parent uses the selector, and the child uses the className.
 */
export function createCssTarget(className: string) {
  return {
    className,
    selector: `& .${className}` as const,
    onHover: `&:hover .${className}` as const,
  };
}

/**
 * Used to create a selector so that a parent component can style a child component.
 */
export function createClassSelector(className: string) {
  return `& .${className}` as const;
}
