import { toLower, toUpper, isNil } from 'ramda';

function maybeChangeCase(value: string | undefined, casing: 'LOWER' | 'UPPER') {
  if (isNil(value)) {
    return value;
  }

  return casing === 'LOWER' ? toLower(value) : toUpper(value);
}

export function maybeLowerCase(value: string | undefined) {
  return maybeChangeCase(value, 'LOWER');
}

export function maybeUpperCase(value: string | undefined) {
  return maybeChangeCase(value, 'UPPER');
}
