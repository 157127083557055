import { darkMode } from '@f8n-frontend/stitches';

export const countCss = {
  lineHeight: '$0',
  borderRadius: '$round',
  position: 'relative',
  transition: 'background-color $1 $ease, color $1 $ease',
};

export const primaryButtonCss = {
  backgroundColor: '$black100',
  boxShadow: 'none',
  color: '$white100',

  '@hover': {
    '&:hover': {
      boxShadow: '$regular1',
      backgroundColor: '$black100',
      transform: 'translate3d(0, -1px, 0)',
    },
  },
  '&:active': {
    backgroundColor: '$black90',
    boxShadow: 'none',
    transform: 'translate3d(0, 2px, 0)',
  },
  '&:focus-visible': {
    borderColor: '$white100',
    outline: '4px solid $black30',
  },
  '&:disabled': {
    backgroundColor: '$black5',
    boxShadow: 'none',
    color: '$black50',
    transform: 'none',

    '&:active': {
      backgroundColor: '$black5',
    },
  },
};
const createButtonWithOutlineCss = (options: {
  activeBackgroundColor: string;
  backgroundColor: string;
  darkModeBackgroundColor: string;
  darkModeActiveBackgroundColor: string;
}) => {
  return {
    backgroundColor: options.backgroundColor,
    borderColor: '$black10',
    color: '$black100',

    [darkMode]: {
      backgroundColor: options.darkModeBackgroundColor,
      borderColor: '$black30',
      '@hover': {
        '&:hover': {
          boxShadow: 'inset 0px 0px 0px 1px $colors$black100',
        },
      },
      '&:active, &[data-state=open]': {
        backgroundColor: options.darkModeActiveBackgroundColor,
      },
    },

    '@hover': {
      '&:hover, &[data-state=on]': {
        borderColor: '$black100',
        boxShadow: '$regular0, inset 0px 0px 0px 1px $colors$black100',
      },
    },
    '&:active, &[data-state=open]': {
      borderColor: '$black100',
      backgroundColor: options.activeBackgroundColor,
      transform: 'translate3d(0, 2px, 0)',
      boxShadow: '$regular0, inset 0px 0px 0px 1px $colors$black100',
    },

    '&:focus-visible': {
      borderColor: '$black100',
      outline: '4px solid $black30',
    },
    '&:disabled': {
      color: '$black40',
      '@hover': {
        '&:hover': {
          borderColor: '$black10',
          boxShadow: 'none',
        },
      },
      '&:active, &[data-state=open]': {
        backgroundColor: '$white100',
        transform: 'none',
      },
    },
  };
};

export const secondaryButtonCss = createButtonWithOutlineCss({
  activeBackgroundColor: '$black5-solid',
  backgroundColor: '$white100',
  darkModeBackgroundColor: '$black10',
  darkModeActiveBackgroundColor: '$black5',
});

export const outlineButtonCss = createButtonWithOutlineCss({
  activeBackgroundColor: '$black5',
  backgroundColor: 'transparent',
  darkModeBackgroundColor: 'transparent',
  darkModeActiveBackgroundColor: 'inherit',
});
