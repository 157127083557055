import { match } from 'ts-pattern';

import {
  BaseChainId,
  CHAINS,
  Chain,
  ChainConfig,
  ChainId,
  ChainSlug,
  MAINNET_CHAINS,
  TESTNET_CHAINS,
  getChainConfig,
  getPrimaryChainConfig,
} from 'lib/chains';

import { ENV, IS_MAINNET, IS_TESTNET } from './env';

const getChainCopyMap = (chain: Chain) => {
  return getChainConfig(chain).copy;
};

export const getNetworkName = (chain: Chain = ENV.PRIMARY_CHAIN) => {
  return getChainCopyMap(chain).chainName;
};

export const getVerboseNetworkName = (chain: Chain = ENV.PRIMARY_CHAIN) => {
  return getChainCopyMap(chain).verboseChainName;
};

export const isSupportedChainId = (
  chainId: number,
  options: {
    isMainnet?: boolean;
  } = {}
): chainId is ChainId => {
  const { isMainnet = IS_MAINNET } = options;
  const chainConfigs = isMainnet ? MAINNET_CHAINS : TESTNET_CHAINS;
  return Object.values(chainConfigs).some((chain) => chain.chainId === chainId);
};

export const isSupportedChainSlug = (
  chainSlug: string,
  options: {
    isMainnet?: boolean;
  } = {}
): chainSlug is ChainSlug => {
  const { isMainnet = IS_MAINNET } = options;
  const chainConfigs = isMainnet ? MAINNET_CHAINS : TESTNET_CHAINS;
  return Object.values(chainConfigs).some((chain) => chain.slug === chainSlug);
};

export const getChainConfigById = (chainId: ChainId) => {
  const chainConfig = Object.values(CHAINS).find(
    (chain) => chain.chainId === chainId
  );

  if (!chainConfig) {
    return getPrimaryChainConfig(ENV.CHAIN_CATEGORY);
  }

  return chainConfig;
};

export const getChainConfigBySlug = (chainSlug: ChainSlug) => {
  const chainConfig = Object.values(CHAINS).find(
    (chain) => chain.slug === chainSlug
  );

  if (!chainConfig) {
    return getPrimaryChainConfig(ENV.CHAIN_CATEGORY);
  }

  return chainConfig;
};

export const getChainIdBySlug = (chainSlug: ChainSlug) => {
  return getChainConfigBySlug(chainSlug).chainId;
};

export const isBaseChainConfig = (
  config: ChainConfig
): config is Extract<ChainConfig, { chain: 'base' | 'baseSepolia' }> => {
  const baseChainId = IS_TESTNET
    ? TESTNET_CHAINS.baseSepolia.chainId
    : MAINNET_CHAINS.base.chainId;

  return config.chainId === baseChainId;
};

export const isL2ChainId = (chainId: ChainId) => {
  return match(chainId)
    .when(isBaseChainId, () => true)
    .otherwise(() => false);
};

export const isBaseChainId = (chainId: ChainId): chainId is BaseChainId => {
  return match(chainId)
    .with(CHAINS.base.chainId, CHAINS.baseSepolia.chainId, () => true)
    .otherwise(() => false);
};
