import { QueryClient } from '@tanstack/react-query';

import { UnsafeAny } from 'types/utils';

import { logger } from './logger';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
    mutations: {
      onError: (error, variables, context) => {
        const errorContext = {
          context,
          variables,
        };

        if (error instanceof Error) {
          logger.error(`global-mutation-error: ${error.message}`, errorContext);
        } else {
          logger.error(`global-mutation: ${JSON.stringify(error)}`, {
            ...(typeof error === 'object' && error
              ? { ...(error as UnsafeAny) }
              : {}),
            ...errorContext,
          });
        }
      },
    },
  },
});
