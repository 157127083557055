import { ENV, IS_TESTNET } from './env';

export const isSentryEnabled = () => {
  return ENV.SENTRY_DSN !== undefined;
};

export const getSentryEnvironment = (options: {
  isTestnet: boolean;
  stage: typeof ENV.VERCEL_ENV;
}) => {
  const { isTestnet, stage } = options;

  // Production environments
  if (stage === 'production') {
    // 'goerli' is used to preserve backwards compatibility on Sentry
    return isTestnet ? 'goerli' : 'production';
  }

  // Preview environments
  if (stage === 'preview') {
    // 'goerli' is used to preserve backwards compatibility on Sentry
    return isTestnet ? 'goerli-preview' : 'production-preview';
  }

  // Fall back to development
  return 'development';
};

export const SENTRY_ENVIRONMENT = getSentryEnvironment({
  stage: ENV.VERCEL_ENV,
  isTestnet: IS_TESTNET,
});
