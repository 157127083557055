import { z } from 'zod';

const VERCEL_EDGE_CONFIG_BASE_URL = 'https://edge-config.vercel.com';
type VercelEdgeConfigUrl = `${typeof VERCEL_EDGE_CONFIG_BASE_URL}/${string}`;

const edgeConfigUrlSchema = z
  .string({ required_error: 'Edge config URL must be a string' })
  .url({ message: 'Edge config URL must be a valid URL' })
  .startsWith(VERCEL_EDGE_CONFIG_BASE_URL, {
    message: 'Edge config URL must be a valid vercel subdomain',
  })
  .transform((val) => val as VercelEdgeConfigUrl);

export const edgeConfigEnvSchema = z.object(
  {
    allowlists: edgeConfigUrlSchema,
  },
  {
    required_error: 'Edge config env vars must be provided',
    invalid_type_error: 'Edge config env vars must be provided as an object',
  }
);
