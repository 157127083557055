import {
  compose,
  nth,
  unnest,
  repeat,
  all,
  equals,
  complement,
  isNil,
  when,
  allPass,
  head,
} from 'ramda';

import { getAddress, isAddress } from 'utils/address';
import { maybeLowerCase } from 'utils/case';
import { getUsernameOrAddressInfo, isString } from 'utils/helpers';

import { UserLight } from 'types/Account';

const getLastDigitFromString = (string: string): string => {
  const lastDigitMatch = string.match(/\d(?!.*\d)/);
  if (lastDigitMatch) {
    return lastDigitMatch[0] || '0';
  } else {
    return '0';
  }
};

export type AvatarGradient = [string, string];

const gradients: AvatarGradient[] = [
  ['#54BCFB', '#4342F3'],
  ['#523FEF', '#FD22AD'],
  ['#FD22AD', '#ED5655'],
  ['#ED5356', '#F9D649'],
  ['#FFF61F', '#19FF3E'],
];

const getRepeatedGradients = compose<
  AvatarGradient[],
  AvatarGradient[][],
  AvatarGradient[]
>(unnest, (arr) => repeat(arr, 2));

export const repeatedGradients = getRepeatedGradients(gradients);

export const buildGradient = (from: string, to: string): string =>
  `linear-gradient(135deg, ${from}, ${to})`;

export const getAvatarByPublicKey = (publicKey: string) => {
  const gradients = repeatedGradients;

  const index = isNil(publicKey) ? '0' : getLastDigitFromString(publicKey);

  const boundIndex = Number(index) % gradients.length;

  const [gradientFrom, gradientTo] = nth(
    boundIndex,
    gradients
  ) as AvatarGradient;
  return buildGradient(gradientFrom, gradientTo);
};

export const getProfilePageTitle = (user: UserLight): string => {
  const { usernameOrAddress, nameOrUsername, hasName } =
    getUsernameOrAddressInfo(user);

  if (hasName) {
    return `${nameOrUsername} (${usernameOrAddress})`;
  } else {
    return usernameOrAddress;
  }
};

// invert of isNil
const notNil = complement(isNil);

// returns true if all in array are not nil
const allNotNil = all(notNil);

// returns true if all not nil and all not equal
const allEqual = (items: unknown[]): boolean => {
  return all(equals(head(items)), items);
};

const normalizeAddress = (address: string) => maybeLowerCase(address);

export const areKeysEqual = (keys: Array<string | undefined | null>) => {
  const normalizedKeys = keys.map(normalizeAddress);
  const areAllDefinedAndEqual = allPass([allNotNil, allEqual]);
  return areAllDefinedAndEqual(normalizedKeys);
};

export const isOnAllowList = (allowlist: string[], address: string) => {
  // Normalize both inputs, to avoid casing differences
  const normalizedAllowList = allowlist.map(normalizeAddress);
  const normalizedAddress = normalizeAddress(address);

  return normalizedAllowList.includes(normalizedAddress);
};

export const maybeGetAddress: (arg0: string) => string = when<string, string>(
  allPass([isString, isAddress]),
  getAddress
);
