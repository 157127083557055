import { PrivyProvider } from '@privy-io/react-auth';
import { WagmiProvider as PrivyWagmiProvider } from '@privy-io/wagmi';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';

import useTargetChain from 'state/stores/target-chain';

import { useRouterTargetChain } from 'hooks/use-router-target-chain';
import { getPrivySupportedChains } from 'lib/privy';
import { queryClient } from 'lib/reactQueryClient';
import { mapChainIdToWagmiChain, wagmiConfig } from 'lib/wagmi';
import { ENV } from 'utils/env';

type ProvidersProps = {
  children: ReactNode;
};

export function Web3AuthServiceProviders(props: ProvidersProps) {
  const initialChainId = useTargetChain(({ target }) => target.chainId);
  useRouterTargetChain();

  return (
    <PrivyProvider
      appId={ENV.PRIVY_APP_ID}
      config={{
        /**
         * Enabled login methods are managed via the Privy dashboard.
         * @see https://dashboard.privy.io/apps/clvnj3ocl042hhk1iz2a83fmr
         */

        appearance: {
          landingHeader: 'Connect wallet',
          theme: 'light',
        },
        embeddedWallets: {
          createOnLogin: 'off',
          requireUserPasswordOnCreate: false,
        },
        defaultChain: mapChainIdToWagmiChain(initialChainId),
        legal: {
          termsAndConditionsUrl: '/terms',
          privacyPolicyUrl: '/privacy',
        },
        supportedChains: getPrivySupportedChains(),
        walletConnectCloudProjectId: ENV.WALLET_CONNECT_PROJECT_ID,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <PrivyWagmiProvider config={wagmiConfig}>
          {props.children}
        </PrivyWagmiProvider>
      </QueryClientProvider>
    </PrivyProvider>
  );
}
