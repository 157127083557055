import { onGrid } from '@f8n/tokens';
import { TippyProps } from '@tippyjs/react';

export const POPOVER_CONTAINER_ID = 'popover-container';

export const DEFAULT_TIPPY_PROPS: TippyProps = {
  delay: [400, 0],
  offset: [0, onGrid(3)],
  duration: [300, 300],
  placement: 'bottom',
};

export const DEFAULT_SINGLETON_TIPPY_PROPS: TippyProps = {
  ...DEFAULT_TIPPY_PROPS,
  delay: [400, 200],
  hideOnClick: false,
  moveTransition: 'transform 0.3s ease-out',
};
