import { match } from 'ts-pattern';

import { CHAINS, ChainId, getPrimaryChainConfig } from 'lib/chains';

import {
  GetBlockExplorerOptions,
  BlockExplorerConfig,
  BlockExplorers,
} from 'types/block-explorer';

import {
  getBlockscoutAddressUrl,
  getBlockscoutNftUrl,
  getBlockscoutTxUrl,
} from './blockscout';
import { ENV } from './env';
import {
  getEtherscanAddressUrl,
  getEtherscanNftUrl,
  getEtherscanTxUrl,
} from './etherscan';
import { getChainConfigById } from './network';

const ETHERSCAN_APP_NAME = 'Etherscan';
const BASESCAN_APP_NAME = 'BaseScan';
const BLOCKSCOUT_APP_NAME = 'Blockscout';

export const getBlockExplorerByChainId = (
  chainId: ChainId | null | undefined
): BlockExplorers => {
  const chainConfig = chainId
    ? getChainConfigById(chainId)
    : getPrimaryChainConfig(ENV.CHAIN_CATEGORY);

  const explorers = chainConfig.blockExplorers;

  return {
    address: getAddressExplorer({
      explorer: explorers.address,
      chainId: chainConfig.chainId,
    }),
    nft: getNftExplorer({
      explorer: explorers.nft,
      chainId: chainConfig.chainId,
    }),
    tx: getTxExplorer({
      explorer: explorers.tx,
      chainId: chainConfig.chainId,
    }),
  };
};

const getAddressExplorer = (
  options: GetBlockExplorerOptions
): BlockExplorers['address'] => {
  const { chainId } = options;

  return {
    copy: getBlockExplorerCopy(options),
    name: options.explorer,
    getUrl: (urlOptions) => {
      const getBlockExplorerAddressUrl = match(options.explorer)
        .with('blockscout', () => getBlockscoutAddressUrl)
        .with('etherscan', () => getEtherscanAddressUrl)
        .exhaustive();

      return getBlockExplorerAddressUrl(urlOptions, { chainId });
    },
  };
};

const getNftExplorer = (
  options: GetBlockExplorerOptions
): BlockExplorers['nft'] => {
  const { chainId } = options;

  return {
    copy: getBlockExplorerCopy(options),
    name: options.explorer,
    getUrl: (urlOptions) => {
      const getBlockExplorerNftUrl = match(options.explorer)
        .with('blockscout', () => getBlockscoutNftUrl)
        .with('etherscan', () => getEtherscanNftUrl)
        .exhaustive();

      return getBlockExplorerNftUrl(urlOptions, { chainId });
    },
  };
};

const getTxExplorer = (
  options: GetBlockExplorerOptions
): BlockExplorers['tx'] => {
  const { chainId } = options;

  return {
    copy: getBlockExplorerCopy(options),
    name: options.explorer,
    getUrl: (urlOptions) => {
      const getBlockExplorerTxUrl = match(options.explorer)
        .with('blockscout', () => getBlockscoutTxUrl)
        .with('etherscan', () => getEtherscanTxUrl)
        .exhaustive();

      return getBlockExplorerTxUrl(urlOptions, { chainId });
    },
  };
};

const getBlockExplorerCopy = (
  options: GetBlockExplorerOptions
): BlockExplorerConfig['copy'] => {
  const explorerBrandName = match(options)
    .with(
      { explorer: 'etherscan', chainId: CHAINS.base.chainId },
      () => BASESCAN_APP_NAME
    )
    .with(
      {
        explorer: 'etherscan',
        chainId: CHAINS.baseSepolia.chainId,
      },
      () => BASESCAN_APP_NAME
    )
    .with({ explorer: 'blockscout' }, () => BLOCKSCOUT_APP_NAME)
    .with({ explorer: 'etherscan' }, () => ETHERSCAN_APP_NAME)
    .exhaustive();

  return {
    name: explorerBrandName,
    viewOnCta: `View on ${explorerBrandName}`,
  } as const;
};
