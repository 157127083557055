import { create } from 'zustand';

import { ChainConfig, ChainId } from 'lib/chains';
import { ENV } from 'utils/env';
import { getChainConfigById } from 'utils/network';

type TargetChainStore = {
  target: ChainConfig;
  setTargetChain: (chain: ChainConfig) => void;
  setTargetChainById: (chainId: ChainId) => void;
};

const useTargetChain = create<TargetChainStore>((set) => ({
  target: getChainConfigById(ENV.PRIMARY_CHAIN_ID),
  setTargetChain: (chain) =>
    set(() => {
      return {
        target: chain,
      };
    }),
  setTargetChainById: (chainId) =>
    set(() => {
      return {
        target: getChainConfigById(chainId),
      };
    }),
}));

export default useTargetChain;
