import { onGrid } from '@f8n/tokens';
import { styled, globalCss } from '@f8n-frontend/stitches';
import { transitions } from '@f8n-frontend/stitches';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

/* Popover.Content */
const Overlay = styled('div', {
  bottom: 0,
  left: 0,

  width: '100vw',
  height: '100vh',
  position: 'fixed',

  pointerEvents: 'none',
  background: '$black60',
  backdropFilter: 'blur(10px)',

  '@bp1': {
    display: 'none',
  },
});

const ContentInner = styled('div', {
  // TODO: expose maxWidth and add padding size scale
  maxWidth: '80vw',
  overflow: 'auto',
  borderRadius: '$4',
  position: 'relative',
  boxShadow: '$regular2',
  background: '$white100',

  // TODO: expose maxHeight
  maxHeight: onGrid(97),
});

const ContentOuter = styled(PopoverPrimitive.Content, {
  transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
  [`${ContentInner}`]: {
    transformOrigin: 'var(--radix-dropdown-menu-content-transform-origin)',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    // When the Content appears above the trigger
    '&[data-side=top][data-state=open]': {
      [`${ContentInner}`]: {
        animation: `${transitions.fadeInUp} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeIn} $transitions$1 $transitions$ease`,
      },
    },
    '&[data-side=top][data-state=closed]': {
      // We need this fake animation here for Radix to know there's some animation on the parent container
      animation: `${transitions.fakeAnimaton} $transitions$1 $transitions$ease`,
      [`${ContentInner}`]: {
        // Otherwise the child will be unmounted before the animation is done
        animation: `${transitions.fadeOutUp} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeOut} $transitions$1 $transitions$ease`,
      },
    },

    // When the Content appears below the trigger
    '&[data-side=bottom][data-state=open]': {
      [`${ContentInner}`]: {
        animation: `${transitions.fadeInDown} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeIn} $transitions$1 $transitions$ease`,
      },
    },
    '&[data-side=bottom][data-state=closed]': {
      // We need this fake animation here for Radix to know there's some animation on the parent container
      animation: `${transitions.fakeAnimaton} $transitions$1 $transitions$ease`,
      [`${ContentInner}`]: {
        // Otherwise the child will be unmounted before the animation is done
        animation: `${transitions.fadeOutDown} $transitions$1 $transitions$ease`,
      },
      [`${Overlay}`]: {
        animation: `${transitions.fadeOut} $transitions$1 $transitions$ease`,
      },
    },
  },
});

type ContentProps = React.ComponentProps<typeof PopoverPrimitive.Content> & {
  minWidth?: number;
};

const Content = React.forwardRef(function Content(
  props: ContentProps,
  /* We need to forward the ref here because Radix won't add an exit transition */
  ref: React.Ref<HTMLDivElement> | undefined
) {
  const { children, minWidth, ...rest } = props;
  return (
    <ContentOuter {...rest} ref={ref}>
      <Overlay />
      <ContentInner css={{ minWidth }}>{children}</ContentInner>
    </ContentOuter>
  );
});

Content.defaultProps = {
  align: 'center',
  collisionPadding: onGrid(2),
  sideOffset: onGrid(2),
};

/* Global styles needed to turn the dropdown menu into a sheet on mobile */
const globalStyles = globalCss({
  ['div[data-radix-popper-content-wrapper]']: {
    zIndex: '1001 !important',
  },
  '@bp1-max': {
    ['div[data-radix-popper-content-wrapper]']: {
      transform: 'none !important',
      top: 'auto !important',
      bottom: '0 !important',
      left: '0 !important',
      width: '100% !important',

      [`${ContentOuter}`]: {
        [`${ContentInner}`]: {
          width: '100%',
          maxWidth: '100vw',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          transform: 'translate3d(0, 100%, 0)',

          paddingTop: '$6',
          paddingBottom: 'calc($6 + env(safe-area-inset-bottom))',
        },

        '@media (prefers-reduced-motion: no-preference)': {
          '&[data-state=open]': {
            [`${ContentInner}`]: {
              animation: `${transitions.longFadeInUp} $transitions$2 $transitions$ease forwards !important`,
              animationDelay: '125ms',
            },
          },
          '&[data-state=closed]': {
            [`${ContentInner}`]: {
              animation: `${transitions.longFadeInDown} $transitions$2 $transitions$ease forwards !important`,
            },
          },
        },
      },
    },
  },
});

// Utils
const onTouchEnd = (_ev: React.TouchEvent<unknown>) => {
  if (typeof document !== 'undefined') {
    document.body.style.pointerEvents = '';
  }
};

// Default props
ContentOuter.defaultProps = {
  onTouchEnd: (ev) => ev.stopPropagation(),
};
Overlay.defaultProps = {
  onTouchEnd,
};

const Popover = {
  Anchor: PopoverPrimitive.Anchor,
  Close: PopoverPrimitive.Close,
  Content,
  globalStyles,
  Portal: PopoverPrimitive.Portal,
  Root: PopoverPrimitive.Root,
  Trigger: PopoverPrimitive.Trigger,
};

export default Popover;
