import { last } from 'ramda';

import { TrackedTx, TransactionsStore, TxHash } from 'types/Transactions';
import { Web3ActionConfigByActionName, Web3ActionName } from 'types/Web3';

import { getLastValue } from './helpers';

const getTransactions = (state: TransactionsStore): TrackedTx[] => {
  return [...state.transactions].map(([_txHash, transaction]) => transaction);
};

export const selectVisibleToasts = (state: TransactionsStore): TrackedTx[] => {
  return getTransactions(state).filter(
    (transaction) => transaction.ui === 'toast'
  );
};

/** Extracts a TrackedTx, with the action narrowed down to a single Web3ActionConfig */
type ExtractTransactionByAction<ActionName = Web3ActionName> = TrackedTx & {
  /** Add action back, but narrowed down based on the ActionName */
  action: Web3ActionConfigByActionName<ActionName>;
};

const selectAllTransactionsByAction = <
  ActionName extends Web3ActionName,
>(options: {
  store: TransactionsStore;
  action: ActionName;
}): Array<ExtractTransactionByAction<ActionName>> => {
  return getTransactions(options.store).filter(
    (transaction): transaction is ExtractTransactionByAction<ActionName> => {
      return transaction.action.name === options.action;
    }
  );
};

export const selectTransactionByAction = <
  ActionName extends Web3ActionName,
>(options: {
  action: ActionName;
  txHash?: TxHash | null;
}) => {
  return (
    store: TransactionsStore
  ): ExtractTransactionByAction<ActionName> | null => {
    const { action, txHash } = options;

    /** if txHash is passed in, extract exact transaction that matches */
    if (txHash) {
      const tx = store.transactions.get(txHash);

      // Double check that the txHash matches the expected action
      if (tx && tx.action.name === options.action) {
        return tx as ExtractTransactionByAction<ActionName>;
      } else {
        return null;
      }
    }

    /** if txHash is passed in, but is null, assume no transaction */
    if (txHash === null) {
      return null;
    }

    const transactionsByAction = selectAllTransactionsByAction({
      action,
      store,
    });
    return getLastValue(transactionsByAction) || null;
  };
};

export const selectLatestTx = (store: TransactionsStore) => {
  const transactions = getTransactions(store);

  const latestTx = last(transactions);

  if (!latestTx) return null;

  return latestTx;
};

export const isTxInProgress = (
  tx: Pick<TrackedTx, 'status'> | null
): tx is TrackedTx => {
  if (!tx) return false;
  return tx.status === 'PENDING' || tx.status === 'INDEXING';
};
