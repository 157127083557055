import type { Address } from 'viem';

import { CHAINS, ChainId } from 'lib/chains';
import { ENV } from 'utils/env';

import { BlockExplorerUrlBuilders } from 'types/block-explorer';
import { HttpsUrl } from 'types/url';

type EtherscanDomain = `${HttpsUrl}etherscan.io` | `${HttpsUrl}basescan.org`;

const ETHERSCAN_NETWORK_DOMAIN_MAP: Record<ChainId, EtherscanDomain> = {
  [CHAINS.mainnet.chainId]: 'https://etherscan.io',
  [CHAINS.base.chainId]: 'https://basescan.org',
  [CHAINS.baseSepolia.chainId]: 'https://sepolia.basescan.org',
  [CHAINS.sepolia.chainId]: 'https://sepolia.etherscan.io',
};

type EtherscanUrlBuilderOptions = {
  chainId?: ChainId;
};

function buildEtherscanUrl(
  path: `/${string}`,
  options: EtherscanUrlBuilderOptions = {}
): string {
  const { chainId = ENV.PRIMARY_CHAIN_ID } = options;
  const etherscanURL = new URL(path, ETHERSCAN_NETWORK_DOMAIN_MAP[chainId]);
  return etherscanURL.href;
}

export const getEtherscanAddressUrl: BlockExplorerUrlBuilders['address'] = (
  variables,
  options
) => {
  const { address } = variables;
  return buildEtherscanUrl(`/address/${address}`, options);
};

export const getEtherscanNftUrl: BlockExplorerUrlBuilders['nft'] = (
  variables,
  options
) => {
  const { contractAddress, tokenId } = variables;
  return buildEtherscanUrl(`/nft/${contractAddress}/${tokenId}`, options);
};

export const getEtherscanTxUrl: BlockExplorerUrlBuilders['tx'] = (
  variables,
  options
) => {
  const { txHash } = variables;
  return buildEtherscanUrl(`/tx/${txHash}`, options);
};

/** @deprecated
 * TODO: document replacement
 */
export const getEtherscanUrl = {
  address: (address: string, options: EtherscanUrlBuilderOptions = {}) => {
    return getEtherscanAddressUrl(
      { address },
      {
        chainId: options.chainId || ENV.PRIMARY_CHAIN_ID,
      }
    );
  },
  nft: (
    variables: { contractAddress: Address; tokenId: number },
    options: EtherscanUrlBuilderOptions = {}
  ) => {
    return getEtherscanNftUrl(variables, {
      chainId: options.chainId || ENV.PRIMARY_CHAIN_ID,
    });
  },
  tx: (txHash: string, options: EtherscanUrlBuilderOptions = {}) => {
    return getEtherscanTxUrl(
      { txHash },
      {
        chainId: options.chainId || ENV.PRIMARY_CHAIN_ID,
      }
    );
  },
};
