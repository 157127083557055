import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { useCookie } from 'react-use';

import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { isAnyTrue } from 'utils/helpers';

import Button from './base/Button';
import Link from './base/Link';
import Text from './base/Text';

type TosBannerState = 'none' | 'privacy' | 'terms' | 'privacy-and-terms';

const TOS_BANNER: TosBannerState = 'privacy';
const LAST_UPDATED_DATE_STRING = '05/30/2024'; // MM/DD/YYYY
const LAST_UPDATED_DATE = new Date(LAST_UPDATED_DATE_STRING);

const formatDate = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}).format;

export default function TOSBanner() {
  const auth = useAuth();
  const connected = hasPublicKey(auth);

  const [value, updateCookie] = useCookie('fnd-tos');

  const hideBanner = isAnyTrue([
    TOS_BANNER === 'none',
    connected && value === LAST_UPDATED_DATE_STRING,
    !connected,
  ]);

  if (hideBanner) return null;

  const handleClick = () => {
    updateCookie(LAST_UPDATED_DATE_STRING, { expires: 365 });
  };

  return (
    <Container>
      <Copy size={1}>
        As of {formatDate(LAST_UPDATED_DATE)}, we have updated our{' '}
        {(TOS_BANNER === 'terms' || TOS_BANNER === 'privacy-and-terms') && (
          <NextLink href="/terms" passHref>
            <Link
              variant="strong"
              isDark
              hasUnderline
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </Link>
          </NextLink>
        )}
        {TOS_BANNER === 'privacy-and-terms' && <> and </>}
        {(TOS_BANNER === 'privacy' || TOS_BANNER === 'privacy-and-terms') && (
          <NextLink href="/privacy" passHref>
            <Link
              variant="strong"
              isDark
              hasUnderline
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </NextLink>
        )}
        .
      </Copy>
      <Button size={0} variant="raised" onClick={handleClick}>
        Accept
      </Button>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'fixed',
  left: 10,
  bottom: 10,
  right: 10,
  zIndex: 999,
  backgroundColor: '$black60',
  backdropFilter: 'blur(10px)',
  borderRadius: '$4',
  padding: '$5',

  '@bp0': {
    flexDirection: 'row',
    alignItems: 'center',
    left: 'unset',
    bottom: 20,
    right: 20,

    paddingLeft: '$6',
    paddingRight: '$3',
    paddingY: '$3',
  },
});

const Copy = styled(Text, {
  color: '$white100',
  marginRight: '$4',
  marginBottom: '$4',
  lineHeight: '$2',
  '@bp0': {
    marginBottom: 0,
  },
});
