import { CHAINS, ChainId } from 'lib/chains';
import { ENV } from 'utils/env';

import { BlockExplorerUrlBuilders } from 'types/block-explorer';
import { HttpsUrl } from 'types/url';

type BlockscoutDomain = `${HttpsUrl}blockscout.com`;

/**
 * @see https://docs.blockscout.com/about/projects for documentation on Blockscout-supported chains
 */
const BLOCKSCOUT_CHAIN_BASE_URL_MAP: Record<ChainId, BlockscoutDomain> = {
  [CHAINS.mainnet.chainId]: 'https://eth.blockscout.com',
  [CHAINS.base.chainId]: 'https://base.blockscout.com',
  [CHAINS.baseSepolia.chainId]: 'https://base-sepolia.blockscout.com',
  [CHAINS.sepolia.chainId]: 'https://eth-sepolia.blockscout.com',
};

function buildBlockscoutUrl(
  path: `/${string}`,
  options: { chainId?: ChainId } = {}
): string {
  const { chainId = ENV.PRIMARY_CHAIN_ID } = options;
  const base = BLOCKSCOUT_CHAIN_BASE_URL_MAP[chainId];
  const url = new URL(path, base);
  return url.href;
}

export const getBlockscoutAddressUrl: BlockExplorerUrlBuilders['address'] = (
  variables,
  options
) => {
  const { address } = variables;
  return buildBlockscoutUrl(`/address/${address}`, options);
};

export const getBlockscoutNftUrl: BlockExplorerUrlBuilders['nft'] = (
  variables,
  options
) => {
  const { contractAddress, tokenId } = variables;
  return buildBlockscoutUrl(
    `/token/${contractAddress}/instance/${tokenId}`,
    options
  );
};

export const getBlockscoutTxUrl: BlockExplorerUrlBuilders['tx'] = (
  variables,
  options
) => {
  const { txHash } = variables;
  return buildBlockscoutUrl(`/tx/${txHash}`, options);
};
