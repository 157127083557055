import { UrlObject } from 'url';

import { Address } from 'viem';

import { ChainId } from 'lib/chains';
import { DropStatus, CreateDropSaleType } from 'schemas/url/drops';
import { isAddress } from 'utils/address';

import { CollectionFilter, CollectionPathInputs } from 'types/Collection';
import { NftFilter } from 'types/Nft';
import { TokenFilter } from 'types/Token';
import { NestedKeyPicker } from 'types/utils';

import { isMintableContract } from './contract-type';
import { formatUsernameIfNeeded } from './helpers';
import { IMPORT_FROM_HIGHLIGHT_PATH } from './import-from-highlight';
import { getChainConfigById } from './network';
import { ProfileDeepLinkModalParam } from './profile';
import { ROUTER_PARAMS } from './router-params';

export type Pathname = `/${string}`;

/**
 * Contains page paths in a literal string format.
 *
 * @example /page/example
 * @example /page/${some-dynamic-part}/${another-dynamic-part}
 */
const pagePaths = {
  browse: {
    collections: '/browse/collections',
    drops: '/browse/drops',
    nfts: '/browse/nfts',
    galleries: '/browse/galleries',
  },
  create: '/create',
  collection: (slugOrContractAddress: string) => {
    return `/collection/${slugOrContractAddress}` as const;
  },
  mint: (collection: CollectionFilter) => {
    const chainConfig = getChainConfigById(collection.chainId);
    return `/mint/${chainConfig.slug}/${collection.contractAddress}`;
  },
  /** @deprecated */
  nft: (options: {
    usernameOrAddress: string;
    collectionSlugOrAddress: string;
    tokenId: number;
  }): Pathname => {
    const {
      usernameOrAddress: profileParam,
      collectionSlugOrAddress,
      tokenId,
    } = options;

    const usernameOrAddress = isAddress(profileParam)
      ? profileParam
      : formatUsernameIfNeeded(profileParam);

    return `/${usernameOrAddress}/${collectionSlugOrAddress}/${tokenId}`;
  },
  token: (options: TokenFilter) => {
    const { chainId, contractAddress, tokenId } = options;
    const chainConfig = getChainConfigById(chainId);
    return `/mint/${chainConfig.slug}/${contractAddress}/${tokenId}` as const;
  },
  profile: (usernameOrAddress: string) => {
    return `/${usernameOrAddress}` as const;
  },
  world: (slug: string) => {
    return `/gallery/${slug}` as const;
  },
} as const;

type BasePath = string;
type CacheKey = number;
type EditionUrl = `${BasePath}/edition/${ChainId}/${Address}`;
type MomentUrl = `${BasePath}/exhibition/${number}/${CacheKey}`;
type NftUrl = `${BasePath}/nft/${ChainId}/${Address}`;

export const getPath = {
  api: {
    authorize: '/api/authorize',
    disconnect: '/api/disconnect',
    generateSignature: '/api/relayer/generate-signature',
    farcasterWebhook: {
      base: '/api/farcaster/webhook',
      edition: {
        mint: (options: CollectionFilter) => {
          return `${getPath.api.farcasterWebhook.base}/edition/mint/${options.chainId}/${options.contractAddress}`;
        },
        result: (options: CollectionFilter) => {
          return `${getPath.api.farcasterWebhook.base}/edition/result/${options.chainId}/${options.contractAddress}`;
        },
      },
      moment: (momentId: number) => {
        return `${getPath.api.farcasterWebhook.base}/exhibition/${momentId}`;
      },
      nft: (options: NftFilter) => {
        return `${getPath.api.farcasterWebhook.base}/nft/${options.chainId}/${options.contractAddress}/${options.tokenId}`;
      },
    },
    openGraph: {
      base: '/api/og',
      edition: (options: CollectionFilter): EditionUrl => {
        return `${getPath.api.openGraph.base}/edition/${options.chainId}/${
          options.contractAddress
        }/${Date.now()}`;
      },
      moment: (options: { momentId: number; cacheKey: number }): MomentUrl => {
        return `${getPath.api.openGraph.base}/exhibition/${options.momentId}/${options.cacheKey}`;
      },
      nft: (options: NftFilter): NftUrl => {
        return `${getPath.api.openGraph.base}/nft/${options.chainId}/${options.contractAddress}/${options.tokenId}`;
      },
    },
  },
  exhibitions: {
    calendar: '/exhibitions/calendar',
  },
  trending: {
    drops: '/trending/drops',
    editions: '/trending/editions',
    galleries: '/trending/galleries',
    exhibitions: '/trending/exhibitions',
  },
  browse: {
    ...pagePaths.browse,
    dropsByStatus: (status: DropStatus) => {
      return {
        pathname: pagePaths.browse.drops,
        query: {
          [ROUTER_PARAMS.DROP_STATUS]: status,
        },
      };
    },
  },
  create: {
    page: pagePaths.create,
    collection: '/create/standard',
    edition: '/create/edition',
    drop: '/create/drop',
    mint: '/create/mint',
    mintToCollection: (options: CollectionFilter): UrlObject => {
      return {
        pathname: '/create/upload',
        query: {
          contractAddress: options.contractAddress,
          chainId: options.chainId,
        },
      };
    },
    world: {
      page: '/create/gallery',
      invite: '/create/gallery/invite-team',
    },
  },
  collection: {
    page: pagePaths.collection,
    byCreator: (
      slugOrContractAddress: string,
      creatorPublicKey: string
    ): UrlObject => {
      return {
        pathname: pagePaths.collection(slugOrContractAddress),
        query: {
          [ROUTER_PARAMS.COLLECTION_CREATOR]: creatorPublicKey,
        },
      };
    },
    byMedia: (
      slugOrContractAddress: string,
      distinctAssetKey: string
    ): UrlObject => {
      return {
        pathname: pagePaths.collection(slugOrContractAddress),
        query: {
          [ROUTER_PARAMS.COLLECTION_DISTINCT_ASSET_KEY]: distinctAssetKey,
        },
      };
    },
  },
  /** Note that importFrom is used to avoid reusing `import` (protected variable name) */
  importFrom: {
    highlight: IMPORT_FROM_HIGHLIGHT_PATH,
  },
  /** @deprecated use token instead */
  nft: {
    /** @deprecated use token.page instead */
    page: pagePaths.nft,
  },
  profile: {
    page: pagePaths.profile,
    follows: (
      usernameOrAddress: string,
      followsType: ProfileDeepLinkModalParam
    ): UrlObject => {
      return {
        pathname: pagePaths.profile(usernameOrAddress),
        query: {
          [ROUTER_PARAMS.MODAL_DEEP_LINK]: followsType,
        },
      };
    },
  },
  verify: {
    page: '/profile/verify',
    instagram: '/profile/verify/instagram',
    twitter: '/profile/verify/twitter',
  },
  world: {
    page: (slug: string) => {
      return pagePaths.world(slug);
    },
    dashboard: (id: number) => {
      return `/manage/gallery/${id}`;
    },
    acceptTransfer: (slug: string) => {
      return `/gallery/${slug}/accept-transfer`;
    },
  },
  drop: {
    page: (addressOrSlug: string) => {
      return `/collection/${addressOrSlug}`;
    },
    start: (options: { contractAddress: string; chainId: ChainId }) => {
      const { contractAddress, chainId } = options;
      return {
        pathname: `/create/drop/${contractAddress}/start`,
        query: { [ROUTER_PARAMS.CHAIN_ID]: chainId },
      };
    },
    startSuccess: (options: { contractAddress: string; chainId: ChainId }) => {
      const { contractAddress, chainId } = options;
      return {
        pathname: `/create/drop/${contractAddress}/start/success`,
        query: { [ROUTER_PARAMS.CHAIN_ID]: chainId },
      };
    },
    startSale: (options: {
      contractAddress: string;
      saleType: CreateDropSaleType;
      chainId: ChainId;
    }) => {
      const { contractAddress, saleType, chainId } = options;
      return {
        pathname: `/create/drop/${contractAddress}/start/${saleType}`,
        query: {
          [ROUTER_PARAMS.CHAIN_ID]: chainId,
        },
      };
    },
    reveal: (options: { contractAddress: string; chainId: ChainId }) => {
      const { contractAddress, chainId } = options;
      return {
        pathname: `/create/${contractAddress}/drop/reveal`,
        query: { [ROUTER_PARAMS.CHAIN_ID]: chainId },
      };
    },
    revealSuccess: (options: { contractAddress: string; chainId: ChainId }) => {
      const { contractAddress, chainId } = options;
      return {
        pathname: `/create/${contractAddress}/drop/reveal/success`,
        query: { [ROUTER_PARAMS.CHAIN_ID]: chainId },
      };
    },
  },
  mint: {
    page: pagePaths.mint,
    filterByAttribute: (options: {
      attributeValue: string;
      collection: CollectionFilter;
    }) => {
      return {
        pathname: pagePaths.mint(options.collection),
        query: {
          [ROUTER_PARAMS.COLLECTION_ATTRIBUTES]: options.attributeValue,
        },
      };
    },
  },
  moment: {
    page: (options: { momentId: number; worldSlug: string }) => {
      const { momentId, worldSlug } = options;
      return `/gallery/${worldSlug}/exhibition/${momentId}` as const;
    },
  },
  token: {
    page: pagePaths.token,
  },
} as const;

export const getCollectionPath = (collection: CollectionPathInputs) => {
  return isMintableContract(collection.contractType)
    ? getPath.mint.page({
        contractAddress: collection.contractAddress,
        chainId: collection.chainId,
      })
    : getPath.collection.page(collection.contractAddress);
};

export const managePaths = {
  world: (worldId: number) => {
    return {
      dashboard: `/manage/gallery/${worldId}`,
      details: `/manage/gallery/${worldId}/details`,
      allowlist: {
        view: `/manage/gallery/${worldId}/invite`,
        edit: `/manage/gallery/${worldId}/invite/add-sellers`,
        fee: `/manage/gallery/${worldId}/invite/fee`,
      },
      inventory: {
        drops: `/manage/gallery/${worldId}/inventory/drops`,
        editions: `/manage/gallery/${worldId}/inventory/editions`,
        nfts: `/manage/gallery/${worldId}/inventory/nfts`,
      },
      moments: {
        create: {
          details: `/manage/gallery/${worldId}/exhibition/create`,
          allowlist: `/manage/gallery/${worldId}/exhibition/create/allowlist`,
        },
        list: `/manage/gallery/${worldId}/exhibitions`,
      },
      payments: {
        view: `/manage/gallery/${worldId}/payments`,
        edit: `/manage/gallery/${worldId}/payments/edit`,
      },
      team: {
        view: `/manage/gallery/${worldId}/team`,
        admins: `/manage/gallery/${worldId}/team/admins`,
        editors: `/manage/gallery/${worldId}/team/editors`,
      },
      ownership: {
        view: `/manage/gallery/${worldId}/ownership`,
        transfer: `/manage/gallery/${worldId}/ownership/transfer`,
      },
    } as const;
  },
  moment: (options: { momentId: number; worldId: number }) => {
    const { momentId, worldId } = options;

    return {
      dashboard: `/manage/gallery/${worldId}/exhibition/${momentId}`,
      drops: `/manage/gallery/${worldId}/exhibition/${momentId}/drops`,
      edit: {
        storytelling: `/manage/gallery/${worldId}/exhibition/${momentId}/customize`,
        details: `/manage/gallery/${worldId}/exhibition/${momentId}/edit`,
        allowlist: `/manage/gallery/${worldId}/exhibition/${momentId}/edit/allowlist`,
      },
      editions: `/manage/gallery/${worldId}/exhibition/${momentId}/editions`,
      nfts: `/manage/gallery/${worldId}/exhibition/${momentId}/nfts`,
      sort: {
        editor: `/manage/gallery/${worldId}/exhibition/${momentId}/sort`,
        preview: `/manage/gallery/${worldId}/exhibition/${momentId}/_/preview/sort`,
      },
    } as const;
  },
};

export const careerPagePaths = {
  gemFormUrl: `https://www.gem.com/form?formID=c6723bb1-a66f-4579-b677-9e74e0086cc4`,
} as const;

export const HELP_CENTER_HOME = 'https://help.foundation.app';
export const HELP_CENTER_REQUEST_SUPPORT_URL = `${HELP_CENTER_HOME}/hc/en-us/requests/new`;

export const helpCenterPaths = {
  aboutDMCA: `${HELP_CENTER_HOME}/hc/en-us/articles/4419467336603`,
  aboutBaseL2: {
    bridging: `${HELP_CENTER_HOME}/hc/en-us/articles/22758724973595`,
  },
  assetTechnicalSpecs: `${HELP_CENTER_HOME}/hc/en-us/articles/6037747211547`,
  createACollection: `${HELP_CENTER_HOME}/hc/en-us/articles/4419002081051`,
  createADrop: `${HELP_CENTER_HOME}/hc/en-us/articles/10091970778395`,
  createAMoment: `${HELP_CENTER_HOME}/hc/en-us/articles/18089039273371`,
  createAnEdition: `${HELP_CENTER_HOME}/hc/en-us/articles/13417835149339`,
  createAWorld: `${HELP_CENTER_HOME}/hc/en-us/articles/11919894685467`,
  curatingOnFnd: `${HELP_CENTER_HOME}/hc/en-us/categories/11912344172315`,
  dropsFaq: `${HELP_CENTER_HOME}/hc/en-us/articles/9321632980507`,
  dutchAuctions: `${HELP_CENTER_HOME}/hc/en-us/articles/16133566055195`,
  editionsFaq: `${HELP_CENTER_HOME}/hc/en-us/articles/13417874712987`,
  gettingStarted: {
    aboutSmartContracts: `${HELP_CENTER_HOME}/hc/en-us/articles/4419483563931`,
    inWeb3: `${HELP_CENTER_HOME}/hc/en-us/categories/7242900176027`,
    walletSetup: `${HELP_CENTER_HOME}/hc/en-us/articles/4418980937627`,
  },
  importingFromHighlight: `${HELP_CENTER_HOME}/hc/en-us/articles/21066482870939`,
  marketplaceBalance: `${HELP_CENTER_HOME}/hc/en-us/articles/6601042998299`,
  marketplaceFee: `${HELP_CENTER_HOME}/hc/en-us/articles/4419440106011`,
  policies: {
    adultContent: `${HELP_CENTER_HOME}/hc/en-us/articles/5658333349403`,
    counterfitContent: `${HELP_CENTER_HOME}/hc/en-us/articles/5658247759771`,
    hatefulOrAbusiveContent: `${HELP_CENTER_HOME}/hc/en-us/articles/5658554731931`,
    misleadingIdentities: `${HELP_CENTER_HOME}/hc/en-us/articles/5658681073819`,
    spamFollow: `${HELP_CENTER_HOME}/hc/en-us/articles/6601042998299`,
    violentOrGraphicContent: `${HELP_CENTER_HOME}/hc/en-us/articles/5658535137563`,
  },
  referralsFaq: `${HELP_CENTER_HOME}/hc/en-us/articles/6155086291739`,
  revealYourDrop: `${HELP_CENTER_HOME}/hc/en-us/articles/10068613429403`,
  sellingInWorlds: `${HELP_CENTER_HOME}/hc/en-us/articles/4562018706459`,
  splitNftEarnings: `${HELP_CENTER_HOME}/hc/en-us/articles/4513530159131`,
  startYourDrop: `${HELP_CENTER_HOME}/hc/en-us/articles/10092987104155`,
  transferWorldOwnership: `${HELP_CENTER_HOME}/hc/en-us/articles/20963837560219`,
  worldAddSellers: `${HELP_CENTER_HOME}/hc/en-us/articles/11919894685467#h_01HBECV284HCWWQWKG98B06QKN`,
  worldNftOwnership: `${HELP_CENTER_HOME}/hc/en-us/articles/16662528611227#01HCA7482W2YYHC25GSD2W54V5`,
  worldsFaq: `${HELP_CENTER_HOME}/hc/en-us/articles/12553756273435`,
} as const;

type HelpCenterPaths = typeof helpCenterPaths;

/**
 * @example 'dropsFaq'
 * @example 'worldsFaq'
 * @example 'gettingStarted.inWeb3'
 * @example 'policies.spamFollow'
 */
export type HelpCenterArticleName = NestedKeyPicker<HelpCenterPaths>;
