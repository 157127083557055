import {
  complement,
  isEmpty,
  prop,
  when,
  anyPass,
  isNil,
  all,
  is,
  slice,
  curry,
  ifElse,
  compose,
  has,
  any,
  take,
  propSatisfies,
  toString,
  cond,
  T,
  equals,
} from 'ramda';

import { isAddress } from 'utils/address';

import { UserLight } from 'types/Account';

export const isNumber = is(Number);
export const isString = is(String);
export const isNumberType = (value: unknown): value is number =>
  typeof value === 'number';
export const isStringType = (value: unknown): value is string =>
  typeof value === 'string';
export const isBooleanType = (value: unknown): value is boolean =>
  typeof value === 'boolean';
export const isErrorInstance = (value: unknown): value is Error =>
  value instanceof Error;

export const castToString = (val: number) => String(val);
export const castToNumber = (val: string | number) => Number(val);

export const castNanToZero = (val: number) => {
  return isNaN(val) ? 0 : val;
};

export function getArrayOrEmpty<T>(arr: T[]): T[] {
  return Array.isArray(arr) ? arr : [];
}

export const maybeToString: (arg0: unknown) => string = when(
  isNumber,
  toString
);

/*
 * @link https://sebhastian.com/fisher-yates-shuffle-javascript/
 */
export function shuffle<T>(inputArr: T[]): T[] {
  // create a new array vs. mutating the existing one
  const arr = [...inputArr];
  let i = arr.length;
  while (--i > 0) {
    const randIndex = Math.floor(Math.random() * (i + 1));
    // @ts-expect-error we copied this code from the internet
    [arr[randIndex], arr[i]] = [arr[i], arr[randIndex]];
  }
  return arr;
}

export function truncateAfterWordsIfNeeded(text: string, wordLimit: number) {
  const words = text.trim().split(' ');

  if (words.length <= wordLimit) {
    return {
      text,
      isTruncated: false,
    };
  } else {
    return {
      text: words.slice(0, wordLimit).join(' ') + '…',
      isTruncated: true,
    };
  }
}

// when an array or string isn’t empty
export const notEmpty = complement(isEmpty);

const isFalse = equals(false);

export const isEmptyOrNil = anyPass([isEmpty, isNil, isFalse]);

export const notEmptyOrNil = complement(isEmptyOrNil);

export const getFirstValue = <Value = string>(
  val: Value[] | Value
): Value | undefined => {
  return Array.isArray(val) ? val[0] : val;
};

export const getLastValue = <Value = string>(
  val: Value[] | Value
): Value | undefined => {
  return Array.isArray(val) ? val[val.length - 1] : val;
};

export function isValueInArray<Value extends string, Arr extends Value[]>(
  value: Value,
  values: Arr
) {
  return value ? values.includes(value) : false;
}

const TRUNCATE_ADDRESS_CHARS = 4;

export const truncateAddress = (address: string): string =>
  truncateStringCenter(TRUNCATE_ADDRESS_CHARS, address);

export const truncateStringCenter = curry(
  (count: number, string: string): string =>
    // defensively call the slice function only when it’s a string (vs. a null)
    when(
      isString,
      // add two characters to the first part of the slice to cater for 0x
      (str) => `${slice(0, count + 2, str)}…${slice(-count, Infinity, str)}`,
      string
    )
);

const appendEllipsis = (count: number, string: string) =>
  compose<string, string, string>((str) => `${str}…`, take(count))(string);

export const maybeAddEllipsis = (count: number, string: string) => {
  if (string && string.length >= count) {
    return appendEllipsis(count, string);
  } else {
    return string;
  }
};

export const truncateMetaDescription = (description: string): string => {
  return maybeAddEllipsis(160, description);
};

export function padNumber(num: number, size = 5): string {
  if (num < 10) {
    return (Math.pow(10, size) + ~~num).toString().substring(1);
  } else {
    return num.toString();
  }
}

export const hasUsername = (account: Partial<Pick<UserLight, 'username'>>) =>
  notEmptyOrNil(account?.username);

const getPublicKeyOrId = cond([
  [has('publicKey'), prop('publicKey')],
  [T, prop('id')],
]);

export const formatUsername = (username: string) => `@${username}`;

export const formatUsernameIfNeeded = (username: string) =>
  username.startsWith('@') ? username : formatUsername(username);

export const getUsernameOrAddress: (arg0: Partial<UserLight>) => string =
  ifElse(
    hasUsername,
    compose(formatUsername, prop('username')),
    getPublicKeyOrId
  );

const truncateWhenIsAddress = when<string, string>(
  isAddress,
  truncateStringCenter(TRUNCATE_ADDRESS_CHARS)
);

export const getUsernameOrTruncatedAddress = compose<
  Partial<UserLight>,
  string,
  string
>(truncateWhenIsAddress, getUsernameOrAddress);

const getNameOrUsername = ifElse(
  propSatisfies(notEmptyOrNil, 'name'),
  prop('name'),
  prop('username')
);

export function getUsernameOrAddressInfo(user: Partial<UserLight>) {
  const nameOrUsername: string = when(notEmptyOrNil, getNameOrUsername, user);
  return {
    nameOrUsername,
    publicKey: user?.publicKey,
    usernameOrAddress: getUsernameOrTruncatedAddress(user),
    userPath: getUsernameOrAddress(user),
    hasName: notEmptyOrNil(user?.name),
    hasUsername: notEmptyOrNil(user?.username),
    isAddress: isEmptyOrNil(Boolean(nameOrUsername)),
  };
}

export const rejectNils = <Type>(array: (Type | null)[]) =>
  array.filter((value): value is Type => !isNil(value));

export const isAnyTrue = any(Boolean);
export const isAllTrue = all(Boolean);

export const noop = (): void => void 0;

export const isBrowser = () => typeof window !== 'undefined';

export const debounce = <Args = unknown>(
  func: (...args: Args[]) => unknown,
  delay = 200
) => {
  let timeout: number;

  return function (...args: Args[]) {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), delay);
  };
};

export const getSafeForUploadColor = (validPercent: number) => {
  if (Number.isNaN(validPercent) || validPercent < 50) {
    return '$red3';
  } else if (validPercent >= 50 && validPercent < 80) {
    return '#f1c23e';
  } else {
    return '$green4';
  }
};

export const mbToBytes = (value: number) => value * 1024 * 1024;
export const bytesToMb = (value: number) => value / 1024 / 1024;

/**
 * @param min - minimum value
 * @param max - maximum value
 * @param percentage - percentage between 0 and 1
 * @returns linear interpolation between min and max
 */
export const lerp = (min: number, max: number, percentage: number): number =>
  min * (1 - percentage) + max * percentage;

export const getIndexPercentage = (index: number, array: unknown[]): number => {
  return index / (array.length - 1);
};

type LerpThroughArrayOptions = {
  /** minimum value */
  min: number;
  /** minimum value */
  max: number;
  /**  */
  index: number;
  /** some array */
  array: unknown[];
};

/**
 * This is an abstraction over lerp, to make it easier to worth with inside a map.
 */
export const lerpThroughArray = (options: LerpThroughArrayOptions) => {
  return lerp(
    options.min,
    options.max,
    getIndexPercentage(options.index, options.array)
  );
};

/**
 * @param n - number of times to repeat the item
 * @param item - the item to repeat
 * @returns array of repeated items
 */
export function repeat<T>(n: number, item: T): T[] {
  return Array.from({ length: n }, () => item);
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getPromiseValueOrFallback<Type>(
  result: PromiseFulfilledResult<Type> | PromiseRejectedResult,
  fallback: Type
): Type {
  if (result.status === 'fulfilled') {
    return result.value;
  } else {
    return fallback;
  }
}

export function cycle<T extends string | number | object>(
  currentValue: T,
  values: T[],
  direction = 1
): T | undefined {
  const currentIndex = values.indexOf(currentValue);
  const nextIndex = (currentIndex + direction + values.length) % values.length;
  return values[nextIndex];
}
