import { base, baseSepolia, mainnet, sepolia } from 'viem/chains';

import { getPrimaryChainConfig, getL2ChainConfig } from 'lib/chains';
import {
  alchemyMainnetEnvSchema,
  alchemyTestnetEnvSchema,
  alchemyUrlSchema,
} from 'schemas/env/alchemy';
import { chainCategorySchema } from 'schemas/env/chain-category';
import { edgeConfigEnvSchema } from 'schemas/env/edge-config';
import {
  relayerMainnetEnvSchema,
  relayerTestnetEnvSchema,
} from 'schemas/env/relayer';

const IS_SERVER_SIDE = typeof window === 'undefined';

const LOCALHOST_URL = 'http://localhost:3000';
const HTTPS_PROTOCOL_SCHEME = 'https://';

const ssrOnly =
  <T>(callback: () => T) =>
  () => {
    if (!IS_SERVER_SIDE) {
      throw new Error('This operation is restricted to server-side execution.');
    }

    return callback();
  };

// prettier-ignore
/**
 * These values are present on the client and server.
 * They must be prefixed with NEXT_PUBLIC_.
 * */
const PROCESS = {
  API_URL: process.env.NEXT_PUBLIC_API_URL,
  APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN,
  BRANDSHIELD_DOMAIN_TOKEN: process.env.NEXT_PUBLIC_BRANDSHIELD_DOMAIN_TOKEN,
  CHAIN_CATEGORY: process.env.NEXT_PUBLIC_CHAIN_CATEGORY,
  DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  FND_DATA_URL: process.env.NEXT_PUBLIC_FND_DATA_URL,
  HASURA_URL: process.env.NEXT_PUBLIC_HASURA_URL,
  NODE_SERVER_URL: process.env.NEXT_PUBLIC_SERVER_URL,
  PRIVY_APP_ID: process.env.NEXT_PUBLIC_PRIVY_APP_ID,
  SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  /** @see https://vercel.com/docs/projects/environment-variables/system-environment-variables#framework-environment-variables */
  VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  /** @see https://vercel.com/docs/projects/environment-variables/system-environment-variables#framework-environment-variables */
  VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  WALLET_CONNECT_PROJECT_ID: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,

  // Alchemy (mainnet)
  ALCHEMY_HTTPS_URL_BASE: process.env.NEXT_PUBLIC_ALCHEMY_HTTPS_URL_BASE,
  ALCHEMY_HTTPS_URL_MAINNET: process.env.NEXT_PUBLIC_ALCHEMY_HTTPS_URL_MAINNET,

  // Alchemy (testnet)
  ALCHEMY_HTTPS_URL_BASE_SEPOLIA: process.env.NEXT_PUBLIC_ALCHEMY_HTTPS_URL_BASE_SEPOLIA,
  ALCHEMY_HTTPS_URL_GOERLI: process.env.NEXT_PUBLIC_ALCHEMY_HTTPS_URL_GOERLI,
  ALCHEMY_HTTPS_URL_SEPOLIA: process.env.NEXT_PUBLIC_ALCHEMY_HTTPS_URL_SEPOLIA,
};

// prettier-ignore
/**
 * These values are only present on the server
 * They must NOT be prefixed with NEXT_PUBLIC_.
 * */
const SERVER_PROCESS = {
  AIRTABLE_API_KEY: process.env.AIRTABLE_API_KEY,
  PINATA_API_KEY: process.env.PINATA_API_KEY,
  PINATA_SECRET_KEY: process.env.PINATA_SECRET_KEY,
  REVALIDATION_TOKEN: process.env.REVALIDATION_TOKEN,
  DATADOG_API_KEY: process.env.DATADOG_API_KEY,
  DATADOG_APPLICATION_NAME: process.env.DATADOG_APPLICATION_NAME,
  DEFENDER_PUBLIC_API_KEY: process.env.DEFENDER_PUBLIC_API_KEY,
  DEFENDER_SECRET_API_KEY: process.env.DEFENDER_SECRET_API_KEY,

  FARCASTER_AUTH_TOKEN: process.env.FARCASTER_AUTH_TOKEN,

  // Defender (mainnet)
  DEFENDER_RELAYER_BASE_API_KEY: process.env.DEFENDER_RELAYER_BASE_API_KEY,
  DEFENDER_RELAYER_BASE_SECRET_KEY: process.env.DEFENDER_RELAYER_BASE_SECRET_KEY,
  DEFENDER_RELAYER_MAINNET_API_KEY: process.env.DEFENDER_RELAYER_MAINNET_API_KEY,
  DEFENDER_RELAYER_MAINNET_SECRET_KEY: process.env.DEFENDER_RELAYER_MAINNET_SECRET_KEY,

  // Defender (testnet)
  DEFENDER_RELAYER_BASE_SEPOLIA_API_KEY: process.env.DEFENDER_RELAYER_BASE_SEPOLIA_API_KEY,
  DEFENDER_RELAYER_BASE_SEPOLIA_SECRET_KEY: process.env.DEFENDER_RELAYER_BASE_SEPOLIA_SECRET_KEY,
  DEFENDER_RELAYER_GOERLI_API_KEY: process.env.DEFENDER_RELAYER_GOERLI_API_KEY,
  DEFENDER_RELAYER_GOERLI_SECRET_KEY: process.env.DEFENDER_RELAYER_GOERLI_SECRET_KEY,
  DEFENDER_RELAYER_SEPOLIA_API_KEY: process.env.DEFENDER_RELAYER_SEPOLIA_API_KEY,
  DEFENDER_RELAYER_SEPOLIA_SECRET_KEY: process.env.DEFENDER_RELAYER_SEPOLIA_SECRET_KEY,

  // Vercel Edge config
  EDGE_CONFIG_FRONTEND_ALLOWLISTS: process.env.EDGE_CONFIG_FRONTEND_ALLOWLISTS,
};

const CHAIN_CATEGORY = chainCategorySchema.parse(PROCESS.CHAIN_CATEGORY);

export const IS_MAINNET = CHAIN_CATEGORY === 'mainnet';
export const IS_TESTNET = CHAIN_CATEGORY === 'testnet';

const getAlchemyEnv = () => {
  if (IS_MAINNET) {
    return alchemyMainnetEnvSchema.parse({
      [base.id]: PROCESS.ALCHEMY_HTTPS_URL_BASE,
      [mainnet.id]: PROCESS.ALCHEMY_HTTPS_URL_MAINNET,
    });
  }

  return alchemyTestnetEnvSchema.parse({
    [baseSepolia.id]: PROCESS.ALCHEMY_HTTPS_URL_BASE_SEPOLIA,
    [sepolia.id]: PROCESS.ALCHEMY_HTTPS_URL_SEPOLIA,
  });
};

const ALCHEMY = getAlchemyEnv();

const alchemyMainnetUrl = alchemyUrlSchema.parse(
  PROCESS.ALCHEMY_HTTPS_URL_MAINNET
);

if (!PROCESS.WALLET_CONNECT_PROJECT_ID) {
  throw new Error('NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID must be set');
}

if (!PROCESS.API_URL) {
  throw new Error('PROCESS.API_URL is not set');
}

if (!PROCESS.FND_DATA_URL) {
  throw new Error('PROCESS.FND_DATA_URL is not set');
}

if (!PROCESS.HASURA_URL) {
  throw new Error('PROCESS.HASURA_URL is not set');
}

if (!PROCESS.NODE_SERVER_URL) {
  throw new Error('PROCESS.NODE_SERVER_URL is not set');
}

if (!PROCESS.PRIVY_APP_ID) {
  throw new Error('PROCESS.PRIVY_APP_ID is not set');
}

// Cast VERCEL_ENV to `development` if unset
type VercelEnv = 'production' | 'preview' | 'development';
const VERCEL_ENV = PROCESS.VERCEL_ENV
  ? (PROCESS.VERCEL_ENV as VercelEnv)
  : ('development' as const);

export const IS_DEV = VERCEL_ENV === 'development';
export const IS_PREVIEW = VERCEL_ENV === 'preview';
export const IS_PROD = VERCEL_ENV === 'production';

// Checks that only run on prod + mainnet (i.e. foundation.app)
if (IS_PROD && IS_MAINNET) {
  if (!PROCESS.SEGMENT_KEY) {
    throw new Error('PROCESS.SEGMENT_KEY is not set');
  }

  if (!PROCESS.DATADOG_CLIENT_TOKEN) {
    throw new Error('PROCESS.DATADOG_CLIENT_TOKEN is not set');
  }

  if (!PROCESS.BRANDSHIELD_DOMAIN_TOKEN) {
    throw new Error('PROCESS.BRANDSHIELD_DOMAIN_TOKEN is not set');
  }

  if (!PROCESS.SENTRY_DSN) {
    throw new Error('PROCESS.SENTRY_DSN is not set');
  }

  if (!PROCESS.API_URL) {
    throw new Error('PROCESS.API_URL is not set');
  }
}

// Checks that only run server-side + prod + mainnet (i.e. foundation.app)
if (IS_SERVER_SIDE && IS_PROD && IS_MAINNET) {
  if (!SERVER_PROCESS.AIRTABLE_API_KEY) {
    throw new Error('SERVER_PROCESS.AIRTABLE_API_KEY is not set');
  }
}

// Checks that only run server-side + staging + prod
if (IS_SERVER_SIDE && (IS_PREVIEW || IS_PROD)) {
  if (!SERVER_PROCESS.DATADOG_API_KEY) {
    throw new Error('SERVER_PROCESS.DATADOG_API_KEY is not set');
  }

  if (!SERVER_PROCESS.DATADOG_APPLICATION_NAME) {
    throw new Error('SERVER_PROCESS.DATADOG_APPLICATION_NAME is not set');
  }
}

if (IS_SERVER_SIDE) {
  if (!SERVER_PROCESS.DEFENDER_PUBLIC_API_KEY) {
    throw new Error('SERVER_PROCESS.DEFENDER_PUBLIC_API_KEY is not set');
  }

  if (!SERVER_PROCESS.DEFENDER_SECRET_API_KEY) {
    throw new Error('SERVER_PROCESS.DEFENDER_SECRET_API_KEY is not set');
  }

  if (!SERVER_PROCESS.FARCASTER_AUTH_TOKEN) {
    throw new Error('SERVER_PROCESS.FARCASTER_AUTH_TOKEN is not set');
  }
}

// Checks that only run on previews
if (IS_PREVIEW) {
  if (!PROCESS.DATADOG_CLIENT_TOKEN) {
    throw new Error('PROCESS.DATADOG_CLIENT_TOKEN is not set');
  }
}

// Checks that only run on the server
if (IS_SERVER_SIDE) {
  if (!SERVER_PROCESS.PINATA_API_KEY) {
    throw new Error('SERVER_PROCESS.PINATA_API_KEY is not set');
  }

  if (!SERVER_PROCESS.PINATA_SECRET_KEY) {
    throw new Error('SERVER_PROCESS.PINATA_SECRET_KEY is not set');
  }
}

const getAppUrl = (): string => {
  // Assume localhost in development
  if (IS_DEV) {
    return LOCALHOST_URL;
  }

  // If APP_DOMAIN is set, use it.
  // This should be set in production + "next" previews
  if (PROCESS.APP_DOMAIN) {
    /**
     * Our APP_DOMAIN does should not include the protocol scheme, but we double check that here just in case.
     * It does not include the protocol scheme to be consistent with VERCEL_URL (see below)
     */
    if (PROCESS.APP_DOMAIN.startsWith(HTTPS_PROTOCOL_SCHEME)) {
      throw new Error(
        `APP_DOMAIN should not start with ${HTTPS_PROTOCOL_SCHEME}`
      );
    }

    return HTTPS_PROTOCOL_SCHEME + PROCESS.APP_DOMAIN;
  }

  // Should be set for all preview links
  if (PROCESS.VERCEL_URL) {
    /**
     * Vercel docs say that the protocol scheme is not included in this variable, but we double check here just in case.
     * @see https://vercel.com/docs/projects/environment-variables/system-environment-variables
     * */
    if (PROCESS.VERCEL_URL.startsWith(HTTPS_PROTOCOL_SCHEME)) {
      throw new Error(
        `VERCEL_URL should not start with ${HTTPS_PROTOCOL_SCHEME}`
      );
    }

    return HTTPS_PROTOCOL_SCHEME + PROCESS.VERCEL_URL;
  }

  throw new Error('Failed to create APP_URL env');
};

const APP_URL = getAppUrl();

const PRIMARY_CHAIN_CONFIG = getPrimaryChainConfig(CHAIN_CATEGORY);
const L2_CHAIN_CONFIG = getL2ChainConfig(CHAIN_CATEGORY);

/**
 * These values are present on the client and server.
 *
 * Some are optional. TS is the source of truth.
 */
export const ENV = {
  ALCHEMY: ALCHEMY,
  ALCHEMY_MAINNET_URL: alchemyMainnetUrl.httpsUrl,
  API_URL: PROCESS.API_URL,
  APP_URL: APP_URL,
  BRANDSHIELD_DOMAIN_TOKEN: PROCESS.BRANDSHIELD_DOMAIN_TOKEN,
  CHAIN_CATEGORY: CHAIN_CATEGORY,
  DATADOG_CLIENT_TOKEN: PROCESS.DATADOG_CLIENT_TOKEN,
  FND_DATA_URL: PROCESS.FND_DATA_URL,
  HASURA_URL: PROCESS.HASURA_URL,
  NODE_SERVER_URL: PROCESS.NODE_SERVER_URL,
  PRIMARY_CHAIN: PRIMARY_CHAIN_CONFIG.chain,
  PRIMARY_CHAIN_ID: PRIMARY_CHAIN_CONFIG.chainId,
  PRIMARY_CREATE_CHAIN: L2_CHAIN_CONFIG.chain,
  PRIMARY_CREATE_CHAIN_ID: L2_CHAIN_CONFIG.chainId,
  PRIVY_APP_ID: PROCESS.PRIVY_APP_ID,
  SEGMENT_KEY: PROCESS.SEGMENT_KEY,
  SENTRY_DSN: PROCESS.SENTRY_DSN,
  VERCEL_ENV: VERCEL_ENV,
  WALLET_CONNECT_PROJECT_ID: PROCESS.WALLET_CONNECT_PROJECT_ID as string,
} as const;

/**
 * These values are only present on the server
 *
 * Some are optional. TS is the source of truth.
 *
 * Those that are type-cast "as string" are required on the server, but will not be present on the client.
 */
export const SERVER_ENV = {
  AIRTABLE_API_KEY: SERVER_PROCESS.AIRTABLE_API_KEY as string,
  PINATA_API_KEY: SERVER_PROCESS.PINATA_API_KEY as string,
  PINATA_SECRET_KEY: SERVER_PROCESS.PINATA_SECRET_KEY as string,
  REVALIDATION_TOKEN: SERVER_PROCESS.REVALIDATION_TOKEN,
  DATADOG_API_KEY: SERVER_PROCESS.DATADOG_API_KEY,
  DATADOG_APPLICATION_NAME: SERVER_PROCESS.DATADOG_APPLICATION_NAME,
  FARCASTER_AUTH_TOKEN: SERVER_PROCESS.FARCASTER_AUTH_TOKEN as string,
  getDefenderEnv: ssrOnly(() => {
    return IS_MAINNET
      ? relayerMainnetEnvSchema.parse({
          mainnet: {
            relayerApiKey: SERVER_PROCESS.DEFENDER_RELAYER_MAINNET_API_KEY,
            relayerApiSecret:
              SERVER_PROCESS.DEFENDER_RELAYER_MAINNET_SECRET_KEY,
          },
          base: {
            relayerApiKey: SERVER_PROCESS.DEFENDER_RELAYER_BASE_API_KEY,
            relayerApiSecret: SERVER_PROCESS.DEFENDER_RELAYER_BASE_SECRET_KEY,
          },
        })
      : relayerTestnetEnvSchema.parse({
          baseSepolia: {
            relayerApiKey: SERVER_PROCESS.DEFENDER_RELAYER_BASE_SEPOLIA_API_KEY,
            relayerApiSecret:
              SERVER_PROCESS.DEFENDER_RELAYER_BASE_SEPOLIA_SECRET_KEY,
          },
          sepolia: {
            relayerApiKey: SERVER_PROCESS.DEFENDER_RELAYER_SEPOLIA_API_KEY,
            relayerApiSecret:
              SERVER_PROCESS.DEFENDER_RELAYER_SEPOLIA_SECRET_KEY,
          },
        });
  }),
  getEdgeConfigEnv: ssrOnly(() => {
    return edgeConfigEnvSchema.parse({
      allowlists: SERVER_PROCESS.EDGE_CONFIG_FRONTEND_ALLOWLISTS,
    });
  }),
} as const;
