import qs from 'query-string';
import {
  allPass,
  anyPass,
  compose,
  curry,
  ifElse,
  last,
  pipe,
  startsWith,
  test,
  uniq,
  unless,
  when,
} from 'ramda';

import { URL_REGEX } from 'lib/constants';
import { isString, notEmptyOrNil } from 'utils/helpers';

import { AssetMimeType } from 'types/Assets';

import { getInstagramHandle, getSocialHandle } from './strings';

export const urlWithParams = (
  url: string,
  query: qs.StringifiableRecord
): string => {
  return when(
    notEmptyOrNil,
    (url) => {
      // Check if url already contains a query string
      // Cant use new URL api as Contentful sends no protocol
      const qsPattern = new RegExp(/\?.+=.*/g);
      if (qsPattern.test(url)) {
        return url;
      }
      return `${url}?${qs.stringify(query)}`;
    },
    url
  );
};

export const withHttps = when(
  isString,
  ifElse(
    startsWith('http'),
    (url) => url,
    (url) => `https:${url}`
  )
);

type ReplaceUrlPathOptions = {
  assetUrl: string;
  newPath: string;
};

export function replaceUrlPath(options: ReplaceUrlPathOptions): string {
  try {
    const newAssetUrl = new URL(options.newPath, options.assetUrl);
    return newAssetUrl.toString();
  } catch {
    return options.assetUrl;
  }
}

export function getFileName(fileUrl: string | null | undefined) {
  if (typeof fileUrl === 'string') {
    const splitUrl = fileUrl.split('/').pop();
    return splitUrl ? splitUrl.split('#')[0]?.split('?')[0] : undefined;
  } else {
    return undefined;
  }
}

export function getFileExtension(fileUrl: string | undefined) {
  if (typeof fileUrl === 'string') {
    const fileName = getFileName(fileUrl);
    return fileName ? fileName.split('.').pop() : undefined;
  } else {
    return undefined;
  }
}

export function getFileExtensionFromFileName(fileName: string | undefined) {
  if (typeof fileName === 'string') {
    return fileName.split('.').pop();
  } else {
    return undefined;
  }
}

const fileTypesMap: Record<AssetMimeType, string> = {
  'image/jpg': 'JPG',
  'image/jpeg': 'JPG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
  'image/svg+xml': 'SVG',
  'video/mp4': 'MP4',
  'video/quicktime': 'MOV',
  'model/gltf-binary': 'glTF',
  'model/gltf+json': 'glTF',
};

export function getAcceptedFileTypes(
  fileTypes: ReadonlyArray<AssetMimeType>
): string {
  const acceptedFileTypes = uniq(
    fileTypes.map((fileType) => fileTypesMap[fileType])
  );
  return acceptedFileTypes.join(', ');
}

const startsWithHttp = (url: string) => url.startsWith('http');

const appendHandle = curry(
  (url: string, handle: string | undefined): string | undefined => {
    if (!handle) {
      return undefined;
    } else {
      return startsWithHttp(handle) ? handle : `${url}${handle}`;
    }
  }
);

export const buildSocialLinkFunction = {
  website: appendHandle('http://'),
  instagram: compose<string, string, string>(
    appendHandle('https://instagram.com/'),
    getInstagramHandle
  ),
  twitter: compose<string, string, string>(
    appendHandle('https://twitter.com/'),
    getSocialHandle
  ),
  youtube: appendHandle('https://youtube.com/channel/'),
  facebook: appendHandle('https://facebook.com/'),
  twitch: appendHandle('https://twitch.tv/'),
  tiktok: appendHandle('https://www.tiktok.com/'),
  snapchat: appendHandle('https://snapchat.com/add/'),
  ens: appendHandle('https://etherscan.io/enslookup-search?search='),
  eth: appendHandle('https://etherscan.io/address/'),
};

export const buildSocialLink = (
  platform: string,
  handle: string
): string | undefined => {
  if (!handle) return undefined;

  switch (platform) {
    case 'website': {
      return buildSocialLinkFunction.website(handle);
    }
    case 'instagram': {
      return buildSocialLinkFunction.instagram(handle);
    }
    case 'twitter': {
      return buildSocialLinkFunction.twitter(handle);
    }
    case 'youtube': {
      return buildSocialLinkFunction.youtube(handle);
    }
    case 'facebook': {
      return buildSocialLinkFunction.facebook(handle);
    }
    case 'twitch': {
      return buildSocialLinkFunction.twitch(handle);
    }
    case 'tiktok': {
      return buildSocialLinkFunction.tiktok(handle);
    }
    case 'snapchat': {
      return buildSocialLinkFunction.snapchat(handle);
    }
    case 'ens': {
      return buildSocialLinkFunction.ens(handle);
    }
    case 'eth': {
      return buildSocialLinkFunction.eth(handle);
    }
    default: {
      return undefined;
    }
  }
};

export function isUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export function getUrlHost(url: string): string {
  return when(
    notEmptyOrNil,
    (url) => {
      try {
        const urlObject = new URL(url);
        return urlObject.hostname;
      } catch (error) {
        return url;
      }
    },
    url
  );
}

// is a valid url
const isValidUrl = test(URL_REGEX);
// starts with a @ symbol
const hasAtSymbol = startsWith('@');

export const buildTikTokHandle = when(
  allPass([isString, notEmptyOrNil]),
  unless(
    // if the url starts with an @ symbol
    // or is a URL match return false
    anyPass([hasAtSymbol, isValidUrl]),
    // otherwise append @ symbol if true
    (handle) => `@${handle}`
  )
);

export const VALID_URL_REGEX =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})*$/;

export const isValidURL = test(VALID_URL_REGEX);

/** If a string starts with "/", remove it */
const removeLeadingSlash = (path: string) => {
  return path.startsWith('/') ? path.slice(1) : path;
};

/** splits url like foundation.app/a/b/c into ['a', 'b', 'c'] */
export const getUrlPathnameParts = pipe(
  (value: string) => new URL(value),
  (url) => url.pathname,
  (pathname) => removeLeadingSlash(pathname),
  (pathname) => pathname.split('/')
);

export const getLastUrlPathSegment = pipe(
  getUrlPathnameParts,
  (pathnameParts) => last(pathnameParts),
  (lastPart) => lastPart || null
);

export const splitPathname = (pathname: string) => {
  return pathname.split('/').filter(Boolean);
};
