import { captureException } from '@sentry/nextjs';
import type { CaptureContext } from '@sentry/types';

import { logger } from './logger';

const FALLBACK_ERROR_MESSAGE = 'Unknown error';

const getFallbackErrorMessageContext = (value: unknown): string => {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number') {
    return String(value);
  }

  if (Array.isArray(value)) {
    return 'array';
  }

  if (value === 'object') {
    return JSON.stringify(value);
  }

  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  return typeof value;
};

const getFallbackError = (value: unknown): Error => {
  const output = getFallbackErrorMessageContext(value);
  return new Error(`${FALLBACK_ERROR_MESSAGE}: ${output}`);
};

const getDerivedError = (value: unknown): Error | null => {
  if (!value) return null;

  if (value instanceof Error) {
    return value;
  }

  if (typeof value === 'string') {
    return new Error(value);
  }

  if (typeof value !== 'object') {
    return null;
  }

  if ('message' in value && typeof value.message === 'string') {
    return new Error(value.message);
  }

  return null;
};

const getNestedError = (data: object, key: string): Error | null => {
  const value = data[key];

  if (value instanceof Error) {
    return value;
  }

  const derivedError = getDerivedError(value);
  if (derivedError) {
    return derivedError;
  }

  return null;
};

export const forceError = (value: unknown): Error => {
  if (!value) {
    return getFallbackError(value);
  }

  if (value instanceof Error) {
    return value;
  }

  if (typeof value === 'string') {
    return new Error(value);
  }

  const derivedError = getDerivedError(value);
  if (derivedError) {
    return derivedError;
  }

  if (typeof value === 'object') {
    const originalError = getNestedError(value, 'originalError');
    if (originalError) {
      return originalError;
    }

    const nestedError = getNestedError(value, 'error');
    if (nestedError) {
      return nestedError;
    }
  }

  return getFallbackError(value);
};

/**
 * This is a small facade around Sentry's captureException function.
 *
 * Sentry technically supports non-Error values, but aggregates them in an unusable way.
 * This wrapper ensures that the data we send to sentry is actually an Error object.
 *
 * @param error The error to report. Should be an Error object, but will work with anything.
 */
export default function report(
  maybeError: unknown,
  context: CaptureContext = {}
) {
  const error = forceError(maybeError);

  // Send the error to Sentry
  captureException(error, context);

  // Also log the error
  logger.error(error.message, error);
}
