import {
  compose,
  head,
  includes,
  last,
  replace,
  split,
  startsWith,
  when,
} from 'ramda';

import { getUsernameOrAddress, notEmptyOrNil } from './helpers';

const getPathFromUrl = when(
  // when it includes a query param
  includes('?'),
  // split by the question mark and take the first part
  compose(head, split('?'))
);

export const getSocialHandle = when(
  notEmptyOrNil,
  compose(
    // get last part
    last,
    // split by slash
    split('/'),
    // remove trailing slash
    replace(/\/$/, ''),
    // get rid of query params
    getPathFromUrl
  )
);

export const stripAtSymbol = when(startsWith('@'), replace('@', ''));

export const getInstagramHandle = compose(
  when(notEmptyOrNil, stripAtSymbol),
  getSocialHandle
);

export const getUserNameOrAddressAndStripAtSymbol = compose(
  when(notEmptyOrNil, stripAtSymbol),
  getUsernameOrAddress
);

export const pluralizeWord = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`;
};

export const pluralize = (options: {
  count: number;
  singular: string;
  plural: string;
}) => {
  return options.count === 1 ? options.singular : options.plural;
};

type ConjunctionType = 'and' | 'or' | false;

/**
 * Joins the elements of the input array using the specified conjunction, with a comma-separated list format.
 *
 * @param {string[]} words - The array of strings to join.
 * @param {ConjunctionType} [conjunction='and'] - The conjunction to use between the last two elements ('and' or 'or').
 * @returns {string} A comma-separated list of the input array elements, joined with the specified conjunction.
 */
export function joinWithConjunction(
  words: string[],
  conjunction: ConjunctionType = 'and'
): string {
  if (words.length === 0 || !words[0]) {
    return '';
  } else if (words.length === 1) {
    return words[0];
  } else if (words.length === 2) {
    return words.join(` ${conjunction} `);
  } else {
    return (
      words.slice(0, -1).join(', ') + `, ${conjunction} ` + words.slice(-1)
    );
  }
}

const conjunctionMap = {
  and: ' and ',
  or: ' or ',
  comma: ', ',
};

export function getConjuction<T extends object>(
  index: number,
  array: T[],
  conjuction: keyof typeof conjunctionMap = 'and'
) {
  if (index === array.length - 1) {
    return '';
  } else if (index === array.length - 2) {
    return conjunctionMap[conjuction];
  } else {
    return ', ';
  }
}

type TruncatedListOptions = {
  list: string[];
  total: number;
};
/*
 * Returns a truncated list of users, with remaining users displayed as a count
 * rough calculations to ensure the list is not too long
 */
export function getTruncatedListOfUsers(options: TruncatedListOptions) {
  const { list, total } = options;
  // if there are 0 users, return ''
  if (total === 0) {
    return '';
  }
  const truncatedList: string[] = [];
  let totalLength = 0;
  // for each user, add to list if name < 20 chars
  // max total length is 20 chars
  for (let i = 0; i < list.length; i++) {
    const user = list[i];
    if (!user) {
      break;
    }
    const userLength = user.length;

    if (totalLength + userLength < 20) {
      truncatedList.push(user);
      totalLength += userLength;
    } else {
      break;
    }
  }

  const others = total - truncatedList.length;
  if (others > 0) {
    truncatedList.push(`${others} ${pluralizeWord('other', others)}`);
  }
  // if first user's name is < 20 chars, we opt to return a summary count
  if (totalLength === 0) {
    return `${total} ${pluralizeWord('artist', total)}`;
  }
  return joinWithConjunction(truncatedList);
}
